import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import Content from '../Content/Content';
import store from '../../store';
import roomImg from '../../assets/icons/room-50x50.png'
import '../ComponentsUI/componentUI.css'
import * as functions from '../../functions/functions'
import ReactTooltip from 'react-tooltip'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'reactstrap'
import Slider from 'rc-slider';
import CookieHandler from './../../shared/utilities/cookies'

/* import device images */
import AC_on from '../../assets/device/ac/ic_ac_open.png';
import AC_off from '../../assets/device/ac/ic_ac_close.png';
import AC_swing from '../../assets/device/ac/ic_ac_open_swing.png';
import 'rc-slider/assets/index.css';


import CURTAIN_on from '../../assets/device/curtain/ic_curtain_open.png';
import CURTAIN_off from '../../assets/device/curtain/ic_curtain_close.png';
import CURTAIN_CTmid from '../../assets/device/curtain/ic_curtain_mid.png';
import CURTAIN_OTmid from '../../assets/device/curtain/ic_curtain_mid.png';

import DOOR_close from '../../assets/device/door/ic_door_closed.png';
import DOOR_open from '../../assets/device/door/ic_door_opened.png';


import FAN_on from '../../assets/device/fan/ic_fan_open.png';
import FAN_off from '../../assets/device/fan/ic_fan_close.png';

import FIRE_yes from '../../assets/device/fire/ic_fire.png';
import FIRE_no from '../../assets/device/fire/ic_nofire.png';

import DUST_yes from '../../assets/device/dust/ic_dust.png';
import DUST_no from '../../assets/device/dust/ic_nodust.png';

import LIGHT_on from '../../assets/device/light/ic_light_open.png';
import LIGHT_off from '../../assets/device/light/ic_light_close.png';

import MOVEMENT_yes from '../../assets/device/motion/ic_movement.png';
import MOVEMENT_no from '../../assets/device/motion/ic_no_movement.png';


import SMOKE_yes from '../../assets/device/smoke/ic_smoke.png';
import SMOKE_no from '../../assets/device/smoke/ic_nosmoke.png';


import SOUND_yes from '../../assets/device/sound/ic_sound.png';
import SOUND_no from '../../assets/device/sound/ic_nosound.png';

import VIBRATION_yes from '../../assets/device/vibration/vibration.png';
import VIBRATION_no from '../../assets/device/vibration/no_vibration.png';

import TILT_yes from '../../assets/device/position/ic_position_tilt.png';
import TILT_no from '../../assets/device/position/ic_position_stable.png';

import WATER from '../../assets/device/water/water-50x50.png';

import ToggleButton from 'react-toggle-button';
import { getSelectedEvents } from '../../shared/services/events';
import { publishDeviceData } from './../../mqtt/mqtt'

import { storeWater } from '../../redux/actions/waterAction'
import { storeSchedule } from '../../redux/actions/schedulesAction';

import { Redirect } from 'react-router-dom'




const style = { width: 100, marginLeft: 22, marginTop: 5 };
const marks = {
  0: <strong>Closed</strong>,
  1: <strong>Mid</strong>,
  2: <strong>Open</strong>,


};


class HomeDevices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      valueDate: "2:00:00 AM 12/02/2019",
      connection: 0,
      connectionDate: "2:00:00 AM 12/02/2019",
      swingValue: false,
      motorValue: false,
      valveValue: false,
      timeout: null,
      tempValue: 0,

      redirect: false

    }

    this._handlewater = this._handlewater.bind(this);
    this._handlemotor = this._handlemotor.bind(this);
    this._handlevalve = this._handlevalve.bind(this);
    this._handleSchedule = this._handleSchedule.bind(this);
    this.getWaterMotorGraph = this.getWaterMotorGraph.bind(this);
  }

  setRedirect = () => {
    this.setState({ redirect: true })
  }
  _handleScheduleSettings = () => {

    this.props.history.push(`/levels/${this.props.match.params.levelId}/water/schedule`);
    this.props.storeWater(this.props.currentOrg, this.props.item, this.state.value, this.state.valueDate)
  }

  changeTemp = (type) => {
    if (type == '+') {

      if (this.state.tempValue < 31) {
        clearTimeout(this.state.timeout)
        var timeout = setTimeout(() => {
          this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Temperature'), this.state.tempValue)
        }, 2000);
        this.setState({
          tempValue: this.state.tempValue + 1,
          timeout: timeout
        })

      }

    }

    else if (type == '-') {
      if (this.state.tempValue > 16) {
        clearTimeout(this.state.timeout)
        var timeout = setTimeout(() => {
          this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Temperature'), this.state.tempValue)
        }, 2000);
        this.setState({
          tempValue: this.state.tempValue - 1,
          timeout: timeout
        })

      }
    }
  }

  getCurtainState = () => {
    if (this.state.state == 3) {
      return 1;
    }
    else {
      return this.state.state;
    }
  }

  setCurtainState = (value) => {
    clearTimeout(this.state.timeout)
    var timeout = setTimeout(() => {
      if (value == 1) {
        if (this.state.value == 0) {
          this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 1)
        }
        else if (this.state.value == 2) {
          this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 3)
        }
      }
      else if (value == 0) {
        this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 0)
      }
      else if (value == 2) {
        this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 2)
      }
    }, 1000);
    this.setState({

      timeout: timeout
    })

  }

  async getWaterMotorGraph() {

    let data = await getSelectedEvents(this.props.item._id, '10', 'Water_Motor');
    /*   let data=await getConsumptionByDevice(deviceId); */


    if (!data.error) {

      if (data.length !== 0) {
        if (data[0].value === 0) {
          this.setState({
            motorValue: false
          })
        }
        else if (data[0].value === 1) {
          this.setState({
            motorValue: true
          })
        }
      }

    }
  }

  componentWillReceiveProps(props) {

    if (props.mqttReducer.event.deviceID === this.props.item._id) {
      let typeSplit = props.mqttReducer.event.type.toLowerCase().split('_');

      let eventType = '';
      let deviceType = '';

      if (typeSplit.length < 3) {
        eventType = typeSplit[1];
        deviceType = typeSplit[0];
      } else {
        eventType = typeSplit[2];
        deviceType = typeSplit[1];
      }


      // if (eventType === 'motor') {
      //   console.log(props.mqttReducer.event.value)
      //   if (props.mqttReducer.event.value === 0) {
      //     this.setState({
      //       motorValue: false
      //     })
      //   } else if (props.mqttReducer.event.value === 1) {
      //     this.setState({
      //       motorValue: true
      //     })
      //   }
      //   this.getWaterMotorGraph();
      // }

      if (eventType === 'connection') {

        this.setState({
          connection: props.mqttReducer.event.value,
          connectionDate: functions.getTime(props.mqttReducer.event.created) + ' ' + functions.getDate(props.mqttReducer.event.created)
        })
      }
      else if (deviceType === this.props.item.type.toLowerCase() || deviceType === 'water') {
        if (deviceType === 'ac') {
          if (eventType === 'status') {
            this.setState({
              value: props.mqttReducer.event.value,
              valueDate: functions.getTime(props.mqttReducer.event.created) + ' ' + functions.getDate(props.mqttReducer.event.created)
            })
          }
          else if (eventType === 'temperature') {
            this.setState({
              tempValue: props.mqttReducer.event.value,

            })
          }
          else if (eventType === 'swing') {
            this.setState({
              swingValue: (props.mqttReducer.event.value === 0) ? false : true,

            })
          }
        }
        else if (deviceType === 'water' || deviceType === this.props.item.type.toLowerCase()) {
          if (eventType === 'status' || eventType === 'flow') {
            this.setState({
              value: props.mqttReducer.event.value,
              valueDate: functions.getTime(props.mqttReducer.event.created) + ' ' + functions.getDate(props.mqttReducer.event.created)
            })
          }
          else if (eventType === 'motor') {
            if (props.mqttReducer.event.value === 0) {
              this.setState({
                motorValue: false
              })
            }
            else if (props.mqttReducer.event.value === 1) {
              this.setState({
                motorValue: true
              })
            }
          }
          else if (eventType === 'valve') {
            if (props.mqttReducer.event.value === 0) {
              this.setState({
                valveValue: false
              })
            }
            else if (props.mqttReducer.event.value === 1) {
              this.setState({
                valveValue: true
              })
            }
          }
        }
        else {
          this.setState({
            value: props.mqttReducer.event.value,
            valueDate: functions.getTime(props.mqttReducer.event.created) + ' ' + functions.getDate(props.mqttReducer.event.created)
          })
        }


      }
    }
  }


  componentDidMount() {
    let currentDeviceEvents = [];
    if (this.props.events !== "" || this.props.events !== null) {
      if (this.props.events.devices) {
        this.props.events.devices.forEach(e => {
          if (e._id === this.props.item._id) {
            currentDeviceEvents = e.events
          }
        })
      }
    }


    currentDeviceEvents.forEach(e => {
      const event = e.type.toLowerCase();
      let eventType = '';
      let deviceType = '';

      const temp = event.split("_");

      if (temp.length < 3) {
        eventType = event.split("_")[1];
        deviceType = event.split("_")[0];
      } else {
        eventType = event.split("_")[2];
        deviceType = event.split("_")[1];
      }

      if (eventType === 'connection') {

        this.setState({
          connection: e.value,
          connectionDate: functions.getTime(e.created) + ' ' + functions.getDate(e.created)
        })

      }
      else if (deviceType === this.props.item.type.toLowerCase() || deviceType === 'water') {
        if (deviceType === 'ac') {
          if (eventType === 'status') {
            this.setState({
              value: e.value,
              valueDate: functions.getTime(e.created) + ' ' + functions.getDate(e.created)
            })
          }
          else if (eventType === 'temperature') {
            this.setState({
              tempValue: e.value,

            })
          }
          else if (eventType === 'swing') {
            this.setState({
              swingValue: (e.value === 0) ? false : true,

            })
          }
        }
        else if (deviceType === 'water' || deviceType === this.props.item.type.toLowerCase()) {
          if (eventType === 'status' || eventType === 'flow') {
            this.setState({
              value: e.value,
              valueDate: functions.getTime(e.created) + ' ' + functions.getDate(e.created)
            })
          }
          else if (eventType === 'motor') {
            if (e.value === 0) {
              this.setState({
                motorValue: false
              })
            }
            else if (e.value === 1) {
              this.setState({
                motorValue: true
              })
            }
          }
          else if (eventType === 'valve') {
            if (e.value === 0) {
              this.setState({
                valveValue: false
              })
            }
            else if (e.value === 1) {
              this.setState({
                valveValue: true
              })
            }
          }
        }
        else {
          this.setState({
            value: e.value,
            valueDate: functions.getTime(e.created) + ' ' + functions.getDate(e.created)
          })
        }


      }

    })

  }

  generateType = (hubId, device, org, status) => {
    let currentHub = {};
    store.getState().OrganizationReducer.organizationsDetails.forEach(e => {
      if (org === e._id) {
        e.hubs.forEach(i => {
          if (i._id === hubId) {
            currentHub = i
          }
        })
      }
    })

    return '' + currentHub.type + '_' + device.type + '_' + status
  }

  generateWaterType = (hubId, device, org, status) => {
    let currentHub = {};
    store.getState().OrganizationReducer.organizationsDetails.forEach(e => {
      if (org === e._id) {
        e.hubs.forEach(i => {
          if (i._id === hubId) {
            currentHub = i
          }
        })
      }
    })

    return '' + currentHub.type + '_' + status
  }

  getHubType = (hubId, org) => {
    let currentHub = { type: '' };
    store.getState().OrganizationReducer.organizationsDetails.forEach(e => {
      if (org === e._id) {
        e.hubs.forEach(i => {
          if (i._id === hubId) {
            currentHub = i
          }
        })
      }
    })

    return currentHub
  }

  publishData = (type, value, device) => {
    if (this.props.item.motorHubId) {
      var data = {
        deviceId: this.props.item._id,
        hubId: this.props.item.motorHubId,
        postHubId: this.props.item.hubId,
        value: value,
        type: type,
        createdBy: CookieHandler.getCookie('user')
      }
      publishDeviceData(this.getHubType(this.props.item.hubId, this.props.org).type, this.props.item.motorHubId, "AppEvents", "json", data);
    }
    else {
      var data = {
        deviceId: this.props.item._id,
        hubId: this.props.item.hubId,
        value: value,
        type: type,
        createdBy: CookieHandler.getCookie('user')
      }

      publishDeviceData(this.getHubType(this.props.item.hubId, this.props.currentOrg).type, this.props.item.hubId, "AppEvents", "json", data);
    }
  }

  _handlemotor() {
    if (this.state.motorValue === false) {
      this.publishData(this.generateWaterType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Motor'), 1)
    }
    else if (this.state.motorValue === true) {
      this.publishData(this.generateWaterType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Motor'), 0)
    }
    // this.setState({ motorValue: !this.state.motorValue })
  }

  _handlevalve() {
    if (this.state.valveValue === false) {
      this.publishData(this.generateWaterType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Valve'), 1)
    }
    else if (this.state.valveValue === true) {
      this.publishData(this.generateWaterType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Valve'), 0)
    }
    // this.setState({ motorValue: !this.state.motorValue })
  }

  _handlewater() {
    if (this.props.item.type === "WaterTank" || this.props.item.type === "FlowMeter") {
      this.props.history.push(`/levels/${this.props.match.params.levelId}/water`);
      this.props.storeWater(this.props.currentOrg, this.props.item, this.state.value, this.state.valueDate)
    }
  }

  _handleSchedule() {
    if (this.props.item.type !== "WaterTank") {
      if (this.props.item.type !== "FlowMeter") {
        this.props.history.push(`/levels/${this.props.match.params.levelId}/home_schedule`);
        this.props.storeSchedule(this.props.item)
      }
    }
  }

  render() {
    return (


      <div className="col-12 animated fadeIn" style={(this.props.item.type === 'Curtain') ? { marginTop: '5px', padding: '5px', height: '125px' } : { marginTop: '5px', padding: '5px' }} >
        <div style={{ width: '100%', borderRadius: '5px' }} className="component-container-box" >
          <div className="row" style={{ margin: 0, height: '100%' }}>
            <div className='col-3' style={{ width: '100%', overflow: 'hidden', paddingTop: 10, paddingBottom: 10, paddingLeft: 15, backgroundImage: 'linear-gradient(#1286A8, #2eafd3)', borderRadius: '5px 0px 0px 5px' }}>
              {/*  <img width={50} height={50} src={roomImg} style={{position:'relative',top:'50%',transform:'translateY(-50%)',filter: 'brightness(100)'}}/> */}

              {/* for light */}
              {(this.props.item.type === 'Light') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={LIGHT_off} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 1)} />
                    :
                    <img width={50} height={50} src={LIGHT_on} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 0)} />
                  }
                </div>
                :
                null
              }

              {/* for lock */}
              {(this.props.item.type === 'Lock') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={DOOR_close} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 1)} />
                    :
                    <img width={50} height={50} src={DOOR_open} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 0)} />
                  }
                </div>
                :
                null
              }



              {/* for fan */}
              {(this.props.item.type === 'Fan') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={FAN_off} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 1)} />
                    :
                    <img width={50} height={50} src={FAN_on} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 0)} />
                  }
                </div>
                :
                null
              }




              {/* for AC */}
              {(this.props.item.type === 'AC') ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={AC_off} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 1)} />
                    :
                    <div>

                      <img width={50} height={50} src={AC_on} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} onClick={() => this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Status'), 0)} />
                      <div style={{ marginTop: '8px' }}>
                        <ToggleButton
                          value={this.state.swingValue}
                          onToggle={(swingValue) => {
                            this.publishData(this.generateType(this.props.item.hubId, this.props.item, this.props.currentOrg, 'Swing'), (this.state.swingValue) ? 0 : 1)
                            this.setState({
                              swingValue: !swingValue,
                            })

                          }} />
                      </div>
                    </div>
                  }
                </div>
                :
                null
              }



              {/* for Curtain */}
              {(this.props.item.type === 'Curtain') ?
                <div>
                  {(this.state.value == 0) ?
                    <img width={50} height={50} src={CURTAIN_off} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    null
                  }
                  {(this.state.value == 1) ?
                    <img width={50} height={50} src={CURTAIN_CTmid} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    null
                  }
                  {(this.state.value == 2) ?
                    <img width={50} height={50} src={CURTAIN_on} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    null
                  }
                  {(this.state.value == 3) ?
                    <img width={50} height={50} src={CURTAIN_CTmid} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    null
                  }

                </div>
                :
                null
              }



              {/* for door */}
              {(this.props.item.type === 'Door') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={DOOR_close} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={DOOR_open} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }



              {/* for Sound */}
              {(this.props.item.type === 'Sound') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={SOUND_no} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={SOUND_yes} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }



              {/* for Smoke */}
              {(this.props.item.type === 'Smoke') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={SMOKE_no} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={SMOKE_yes} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }




              {/* for Fire */}
              {(this.props.item.type === 'Fire') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={FIRE_no} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={FIRE_yes} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }



              {/* for Motion */}
              {(this.props.item.type === 'Motion') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={MOVEMENT_no} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={MOVEMENT_yes} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }


              {/* for Vibration */}
              {(this.props.item.type === 'Vibration') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={VIBRATION_no} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={VIBRATION_yes} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }




              {/* for Position */}
              {(this.props.item.type === 'Fire') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={TILT_no} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={TILT_yes} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }



              {/* for Monitor */}
              {(this.props.item.type === 'Monitor') ?
                <div>
                  {(this.state.value === 0) ?
                    <img width={50} height={50} src={AC_off} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                    :
                    <img width={50} height={50} src={AC_on} style={{/* position:'relative',top:'50%',transform:'translateY(-50%)', */filter: 'brightness(100)' }} />
                  }
                </div>
                :
                null
              }


              {/* for Temperature */}
              {(this.props.item.type === 'Temperature') ?
                <span style={{ position: 'relative', top: '25%', color: 'white' }}>{this.state.value} &#8451;</span>
                :
                null
              }


              {/* for Humidity */}
              {(this.props.item.type === 'Humidity') ?
                <span style={{ position: 'relative', top: '25%', color: 'white' }}>{this.state.value} %</span>
                :
                null
              }


              {(this.props.item.type === 'WaterTank') ?
                <div style={{ marginTop: 8, marginBottom: 8 }} >
                  <img width={50} height={50} src={WATER} style={{ filter: 'brightness(100)' }} />
                  {(this.props.item.metadata.motor) ?
                    <div style={{ marginTop: '8px' }}>
                      <ToggleButton
                        value={this.state.motorValue}
                        onClick={this._handlemotor} />
                    </div>
                    :
                    null
                  }
                  {/* <p style={{ height: 52, width: 52, paddingTop: 9, color: 'white' }} className="value-p">{this.state.value}<br />Feet</p> */}
                </div>
                :
                null
              }

              {(this.props.item.type === 'FlowMeter') ?
                <div style={{ marginTop: 8, marginBottom: 8 }} >
                  <img width={50} height={50} src={WATER} style={{ filter: 'brightness(100)' }} />
                  {/* <p style={{ height: 52, width: 52, paddingTop: 9, color: 'white' }} className="value-p">{this.state.value}<br />Feet</p> */}
                  {(this.props.item.metadata.valve) ?
                    <div style={{ marginTop: '8px' }}>
                      <ToggleButton
                        value={this.state.valveValue}
                        onClick={this._handlevalve}
                      />
                    </div>
                    :
                    null
                  }
                </div>
                :
                null
              }



            </div>
            <div onClick={this._handlewater} className='col-7' style={{ textAlign: 'left', paddingTop: 10, paddingBottom: 10, paddingRight: 0 }}>
              <h6 style={{ fontWeight: 'bold', marginBottom: 0 }}>{this.props.item.name}</h6>
              <span style={{ fontSize: 10, display: 'inline-block', marginTop: '8px', lineHeight: '16px' }}><b>Last Updated:</b> {this.state.valueDate}</span>
              <div className="">
                {(this.props.item.type === 'AC') ?
                  <div className="text-muted text-uppercase font-weight-bold font-xs">{this.state.tempValue}°C
                 {(this.state.value === 1) ?
                      <div className="d-inline">
                        <div className="d-inline">
                          <Button title="Raise" size="sm" onClick={() => this.changeTemp("+")} style={{ marginLeft: 12 }}>+</Button>
                          <Button title="Drop" style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 6 }} size="sm" onClick={() => this.changeTemp("-")}>-</Button>


                        </div>

                        <div>

                        </div>
                      </div>
                      :
                      null
                    }

                  </div>
                  :
                  null
                }

                {(this.props.item.type === 'Curtain') ?
                  <div style={style}>
                    {(this.state.value === 3) ?
                      <div>
                        <Slider dots min={0} max={2} marks={marks} step={1} onChange={this.setCurtainState} defaultValue={1} />
                      </div>

                      :
                      <Slider dots min={0} max={2} marks={marks} step={1} onChange={this.setCurtainState} defaultValue={this.state.value} />
                    }

                  </div>
                  :
                  null
                }

                {(this.props.item.type === 'WaterTank') ?
                  <p style={{ paddingTop: 5 }} className="value-p"><b>Value:</b> {this.state.value}%</p>
                  :
                  null
                }


              </div>
            </div>
            <div className='col-2' style={{ padding: 0 }}>
              <span style={{ padding: '0px 3px', display: 'inline-block', marginTop: '5px' }} data-event='click focus' data-tip='custom show' data-for={this.props.item._id + '_Tooltip'}>
                {
                    (this.state.connection) ?
                    <div>
                      <span className="mdi mdi-wifi mdi-10px"  ></span>
                      {(this.props.item.type === 'WaterTank') ?
                        (this.props.item.metadata.motor) ?
                          <span className="mdi mdi-settings-outline mdi-10px" style={{ display: 'inline-block', marginTop: '5px', padding: '0px 3px' }} onClick={this._handleScheduleSettings}></span>
                          : null

                        : null
                      }
                    </div>
                    :
                    <div>
                      <span className="mdi mdi-wifi-off mdi-10px"></span>
                      {(this.props.item.type === 'WaterTank') ?
                        (this.props.item.metadata.motor) ?
                          <span className="mdi mdi-settings-outline mdi-10px" style={{ display: 'inline-block', marginTop: '5px', padding: '0px 3px' }} onClick={this._handleScheduleSettings}></span>
                          : null

                        : null
                      }
                    </div>
                }

                {/* <ReactTooltip id={this.props.item._id+'_Tooltip'} type='dark' effect='float' place='bottom' globalEventOff='click'>
                {(this.state.connection===0)?
                <span>Disconnected since:</span> 
                :
                <span>Connected since:</span>    
            }
            <span>{this.state.connectionDate}</span>
                </ReactTooltip> */}
              </span>

              {(this.props.item.type !== "WaterTank") ?
                (this.props.item.type !== "FlowMeter") ?
                  <span className="mdi mdi-settings-outline mdi-18px" style={{ display: 'inline-block', marginTop: '5px', padding: '0px 3px' }} onClick={this._handleSchedule}></span>
                  :
                  null
                :
                null
              }
            </div>

          </div>

          {/*  <div className="content-container" onClick={() => this.props.history.push('/levels/' + this.props.item._id)}>
             <div className="content-box-icon">
             <div style={{float:'right',marginRight:10}}><span style={{color:'rgb(119, 119, 119)'}}></span></div>
             <div style={{width:'100%',overflow:'hidden'}}>
             <img width={50} height={50} src={roomImg} />
             </div>
             <p style={{overflow:'hidden'}}>{this.props.item._id}</p>
             </div>
             </div> */}
        </div>
      </div>





    );
  }
}

const mapStateToProps = (state) => {
  return {
    mqttReducer: state.MqttReducer,

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ storeWater, storeSchedule }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeDevices));