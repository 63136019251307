import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import Content from '../Content/Content';
import store from '../../store'
import { getOrganizations, clearOrganization, gettingOrganization } from '../../redux/actions/organizationActions'
import { clearUser } from '../../redux/actions/usersActions'
import { clearEvents } from '../../redux/actions/eventsAction'

import OrganizationUI from '../ComponentsUI/organizationUI';
import { checkSignIn } from '../../shared/services/users';
import './Home.css';
import { getdata, disconnectMqtt } from '../../mqtt/mqtt'
import { mqttConfig } from '../../config/config';
import { Progress } from 'reactstrap';
import OttoIcon from '../../assets/icons/mind-map-filled-100x100.png'

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidenav: false,
            gettingData: true,
            progressPercent: 0
        }
    }

    storeSubscribe = () => {
        let storeState = store.getState();
        console.log(storeState);

        this.setState({
            progressPercent: this.getProgressPercent(storeState.OrganizationReducer.progressCounter, storeState.OrganizationReducer.totalOrganizations)
        })


        if (this.state.gettingData) {
            if (storeState.OrganizationReducer.isOrganizationSet) {
                this.setState({
                    gettingData: false
                })
                //connect mqtt
            }
        }


    }

    sidenavToggleHandler = () => {
        if (this.state.sidenav) {
            this.setState({
                ...this.state,
                sidenav: false
            });
        } else {
            this.setState({
                ...this.state,
                sidenav: true
            });
        }
    }

    getProgressPercent = (progress, total) => {

        let grandTotal = total * 3;
        let percent = 0;
        if (grandTotal > 0) {
            percent = Number((progress * 100) / (grandTotal)).toFixed(2)
        }

        console.log(percent)
        return percent;
    }


    componentDidMount() {
        (console.log('main level mount'));
        this.unsubscribe = store.subscribe(this.storeSubscribe);
        if (checkSignIn()) {

            if (!store.getState().OrganizationReducer.isOrganizationSet) {
                store.dispatch(gettingOrganization());
                store.dispatch(getOrganizations());
            }
            else {
                this.setState({
                    gettingData: false
                })
            }
        }
        else {
            store.dispatch(clearUser())
            store.dispatch(clearOrganization())
            store.dispatch(clearEvents())
            disconnectMqtt();

            this.props.history.push('/')
        }



    }

    componentWillUnmount() {

        this.unsubscribe();
    }


    render() {
        return (
            <div>
                <Header history={this.props.history} toggle={() => this.sidenavToggleHandler()} name={'Ottomatically'} hideHomeButton={true}></Header>
                <Sidenav width={this.state.sidenav ? '75%' : '0px'} history={this.props.history}></Sidenav>
                <div className="content" id="content" style={(this.state.gettingData) ? { display: 'block', height: '100vh' } : { display: 'block', height: '100%' }}>
                    {(this.state.gettingData) ?
                        <div className=' animated fadeIn' style={{ margin: 0, position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                            <img src={OttoIcon} style={{ width: '60px', marginBottom: '25px', marginLeft: '8px' }} />
                            <Progress style={{ height: '5px', width: '50%', margin: '0 auto' }} color="info" value={this.state.progressPercent} />
                        </div>
                        :
                        /*  <Content></Content> */

                        <div className="row animated fadeIn" style={{ margin: 0 }}>
                            {(store.getState().OrganizationReducer.organizationsList.length === 1) ?
                                this.props.history.push('/levels/' + store.getState().OrganizationReducer.organizationsList[0]._id)
                                :
                                (store.getState().OrganizationReducer.organizationsList.map((item, index) =>
                                    <OrganizationUI key={index} history={this.props.history} item={item} />))
                            }
                        </div>

                    }
                </div>
                <Footer></Footer>
            </div>
        );
    }
}

export default withRouter(Home);