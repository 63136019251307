import React, { Component } from 'react';
import './Content.css';

class Content extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="content"></div>
        );
    }
}

export default Content;