import React, { Component } from 'react'

import $ from "jquery";
import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
    Fade,
    Collapse,
    Alert
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

export class prerequisiteEvents extends Component {
    constructor() {
        super();
        this.removeCurrentSchedule = this.removeCurrentSchedule.bind(this);

        this.state = {
            deviceId: "",
            type: "",
            value: 0,
            condition: "",
            deviceList: [],
            events: []
        };
    }

    componentWillReceiveProps(props) {
        if (props.data) {
            this.setState({
                deviceId: props.data.deviceId,
                type: props.data.type,
                value: props.data.value,
                condition: props.data.condition,
                deviceList: props.deviceList
            });
            /*  this.state.deviceId=props.data.deviceId;
                   this.state.type=props.data.type
                   this.state.value = props.data.value
                   this.state.condition = props.data.condition
                   this.forceUpdate(); */
        }
    }
    componentWillMount() {
        this.setState({
            events: this.props.events
        }, () => {
            console.log("this.state.events", this.state.events)
        });
        // if (this.props.data) {
        // this.state.type = this.props.data.type;
        this.state.value = this.props.events.value;
        // this.state.condition = this.props.data.condition;
        // }

    }

    handleAdd = () => {
        let events = this.state.events;
        events.push({
            deviceId: this.props.device._id,
            hubId: this.props.device.hubId,
            type: 'Water_Motor',
            value: (this.props.type === 'Low') ? 1 : 0
        })
        this.setState({
            events
        })
    }

    handleRemove = (index) => {
        let events = this.state.events;
        events.splice(index, 1);
        this.setState({
            events
        })
    }

    handleDropdown = (e, index) => {
        let events = this.state.events;
        let dId = events[index].deviceId.split('_');
        dId = dId[0] + '_' + dId[1] + '_'
        events[index].deviceId = dId + e.target.value
        // console.log(this.state.events)
        this.setState({
            events
        })
    }

    _handleSavePre = () => {
        this.props.handler(this.state.events, this.props.type)
    }

    valueHandler(e) {
        /*   if(e.target.name==="type"){
               this.state.deviceList.forEach(x=>{
                   if(x._id===this.state.deviceId){
                       if(x.hub){
                           let hubType = x.hub[0].type
                           let deviceType = x.type
                           let eventType = e.target.value
    
                           let type = ""+hubType+"_"+deviceType+"_"+eventType+""
                           this.setState({
                              type : e.target.value
                             },()=>{
                               let obj ={
                                   deviceId : this.state.deviceId,
                                   condition : this.state.condition,
                                   type : this.state.type,
                                   value : this.state.value
                               }
                               this.props.updatePrerequisites(obj,this.props.index)
                             }) 
                       }
                   }
               })
              } */
        this.setState(
            {
                [e.target.name]: e.target.value
            },
            () => {
                let obj = {
                    deviceId: this.state.deviceId,
                    condition: this.state.condition,
                    type: this.state.type,
                    value: this.state.value
                };
                this.props.updatePrerequisites(obj, this.props.index);
            }
        );
    }
    getTypeValue = type => {
        if (this.state.deviceId !== "") {
            for (let x = 0; x < this.state.deviceList.length; x++) {
                if (this.state.deviceList[x]._id === this.state.deviceId) {
                    if (this.state.deviceList[x].hub) {
                        let hubType = this.state.deviceList[x].hub[0].type;
                        let deviceType = this.state.deviceList[x].type;
                        let eventType = type;

                        let fullType =
                            "" + hubType + "_" + deviceType + "_" + eventType + "";
                        // console.log(fullType)
                        return fullType;
                    }
                }
            }
        }
    };
    removeCurrentSchedule() {
        $("#schedule" + this.props.index).hide();
        var obj = {
            target: {
                name: "_deleted",
                value: true
            }
        };
        this.valueHandler(obj);
    }

    createSelectItems = () => {
        let items = [];
        let values = ["Status", "Connection", "Swing"];
        for (let i = 0; i <= 2; i++) {
            items.push(
                <option key={i} value={this.getTypeValue(values[i])}>
                    {values[i]}
                </option>
            );

            //here I will be creating my options dynamically based on
            //what props are currently passed to the parent component
        }
        return items;
    };
    render() {
        return (
            this.state.events.map((event, index) => (
                <Card >
                    <div style={{ textAlign: 'right', color: '#20536c' }}><i class="fa fa-plus" onClick={this.handleAdd}></i></div>
                    <CardBody>
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6">
                                <FormGroup>
                                    <h6 style={{ fontSize: '13px', marginBottom: '0px', textAlign: 'left' }}>Device Id</h6>
                                    {/* <Input style={{ padding: '5px', fontSize: '12px' }}
                                    className="mt-1"
                                    type="select"
                                    name="deviceId"
                                    placeholder="Device Id"
                                    value={this.state.deviceId}
                                    onChange={this.valueHandler.bind(this)}
                                > */}

                                    <select
                                        style={{ padding: '5px', fontSize: '12px' }}
                                        className="mt-1"
                                        type="select"
                                        name="deviceId"
                                        placeholder="Device Id"
                                        // value={this.state.deviceId}
                                        // onChange={this.valueHandler.bind(this)}
                                        value={event.deviceId.split('_')[event.deviceId.split('_').length - 1]} className="border-success"
                                        onChange={(e) => this.handleDropdown(e, index)}
                                        style={{ width: '200px', fontSize: '13px' }}
                                    >

                                        <option value={"Overhead"}>Overhead</option>
                                        <option value={"Underground"}>Underground</option>
                                    </select>

                                    {/* {this.state.deviceList.map((value, index) => (
                                        <option value={value._id} key={index}>
                                            {value.name}
                                        </option>
                                    ))} */}
                                    {/* {this.state.events.map((event, index) => (
                                        <div className="col-12" style={{ textAlign: 'left' }}>
                                            <select value={event.deviceId.split('_')[event.deviceId.split('_').length - 1]} onChange={(e) => this.handleDropdown(e, index)} style={{ width: '200px', fontSize: '13px' }}>
                                                <option value={"Overhead"}>Overhead</option>
                                                <option value={"Underground"}>Underground</option>
                                            </select>
                                            <div style={{ display: 'inline', float: 'right', color: 'red' }}><i class="fa fa-times" onClick={(e) => this.handleRemove(index)}></i></div>
                                        </div>
                                    ))} */}

                                    {/* </Input> */}
                                </FormGroup>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                <FormGroup>
                                    <h6 style={{ fontSize: '13px', marginBottom: '0px', textAlign: 'left' }}>Type</h6>

                                    <Input style={{ padding: '5px', fontSize: '12px' }}
                                        className="mt-1"
                                        type="select"
                                        name="type"
                                        ref="type"
                                        // disabled={this.state.deviceId === "" ? true : false}
                                        placeholder="Type"
                                        value={this.state.type}
                                    // onChange={this.valueHandler.bind(this)}
                                    >
                                        {/* <option value={this.getTypeValue('Status')}  >{()=>this.getTypeValue('Status')}</option>
                                    <option value={this.getTypeValue('Connection')}  >{this.getTypeValue('Connection')}</option>
                                    <option value={this.getTypeValue('Swing')}  >{this.getTypeValue('Swing')}</option> */}
                                        <option value="Status">Status</option>
                                        {/* {this.createSelectItems()} */}
                                    </Input>
                                </FormGroup>
                            </Col>

                            <Col xs="12" sm="12" md="6" lg="6">
                                <FormGroup>
                                    <h6 style={{ fontSize: '13px', marginBottom: '0px', textAlign: 'left' }}>Condition</h6>
                                    <Input style={{ padding: '5px', fontSize: '12px' }}
                                        className="mt-1"
                                        type="select"
                                        name="condition"
                                        ref="condition"
                                        placeholder="Condition"
                                        value={this.state.condition}
                                    // onChange={this.valueHandler.bind(this)}
                                    >
                                        <option value="LOW">LOW</option>
                                        <option value="EQUAL">EQUAL</option>
                                        <option value="HIGH">HIGH</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6">
                                <FormGroup>
                                    <h6 style={{ fontSize: '13px', marginBottom: '0px', textAlign: 'left' }}>Value</h6>
                                    <Input style={{ padding: '5px', fontSize: '12px' }}
                                        className="mt-1"
                                        type="number"
                                        name="value"
                                        ref="value"
                                        placeholder="Value"
                                        value={this.state.value}
                                    // onChange={this.valueHandler.bind(this)}
                                    />
                                </FormGroup>
                            </Col>
                            <div style={{ display: 'inline', float: 'right', color: 'red' }}><i class="fa fa-times" onClick={(e) => this.handleRemove(index)}></i></div>
                        </Row>
                    </CardBody>
                    <button style={{ width: "100%", backgroundColor: "#19a848", color: "white", borderColor: "#19a848", fontSize: '12px', margin: '10px 0px' }} onClick={this._handleSavePre}>Save</button>
                </Card>
            ))
        );
    }
}

export default prerequisiteEvents
