import cookies from '../utilities/cookies';
import moment from 'moment'
// const host = 'https://linked-things-s4-gateway.eu-de.mybluemix.net/api/v1';
const host = 'https://gateway.s5.ottomatically.com/api/v1';
// let host = "http://175.107.196.247:3000";

export let getAlertsByDeviceId = async function (deviceId) {
    let token = cookies.getCookie('jwt');

    let response = await fetch(host + '/devices/' + deviceId + '/alerts', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });

    response = await response.json();
    return response;
}


export let getSchedulesByLevel = async function (levelId) {
    let token = cookies.getCookie('jwt');

    let response = await fetch(host + '/levels/' + levelId + '/schedules', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    });

    response = await response.json();
    return response;
}


export let postAlert = async (deviceId, highVal, highEvents, lowVal, lowEvents, deviceType, eventType, lowPre) => {
    let token = cookies.getCookie('jwt');
    let hubId = deviceId.split('_')[0] + '_' + deviceId.split('_')[1];

    let lowValParsed = parseInt(lowVal);
    let highValParsed = parseInt(highVal);
    let alerts = [];
    if (highValParsed !== '' && lowValParsed !== '' && lowValParsed > highValParsed) {
        console.log("low is greather than High")
        return;
    }
    else {
        if (lowValParsed !== '') {
            let device = deviceId;
            device = device.split('_');
            device = device[2];
            console.log("LOW Recieved ", lowValParsed, highValParsed)

            if (device === 'Overhead') { //for only post prerequisites in Overhead
                alerts.push({
                    deviceId,
                    condition: 'LOW',
                    value: parseInt(lowValParsed),
                    type: 'Water_' + deviceType + '_Status',
                    events: lowEvents,
                    prerequisites: lowPre
                });

            }
            else {
                alerts.push({
                    deviceId,
                    condition: 'LOW',
                    value: parseInt(lowValParsed),
                    type: 'Water_' + deviceType + '_Status',
                    events: lowEvents,
                    prerequisites: []
                });
            }
        }
        if (highValParsed !== '') {
            console.log("HIGH Recieved ", lowValParsed, highValParsed)
            alerts.push({
                deviceId,
                condition: 'HIGH',
                value: parseInt(highValParsed),
                type: 'Water_' + deviceType + '_Status',
                events: highEvents,
                prerequisites: []

            });
            console.log("PostAlert", alerts);

            let response = await fetch(host + '/alerts/bulk', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(alerts)
            });

            response = await response.json();
            return response;
        }

    }


}

export let putAlert = async (alertId, val, events) => {
    let token = cookies.getCookie('jwt');
    console.log("evennnnn", events)
    if (val) {
        let response = await fetch(host + '/alerts/' + alertId, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ value: val, events: events })
        });

        response = await response.json();
        return response;
    }
}

export let putAlertPre = async (alertId, val, events) => {
    let token = cookies.getCookie('jwt');
    if (!events.hubId) {
        let response = await fetch(host + '/alerts/' + alertId, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ prerequisites: events })
        });

        response = await response.json();
        return response;
    }
}

export let postSchedule = async (deviceId, start, end, repeatOn, repeatOff, type) => {
    let token = cookies.getCookie('jwt');
    let hubId = deviceId.split('_')[0] + '_' + deviceId.split('_')[1];
    let str;
    if (deviceId.split('_')[2] == 'Underground') {
        str = 'UG Motor';
    } else {
        str = 'OH Motor';
    }
    if (repeatOn.length > 0 && repeatOff.length > 0) {
        let schedules = [{
            name: str + ' ON',
            description: str + ' ON',
            levelId: deviceId.split('_')[0],
            startTime: start,
            endTime: end,
            repeat: repeatOn,
            events: [
                {
                    deviceId,
                    hubId,
                    type,
                    value: 1
                }
            ],
            prerequisites: [
                {
                    deviceId,
                    condition: 'EQUAL',
                    type,
                    value: 0
                }
            ]
        }, {
            name: str + ' OFF',
            description: str + '  OFF',
            levelId: deviceId.split('_')[0],
            endTime: start,
            startTime: end,
            repeat: repeatOff,
            events: [
                {
                    deviceId,
                    hubId,
                    type,
                    value: 0
                }
            ],
            prerequisites: [
                {
                    deviceId,
                    condition: 'EQUAL',
                    type,
                    value: 1
                }
            ]
        }]

        console.log(schedules);

        let response = await fetch(host + '/schedules/bulk', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(schedules)
        });


        response = await response.json();
        return response;
    }
}

export let postScheduleStart = async (deviceId, start, end, repeatOn, repeatOff, type) => {
    let token = cookies.getCookie('jwt');
    let hubId = deviceId.split('_')[0] + '_' + deviceId.split('_')[1];
    let str;
    if (deviceId.split('_')[2] == 'Underground') {
        str = 'UG Motor ON';
    } else {
        str = 'OH Motor ON';
    }
    let endtime = moment.utc(start, "HH:mm");
    endtime.add(2, "minutes")
    if (repeatOn.length > 0) {
        let startSchedule = [  // if user enter only On schedule
            {
                name: str,
                description: str,
                levelId: deviceId.split('_')[0],
                startTime: start,
                endTime: endtime.format("HH:mm"),
                repeat: repeatOn,
                events: [
                    {
                        deviceId,
                        hubId,
                        type,
                        value: 1
                    }
                ],
                prerequisites: [
                    {
                        deviceId,
                        condition: 'EQUAL',
                        type,
                        value: 0
                    }
                ]
            }
        ]

        let response = await fetch(host + '/schedules/bulk', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(startSchedule)
        });
        response = await response.json();
        return response;
    }
}

export let postScheduleEnd = async (deviceId, start, end, repeatOn, repeatOff, type) => {
    let token = cookies.getCookie('jwt');
    let hubId = deviceId.split('_')[0] + '_' + deviceId.split('_')[1];
    let str;
    if (deviceId.split('_')[2] == 'Underground') {
        str = 'UG Motor OFF';
    } else {
        str = 'OH Motor OFF';
    }
    let endtime = moment.utc(end, "HH:mm");
    endtime.add(2, "minutes")

    // if user enter only Off schedule

    console.log("user entered only End time")
    if (repeatOff.length > 0) {
        let Endschedule = [
            {
                name: str,
                description: str,
                levelId: deviceId.split('_')[0],
                endTime: endtime.format("HH:mm"),
                startTime: end,
                repeat: repeatOff,
                events: [
                    {
                        deviceId,
                        hubId,
                        type,
                        value: 0
                    }
                ],
                prerequisites: [
                    {
                        deviceId,
                        condition: 'EQUAL',
                        type,
                        value: 1
                    }
                ]
            }
        ]

        let response = await fetch(host + '/schedules/bulk', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(Endschedule)
        });
        response = await response.json();
        return response;
    }
}

export let putSchedule = async (scheduleId, start, end, repeatOn, repeatOff) => {
    if (repeatOn.length > 0 && repeatOff.length > 0) {
        let token = cookies.getCookie('jwt');
        //for put call on and off schedules
        let response = await fetch(host + '/schedules/' + scheduleId, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ startTime: start, endTime: end, repeat: repeatOn, repeat: repeatOff })
        });
        response = await response.json();
        return response;
    }
}

export let putScheduleStart = async (scheduleId, start, end, repeatOn, repeatOff) => {
    if (repeatOn.length > 0) {
        let token = cookies.getCookie('jwt');
        let endtime = moment.utc(start, "HH:mm");
        endtime.add(2, "minutes")
        //for put call only ON schedule
        let response = await fetch(host + '/schedules/' + scheduleId, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                startTime: start,
                endTime: endtime.format("HH:mm"),
                repeat: repeatOn
            })
        });
        response = await response.json();
        return response;
    }
}

export let putScheduleEnd = async (scheduleId, start, end, repeatOn, repeatOff) => {
    console.log("repeatOff.length", repeatOff.length)
    if (repeatOff.length > 0) {
        let token = cookies.getCookie('jwt');
        let endtime = moment.utc(end, "HH:mm");
        endtime.add(2, "minutes")

        //for put call only OFF schedule
        console.log("for put only ONNN", repeatOn.length + " OFF  " + repeatOff.length)
        let response = await fetch(host + '/schedules/' + scheduleId, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                endTime: endtime.format("HH:mm"),
                startTime: end, repeat: repeatOff
            })
        });
        response = await response.json();
        return response;
    }
}

export let deleteAlert = async (alertId) => {
    let token = cookies.getCookie('jwt');

    let response = await fetch(host + '/alerts/' + alertId, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    });

    response = await response.json();
    return response;
}

export let deleteSchedule = async (scheduleId) => {
    let token = cookies.getCookie('jwt');

    let response = await fetch(host + '/schedules/' + scheduleId, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json"
        }
    });

    response = await response.json();
    return response;

}