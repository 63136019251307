

import React, { Component } from 'react';
import './contextLoader.min.css'



class Skeleton extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }



    render() {
        return (
            <div className="timeline-item">
                <div className="animated-background">
                    <div className="background-masker header-top"></div>
                    <div className="background-masker header-left"></div>
                    <div className="background-masker header-right"></div>
                    <div className="background-masker header-bottom"></div>
                    <div className="background-masker subheader-left"></div>
                    <div className="background-masker subheader-right"></div>
                    <div className="background-masker subheader-bottom"></div>
                    <div className="background-masker content-top"></div>
                    <div className="background-masker content-first-end"></div>
                </div>
            </div>
        );
    }
}

export default Skeleton;