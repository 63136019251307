import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import Content from '../Content/Content';

import store from '../../store'
import { getOrganizations, clearOrganization, gettingOrganization } from '../../redux/actions/organizationActions'
import { clearUser } from '../../redux/actions/usersActions'
import { clearEvents } from '../../redux/actions/eventsAction'

import { getHierarchy } from '../../shared/services/levels'
import { getHubsByLevel } from '../../shared/services/hubs'
import { getDevicesByLevel } from '../../shared/services/devices'
import { getOrganizationList } from '../../shared/services/organizations'
import LevelUI from '../ComponentsUI/levelsUI';
import DeviceUI from '../ComponentsUI/devicesUI';
import { getdata, disconnectMqtt } from '../../mqtt/mqtt';
import { checkSignIn } from '../../shared/services/users';
import { Progress } from 'reactstrap';
import OttoIcon from '../../assets/icons/mind-map-filled-100x100.png'

let url = window.location.href;
let segment = url.substring(url.lastIndexOf('/') + 1);
let itemSegment = null;
class Levels extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidenav: false,
            gettingData: true,
            currentLevel: [],
            currentDevices: [],
            noDevices: false,
            levelId: null,
            progressPercent: 0,
            headerName: '',
            currentOrg: '',
            branches: ''
        }
    }

    storeSubscribe = () => {
        //console.log(store.getState().EventsReducer.currentEvent)

        const storeState = store.getState();
        //console.log(storeState);
        this.setState({
            progressPercent: this.getProgressPercent(storeState.OrganizationReducer.progressCounter, storeState.OrganizationReducer.totalOrganizations)
        })

        if (this.state.gettingData) {
            if (storeState.OrganizationReducer.isOrganizationSet) {
                this.setLevelData(storeState);



            }
        }

    }

    sidenavToggleHandler = () => {
        if (this.state.sidenav) {
            this.setState({
                ...this.state,
                sidenav: false
            });
        } else {
            this.setState({
                ...this.state,
                sidenav: true
            });
        }
    }

    getProgressPercent = (progress, total) => {

        let grandTotal = total * 3;
        let percent = 0;
        if (grandTotal > 0) {
            percent = Number((progress * 100) / (grandTotal)).toFixed(2)
        }

        return percent;
    }
    componentWillReceiveProps(props) {
        if (this.props.location.pathname !== props.location.pathname) {
            if (checkSignIn()) {
                this.setLevelData(store.getState());
            }
            else {
                store.dispatch(clearUser())
                store.dispatch(clearOrganization())
                store.dispatch(clearEvents())
                disconnectMqtt();

                this.props.history.push('/')
            }



        }
    }
    async componentDidMount() {
        this.unsubscribe = store.subscribe(this.storeSubscribe);
        if (checkSignIn()) {

            if (!store.getState().OrganizationReducer.isOrganizationSet) {
                store.dispatch(gettingOrganization());
                store.dispatch(getOrganizations());

            }
            else {
                this.setState({
                    gettingData: false,
                })
                this.setLevelData(store.getState());
            }
        }
        else {
            store.dispatch(clearUser())
            store.dispatch(clearOrganization())
            store.dispatch(clearEvents())
            disconnectMqtt();

            this.props.history.push('/')
        }
    }

    componentWillUnmount() {
        this.unsubscribe();

    }

    setLevelData = (storeState) => {
        const path = this.props.history.location.pathname;
        const levelId = path.split('/')[path.split('/').length - 1];
        const org = levelId.split('_')[0];
        this.state.currentOrg = org;
        this.state.levelId = levelId
        let currentLevel = null;
        let levels = [];
        let devices = [];
        let branches = [];
        let selectedLevel = {
            name: ''
        };
        store.getState().OrganizationReducer.organizationsDetails.forEach(e => {

            if (e._id === org) {
                e.levels.forEach(i => {
                    if (i.levelId === levelId) {
                        levels.push(i)
                    }
                    if (i._id === levelId) {
                        selectedLevel = i
                    }
                })
                e.devices.forEach(i => {
                    if (i.levelId === levelId) {
                        devices.push(i)
                    }
                })
                if (e.branches.length > 0) {
                    branches = e.branches;
                }
            }
        })

        if (levels.length >= 1) {
            this.state.currentLevel = levels;
            this.state.currentDevices = [];
            this.state.noDevices = false;
            this.state.hasLevels = true;
            this.state.headerName = selectedLevel.name
            this.state.branches = branches;
        }
        else if (devices.length >= 1) {
            this.state.currentDevices = devices;
            this.state.currentLevel = []
            this.state.noDevices = false;
            this.state.hasLevels = false;
            this.state.headerName = selectedLevel.name

        }
        else {
            this.state.currentLevel = [];
            this.state.currentDevices = [];
            this.state.noDevices = true;
            this.state.hasLevels = false;
            this.state.headerName = selectedLevel.name
        }

        //connect mqtt
        this.setState({
            gettingData: false,

        })


    }
    render() {
        url = window.location.href;
        segment = url.substring(url.lastIndexOf('/') + 1);
        segment=segment.split('_');

        this.state.currentLevel.map((item) => {     
            itemSegment = item.levelId
        })
        return (
            <div>      
                <Header toggle={() => this.sidenavToggleHandler()} history={this.props.history} name={this.state.headerName} hideHomeButton={(segment.length <= 1 ) ? true : false} ></Header>
                <Sidenav width={this.state.sidenav ? '75%' : '0px'} history={this.props.history}></Sidenav>
                <div className="content " id="content" style={(this.state.gettingData) ? { display: 'block', height: '100vh' } : { display: 'block', height: '100%' }}>
                    {(this.state.gettingData) ?
                        <div className=' animated fadeIn' style={{ margin: 0, position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
                            <img src={OttoIcon} style={{ width: '60px', marginBottom: '25px', marginLeft: '8px' }} />
                            <Progress style={{ height: '5px', width: '50%', margin: '0 auto' }} color="info" value={this.state.progressPercent} />

                        </div>
                        :
                        /*  <Content></Content> */



                        <div>
                            {(this.state.hasLevels) ?
                                <div className="row" style={{ margin: 0 }}>
                                    {this.state.currentLevel.map((item, index) =>
                                        (this.state.branches.length === 0) ?
                                            < LevelUI key={index} history={this.props.history} item={item} />
                                            :
                                            (this.state.branches.includes(item._id) ?
                                                < LevelUI key={index} history={this.props.history} item={item} />
                                                :
                                                null
                                            )
                                    )}
                                </div> :
                                null
                            }
                            {(!this.state.hasLevels && !this.state.noDevices) ?

                                <DeviceUI devices={this.state.currentDevices} currentOrg={this.state.currentOrg} levelId={this.state.levelId} history={this.props.history} />

                                :
                                null

                            }
                            {
                                (this.state.noDevices) ?
                                    <p>No level and devices</p>
                                    :
                                    null
                            }
                        </div >

                    }
                </div>

                <Footer></Footer>
            </div>
        );
    }
}

export default Levels;