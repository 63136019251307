import { getHierarchy } from '../../shared/services/levels'
import { getHubsByLevel } from '../../shared/services/hubs'
import { getDevicesByLevel } from '../../shared/services/devices'
import { getOrganizationList } from '../../shared/services/organizations'

import { read, readSingle } from '../../database';

import { Offline, Online } from "react-detect-offline";

import { write_data, remove_single, remove_all, remove_db } from '../../functions/idb_functions';


let getOrganizationSuccess = (organizationList, organizationsDetails) => {

    write_data('organizations', 'organizations', 'List', organizationList);
    write_data('organizations', 'organizations', 'Details', organizationsDetails);

    return {
        type: 'ORGANIZATION_DETAILS',
        payload: {
            organizationList,
            organizationsDetails
        }
    }
}


export let gettingOrganization = () => {
    return {
        type: "GETTING_ORGANIZATIONS"
    }
}

export let getOrganizations = () => {

    return async (dispatch) => {

        if (navigator.onLine) {
            let organizationResponse = await getOrganizationList();
            let organizationList = [];
            let counter = 0;
            let organizationsCount = 0;

            if (organizationResponse) {

                if (organizationResponse.error === true) {   //if error occur during login

                    return dispatch(getOrganizationFail(organizationResponse));

                }
                else {

                    organizationList = organizationResponse.levels;
                    organizationsCount = organizationList.length;

                    if (organizationList.length > 0) {
                        let array = [];
                        let branches = [];
                        for (const level of organizationList) {
                            let levels = await getHierarchy(level._id);
                            counter++;
                            dispatch(addCounter(counter, organizationsCount));

                            let hubs = await getHubsByLevel(level._id);
                            counter++;
                            dispatch(addCounter(counter, organizationsCount));

                            let devices = await getDevicesByLevel(level._id);
                            counter++;
                            dispatch(addCounter(counter, organizationsCount));

                            if (level.branches) {
                                branches = level.branches
                            }


                            array.push({
                                _id: level._id,
                                levels,
                                hubs,
                                devices,
                                branches
                            });
                        }


                        return dispatch(getOrganizationSuccess(organizationList, array))


                    }
                    return dispatch(getOrganizationSuccess([], []))


                }

            }
        }
        else {
            let resultsList = '';
            let resultsArray = '';

            if (!window.indexedDB) {
                console.log('IndexedDb is not supported by browser');
            } else {
                resultsList = await readSingle('organizations', 'organizations', 'List');
                resultsArray = await readSingle('organizations', 'organizations', 'Details');
            }

            console.log(resultsList, resultsArray)

            return dispatch(getOrganizationSuccess(resultsList, resultsArray))
        }


    }
}

let addCounter = (counter, organizationsCount) => {
    return {
        type: 'PROGRESS_COUNTER',
        payload: {
            counter,
            organizationsCount
        }
    }
}

let getOrganizationFail = (error) => {
    return {
        type: 'ORGANIZATION_GET_ERROR',
        payload: error
    }
}


export let clearOrganization = () => {
    return {
        type: 'CLEAR_ORGANIZATION',

    }
}