import React, { Component } from 'react';
import './Sidenav.css';
import store from '../../store'
import { clearOrganization } from '../../redux/actions/organizationActions'
import { clearUser } from '../../redux/actions/usersActions'
import { clearEvents } from '../../redux/actions/eventsAction'
import { disconnectMqtt } from '../../mqtt/mqtt'
import cookieService from '../../shared/utilities/cookies'

import { write_data, remove_single, remove_all, remove_db } from '../../functions/idb_functions';

import { read_single, read_all } from '../../redux/actions/idbActions';

import { bindActionCreators } from 'redux';

import LogoutLogo from '../../assets/icons/shutdown-50x50-blue.png'
import User from '../../assets/icons/user.png'
import { connect } from 'react-redux';
import { readSingle } from '../../database';


const logout = (props) => {
    remove_db('signin');
    remove_db('organizations');
    remove_db('events');

    store.dispatch(clearUser())
    store.dispatch(clearOrganization())
    store.dispatch(clearEvents())
    if (navigator.onLine) {
        disconnectMqtt();
        cookieService.deleteCookie('jwt')
    }
    props.history.push('/')
}

class sidenav extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.read_single('signin', 'signin', 'key001')
    }


    render() {
        return (
            <div>
                <div id="sidenav" className="sidenav" style={{ width: this.props.width }}>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <img src={User} style={{ filter: 'brightness(50%)' }} />
                        {(this.props.idb_state.rw) ?
                            <p id="username" style={{ fontSize: '12px' }}>{cookieService.getCookie('user')}</p>
                            :
                            null}
                    </div>

                    <div onClick={() => this.props.history.push(`/levels`)} style={{ fontSize: '16px', marginTop: '10px' }}><span style={{ fontSize: '20px'}} className="mdi mdi-home-outline"></span>Home</div>

                    {/* <div onClick={() => this.props.history.push(`/add`)} style={{ fontSize: '16px', marginTop: '10px' }}><span style={{ fontSize: '20px'}} className="mdi mdi-plus"></span>Add Device</div> */}

                    <div onClick={() => logout(this.props)} style={{ fontSize: '16px', marginTop: '10px' }}><span style={{ fontSize: '20px'}} className="mdi mdi-power"></span>Logout</div>

                    {/* this.props.history.push(`/levels/${this.props.match.params.levelId}`); */}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        idb_state: state.idb_state,
        UserReducer: state.UserReducer
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ read_single, read_all }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(sidenav);
