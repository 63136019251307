import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Particles from '../../components/Particles/Particles';
import ClipLoader from 'react-spinners/ClipLoader';
import './Signin.css';
import { connect } from 'react-redux';
import { loginProcess, loginWithDatabase } from '../../redux/actions/usersActions'
import { read_single } from '../../redux/actions/idbActions'
import { getOrganizations, clearOrganization, gettingOrganization } from '../../redux/actions/organizationActions'
import store from '../../store'
/* import usersService from '../../shared/services/users';
import levelsService from '../../shared/services/levels';
import hubsService from '../../shared/services/hubs';
import devicesService from '../../shared/services/devices'; */
import { signIn, checkSignIn, signOut, getSignedInUser } from '../../shared/services/users';
import { getdata, disconnectMqtt } from '../../mqtt/mqtt'
import LTlogo from '../../assets/icons/logo.png'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


class Signin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            user: {
                _id: '',
                password: ''
            },
            isSignedIn: false,
            isErrorMessage: false
        }
    }

    storeSubscribe = () => {
        let storeState = store.getState();
        console.log(storeState)
        console.log(this.props)

        if (storeState.UserReducer.isLoginProcessing) {
            this.setState({
                loading: true
            })
        }
        else {
            this.setState({
                loading: false
            })
        }
        if (storeState.UserReducer.isAuthenticated) {
            this.props.history.push('/levels');
            getdata();

        }
        else if (storeState.UserReducer.isError) {
            console.log('error')
            this.setState({ isErrorMessage: true })
            // window.alert('Code: ' + storeState.UserReducer.errorMessage.statusCode + ' Message: ' + storeState.UserReducer.errorMessage.message);
        }
    }
    async componentDidMount() {

        this.unsubscribe = store.subscribe(this.storeSubscribe);



    }
    componentWillUnmount() {
        this.unsubscribe();
    }
    _idChangedHandler = (event) => {
        this.setState({
            user: {
                _id: event.target.value,
                password: this.state.user.password
            }
        });
    }

    passwordChangedHandler = (event) => {
        this.setState({
            user: {
                _id: this.state.user._id,
                password: event.target.value
            }
        });
    }


    signInClickHandler = async () => {
        // console.log(this.props.idb_state.rw)
        // if (this.props.idb_state.rw) {
        //     if (this.props.idb_state.rw._id === this.state.user._id && this.props.idb_state.rw.password === this.state.user.password) {
        //         this.setState({
        //             isSignedIn: true
        //         })
        //     }
        // }
        // else {
        this.props.startLogin();
        this.props.loginUser(this.state.user);
        // }


        /*   this.setState({
              loading: true
          });
  
          let response = await usersService.signIn(this.state.user);
  
          let r2 = await usersService.getSignedInUser();
          let array = [];
  
          console.log(r2);
  
          r2.levels.forEach(async (level) => {
              let levels = await levelsService.getHierarchy(level._id);
              let hubs = await hubsService.getByLevel(level._id);
              let devices = await devicesService.getByLevel(level._id);
  
              array.push({
                  _id: level._id,
                  levels,
                  hubs,
                  devices
              });
          });
  
          console.log(array);
  
          if(!response.error) {
              this.setState({
                  loading: false,
                  user: {
                      _id: this.state._id,
                      password: ''
                  }
              });
  
              this.props.history.replace('/levels');
          } else {
              this.setState({
                  loading: false,
                  user: {
                      _id: '',
                      password: ''
                  }
              });
          } */
    }

    componentWillMount() {
        // this.props.readDataFromIdb();
        this.setState({
            isSignedIn: checkSignIn()
        });
        if (checkSignIn()) {
            //connect mqtt
        }
        console.log('component mount')
    }

    _handleError = () => {
        this.setState({ isErrorMessage: false })
    }

    render() {
        if (this.state.isSignedIn) {
            return <Redirect to="/levels"></Redirect>
        }

        return (
            <div style={{ minHeight: '100%', position: 'relative', paddingBottom: '40vh' }}>
                <Modal isOpen={this.state.isErrorMessage}>
                    <ModalHeader>Error</ModalHeader>
                    <ModalBody>
                        <div>Message: {store.getState().UserReducer.errorMessage.message}</div>
                    </ModalBody>
                    <ModalFooter onClick={this._handleError}>OK</ModalFooter>
                </Modal>

                <Particles />

                {(window.screen.width < 600) ?
                    <div className="heading" style={{ marginTop: '140px' }}>
                        <h1>OTTO Home</h1>
                        <p>One app for everything in your home.</p>
                    </div>
                    :
                    <div className="heading" style={{ marginTop: '220px' }}>
                        <h1>OTTO Home</h1>
                        <p>One app for everything in your home.</p>
                    </div>
                }

                <div className="form">
                    <input type="text" placeholder="Email"
                        onChange={(event) => this._idChangedHandler(event)} />

                    <input type="password" placeholder="Password"
                        onChange={(event) => this.passwordChangedHandler(event)} />

                    <button onClick={() => this.signInClickHandler()}>
                        Sign in
                        <ClipLoader
                            css={`
                                margin-left: 10px; 
                                margin-top: 0.5px; 
                                position: absolute;
                            `}
                            sizeUnit={"px"}
                            size={10}
                            color={"#333"}
                            loading={this.state.loading} />
                    </button>
                </div>

                <div className="footer" style={{ backgroundColor: 'transparent', boxShadow: 'none', position: 'absolute', bottom: '0px' }}><img src={LTlogo} width="100px" style={{ display: 'block', margin: 'auto' }} /></div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {

        UserReducer: state.UserReducer,
        idb_state: state.idb_state
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // readDataFromIdb: () => {
        //     dispatch(read_single('database', 'signin', 'key001'))
        // },

        startLogin: () => {
            dispatch(loginProcess())
        },

        loginUser: (user) => {
            dispatch(loginWithDatabase(user))
        },

        clearOrganizationFromStore: () => {
            dispatch(clearOrganization())
        },

        startGettingOrganization: () => {
            dispatch(gettingOrganization())
        },

        getOrganizationDetails: () => {
            dispatch(getOrganizations())
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Signin);