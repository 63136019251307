import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import Content from '../Content/Content';
import store from '../../store';
import roomImg from '../../assets/icons/room-50x50.png';
import OTTO from '../../assets/icons/mind-map-filled-100x100.png';
import './componentUI.css'



class OrganizationUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            levelCount: 0,
            deviceCount: 0
        }
    }





    componentDidMount() {
        let storeData = store.getState().OrganizationReducer.organizationsDetails;
        let levelCount = 0;
        let deviceCount = 0;
        storeData.forEach((i) => {
            if (i._id === this.props.item._id) {
                levelCount = i.levels.length - 1;
                deviceCount = i.devices.length;
                this.setState({
                    levelCount,
                    deviceCount
                })
            }
        })


    }
    render() {
        return (


            // <div className="col-12 animated fadeIn" style={{ marginTop: '5px', padding: '5px' }} onClick={() => this.props.history.push('/levels/' + this.props.item._id)}>

            //     <div style={{ width: '100%', borderRadius: '10px' }} className="component-container-box">
            //         <div className="row" style={{ margin: 0 }}>
            //             <div className='col-3' style={{ width: '100%', overflow: 'hidden', paddingTop: 10, paddingBottom: 10, paddingLeft: 15, backgroundColor: '#107895', borderRadius: '10px 0px 0px 10px' }}>
            //                 <img width={50} height={50} src={OTTO} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //             </div>
            //             <div className='col-9' style={{ textAlign: 'left', paddingTop: 10, paddingBottom: 10, paddingRight: 25 }}>
            //                 <h6 style={{ fontWeight: 'bold' }}>{this.props.item._id}</h6>

            //                 <div className="">
            //                     <div style={{ fontSize: 12 }}><span style={{ fontWeight: 'bold' }}>Nested Levels: </span><span>{this.state.levelCount} levels</span></div>
            //                     <div style={{ fontSize: 12 }}><span style={{ fontWeight: 'bold' }}>Nested Devices: </span><span>{this.state.deviceCount} Devices</span></div>

            //                 </div>
            //             </div>
            //         </div>

            // <div className="content-container" onClick={() => this.props.history.push('/levels/' + this.props.item._id)}>
            //     <div className="content-box-icon" style={{borderRadius: '10px'}}>
            //         <div style={{ float: 'right', marginRight: 10 }}><span style={{ color: '#107895' }}></span></div>
            //         <div style={{ width: '100%', overflow: 'hidden' }}>
            //             <img width={50} height={50} src={OTTO} />
            //         </div>
            //         <p style={{ overflow: 'hidden' }}>{this.props.item._id}</p>
            //     </div>
            // </div>

            <div className="content-container" onClick={() => this.props.history.push('/levels/' + this.props.item._id)}>
                <div style={{ borderRadius: '5px', backgroundImage: 'linear-gradient(#1286A8, #2eafd3)', padding: '10px 3px' }}>
                    {/* <div style={{ float: 'right', marginRight: 10 }}><span style={{ color: 'white' }}></span></div> */}
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        <img width={50} height={50} src={OTTO} style={{ filter: 'brightness(10)' }} />
                    </div>
                    <p style={{ color: 'white' }}>{this.props.item._id}</p>
                </div>
            </div>

            //     </div>
            // </div>



        );
    }
}

export default OrganizationUI;