import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import { withRouter } from 'react-router-dom';

import GaugeGraph from './gaugeGraph';
import ConsumptionGraph from './consumptionGraph';
import MotorGraph from './motorGraph';
import IntakeStats from '../Stats/intake';
import FlowGraph from './flowGraph';
import ValveGraph from './valveGraph';

class WaterGraphs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidenav: false,
        }
    }

    sidenavToggleHandler = () => {
        if (this.state.sidenav) {
            this.setState({
                ...this.state,
                sidenav: false
            });
        } else {
            this.setState({
                ...this.state,
                sidenav: true
            });
        }
    }

    render() {
        
        if (this.props.WaterReducer.water_value === undefined) {
            this.props.history.push(`/levels/${this.props.match.params.levelId}`);
        }

        return (
            <div>
                {(this.props.WaterReducer.water_value !== undefined) ?
                    <div>
                        <Header history={this.props.history} toggle={() => this.sidenavToggleHandler()} name={this.props.WaterReducer.water_device.name}  ></Header>
                        <Sidenav width={this.state.sidenav ? '75%' : '0px'} history={this.props.history}></Sidenav>

                        {(this.props.WaterReducer.water_device.type === "WaterTank") ?
                            <div className='row' style={{ margin: '60px 2%' }}>
                                <GaugeGraph value={this.props.WaterReducer.water_value} valueDate={this.props.WaterReducer.water_valueDate} motorConfigure={this.props.WaterReducer.water_device.metadata.motor} connection={this.props.mqttReducer.event.value} />
                                <ConsumptionGraph device={this.props.WaterReducer.water_device} />
                                {(this.props.WaterReducer.water_device.metadata.motor === true) ?
                                    <MotorGraph org={this.props.WaterReducer.water_org} valueDate={this.props.WaterReducer.water_valueDate} device={this.props.WaterReducer.water_device} />
                                    :
                                    null
                                }
                                {(this.props.WaterReducer.water_device.metadata.reports === true) ?
                                    <IntakeStats device={this.props.WaterReducer.water_device} />
                                    :
                                    null
                                }
                            </div>
                            :
                            ((this.props.WaterReducer.water_device.type === "FlowMeter") ?
                                <div className='row' style={{ margin: '60px 2%' }}>
                                    <FlowGraph device={this.props.WaterReducer.water_device} />
                                    {(this.props.WaterReducer.water_device.metadata.valve === true) ?
                                        <ValveGraph org={this.props.WaterReducer.water_org} valueDate={this.props.WaterReducer.water_valueDate} device={this.props.WaterReducer.water_device} />
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            )
                        }
                        <Footer></Footer>
                    </div>
                    :
                    null
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        WaterReducer: state.WaterReducer,
        mqttReducer: state.MqttReducer
    }
}

export default connect(mapStateToProps, null)(withRouter(WaterGraphs));