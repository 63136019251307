const INITIAL_STATE = {
   eventStack:[],
   currentEvent:{},
   gettingCurrentEvents:false,
   gettingCurrentEventsError:false,
   errorMessage:{}
}
const EventsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      
        case "GETTING_CURRENT_EVENTS":
        return{...state,currentEvent:{},gettingCurrentEvents:true, gettingCurrentEventsError:false,errorMessage:{}}

        case "CURRENT_EVENTS_GET":
        return{...state,currentEvent:action.payload,gettingCurrentEvents:false, gettingCurrentEventsError:false,errorMessage:{}}
      
        case "CURRENT_EVENTS_ERROR":
        return{...state,currentEvent:{},gettingCurrentEvents:false, gettingCurrentEventsError:true,errorMessage:action.payload}

        case "CLEAR_EVENTS":
       
        return{...state,
            eventStack:[],
            currentEvent:{},
            gettingCurrentEvents:false,
            gettingCurrentEventsError:false,
            errorMessage:{}}
       
           
            default:
            return state
    }
}
export default EventsReducer