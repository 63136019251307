import React, { Component } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import QrReader from 'react-qr-reader';
import base64 from 'base-64';
import utf8 from 'utf8';
// import WiFiControl from 'wifi-control';
// import WifiManager from 'react-native-wifi';

class AddDevice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidenav: false,
            headerName: 'Add Device',
            wifiid: '',
            wifipass: ''
        }
    }

    handleScan = data => {
        if (data) {
            var temp = data.split(",");
            if (temp.length > 1) {
                var arr = temp[0].match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/g);
                var arr1 = temp[1].match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/g);
                console.log(arr, arr1);

                if (arr && arr1) {
                    var decodedData = base64.decode(temp[0]);
                    var decodedData1 = base64.decode(temp[1]);
                    console.log(decodedData);
                    console.log(decodedData1);

                    this.setState({
                        wifiid: decodedData,
                        wifipass: decodedData1
                    })
                }
            }
        }
    }

    handleError = err => {
        console.error(err)
    }

    sidenavToggleHandler = () => {
        if (this.state.sidenav) {
            this.setState({
                ...this.state,
                sidenav: false
            });
        } else {
            this.setState({
                ...this.state,
                sidenav: true
            });
        }
    }

    render() {
        return (
            <div>
                <Header toggle={() => this.sidenavToggleHandler()} history={this.props.history} name={this.state.headerName}></Header>
                <Sidenav width={this.state.sidenav ? '75%' : '0px'} history={this.props.history}></Sidenav>

                <div style={{ padding: '64px 2%' }}>
                    <QrReader
                        delay={300}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        style={{ width: '100%' }}
                    />
                    <p>{this.state.wifiid}</p>
                    <p>{this.state.wifipass}</p>
                </div>

                <Footer></Footer>
            </div>
        );
    }
}

export default AddDevice;