const INITIAL_STATE = {
    organizationsList: [],
    organizationsDetails: [],
    isProcessing: false,
    isOrganizationSet: false,
    isError: false,
    errorMessage: {},
    progressCounter: 0,
    totalOrganizations: 0,
}
const OrganizationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        /*  case "REGISTER":
             return { ...state, isSignupProcessing: true, isRegistered: false, isError: false };
 
         case "REGISTER_SUCCESS":
             return { ...state, isRegistered: true, isSignupProcessing: false, isAuthenticated: true, authUser: action.payload,errorMessage: {}  };
 
         case "REGISTER_FAIL":
             return { ...state, errorMessage: action.payload, isSignupProcessing: false, isError: true };
 
         case "LOGIN":
             return { ...state, isLoginProcessing: true, isAuthenticated: false, isError: false };
 
         case "LOGIN_SUCCESS":
             return { ...state, isAuthenticated: true, authUser: action.payload, isLoginProcessing: false, isRegistered: false, errorMessage: {} };
 
         case "LOGIN_FAIL":
             return { ...state, errorMessage: action.payload, isLoginProcessing: false, isError: true, isAuthenticated: false,authUser: {}}; */

        case "GETTING_ORGANIZATIONS":
            return { ...state, organizationsList: {}, organizationsDetails: {}, isProcessing: true, isError: false, errorMessage: {}, isOrganizationSet: false, progressCounter: 0, totalOrganizations: 0 };


        case "ORGANIZATION_DETAILS":
            return { ...state, organizationsList: action.payload.organizationList, organizationsDetails: action.payload.organizationsDetails, isProcessing: false, isError: false, errorMessage: {}, isOrganizationSet: true, progressCounter: 0, organizationsCount: action.payload.organizationsCount };

        case "PROGRESS_COUNTER":
            return { ...state, progressCounter: action.payload.counter, totalOrganizations: action.payload.organizationsCount };

        case "ORGANIZATION_GET_ERROR":
            return { ...state, organizationsList: {}, organizationsDetails: {}, isProcessing: false, isError: true, errorMessage: action.payload, isOrganizationSet: false, progressCounter: 0, totalOrganizations: 0 };

        case "CLEAR_ORGANIZATION":

            return {
                ...state, organizationsList: [],
                organizationsDetails: [],
                isProcessing: false,
                isOrganizationSet: false,
                isError: false,
                errorMessage: {},
                progressCounter: 0,
                totalOrganizations: 0,
            };



        default:
            return state
    }
}
export default OrganizationReducer