import React, { Component } from 'react';

import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
    Fade,
    Collapse,
    Alert,
    InputGroup
} from "reactstrap";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";
import prerequisiteEvents from './prerequisiteEvents';

let updatedValue = null;
class alertEvents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            events: [],
            prerequisites: [],
            show: "",
            deviceId: "",
            type: "",
            condition: "",
            value: 5,  // for give default value to pre requisites
        }
    }

    componentWillMount() {
        // console.log(this.props.device)
        this.setState({
            events: this.props.events,
            prerequisites: this.props.events,
            show: this.props.show
        }
            , () => { console.log("this.state.prerequisites", this.state.prerequisites) })

    }

    // componentWillReceiveProps(nextProps) {
    //     if (this.props.events != nextProps.events) {
    //         this.setState({ prerequisites: nextProps.events })
    //     }
    // }


    // handleAdd = () => {
    //     let events = this.state.events;
    //     events.push({
    //         deviceId: this.props.device._id,
    //         hubId: this.props.device.hubId,
    //         type: 'Water_Motor',
    //         value: (this.props.type === 'Low') ? 1 : 0
    //     })
    //     this.setState({
    //         events
    //     })
    // }
    // handleAddPre = () => {

    //     let prerequisites = this.state.prerequisites;
    //     prerequisites.push({
    //         deviceId: this.props.device._id,
    //         type: 'Water_WaterTank_Status',
    //         condition: 'LOW',
    //         value: "60"
    //     })
    //     this.setState({
    //         prerequisites
    //     })
    // }


    // handleRemove = (index) => {
    //     let events = this.state.events;
    //     events.splice(index, 1);
    //     this.setState({
    //         events
    //     })
    // }
    // handleRemovePre = (index) => {
    //     let prerequisites = this.state.prerequisites;
    //     prerequisites.splice(index, 1);
    //     this.setState({
    //         prerequisites
    //     })
    // }

    // handleDropdown = (e, index) => {
    //     let events = this.state.events;
    //     let dId = events[index].deviceId.split('_');
    //     dId = dId[0] + '_' + dId[1] + '_'
    //     events[index].deviceId = dId + e.target.value;
    //     this.setState({
    //         events
    //     })
    // }
    // handleChangeDevice = (e, index) => {
    //     let prerequisites = this.state.prerequisites;
    //     let dId = prerequisites[index].deviceId.split('_');
    //     dId = dId[0] + '_' + dId[1] + '_'
    //     prerequisites[index].deviceId = dId + e.target.value
    //     this.setState({
    //         prerequisites
    //     })
    // }
    // handleChangeType = (e, index) => {
    //     let prerequisites = this.state.prerequisites;
    //     prerequisites[index].type = e.target.value;
    //     this.setState({
    //         prerequisites
    //     })
    // }
    // handleChangeCondition = (e, index) => {
    //     let prerequisites = this.state.prerequisites;
    //     prerequisites[index].condition = e.target.value;
    //     this.setState({
    //         prerequisites
    //     })
    // }

    handleChangeValueNew = (e) => {
        this.setState({ value: e.target.value },
            () => { console.log("handleChangeValueNew", this.state.value) });
        console.log("removing change1")
    }
    
    handleChangeValue = (e, index) => {
        console.log("updating value")
        let prerequisites = this.state.prerequisites;
        prerequisites[index].value = e.target.value;
        updatedValue = e.target.value;
        console.log("updatedValue", updatedValue)
        this.setState({
            prerequisites
        })
        if (updatedValue == '') {
            let prerequisites = this.state.prerequisites;
            prerequisites.splice(index, 1);
            this.setState({
                prerequisites
            })
        }
        console.log("removing change2")
        this.setState({ value: updatedValue })
    }

    _handleSave = () => {
        this.props.handler(this.state.events, this.props.type)
    }
    _handleSavePre = () => {
        if (updatedValue !== '' || this.state.value !== '') {
            if (this.state.prerequisites.length === 0) {
                console.log("this.state.prerequisites1", this.state.prerequisites)
                let prerequisites = this.state.prerequisites;
                prerequisites.push({
                    deviceId: this.props.device._id,
                    type: 'Water_WaterTank_Status',
                    condition: 'LOW',
                    value: this.state.value
                })
                this.setState({
                    prerequisites
                })
            }
            else if (this.state.prerequisites.length > 0) {
                let prerequisites = this.state.prerequisites;
                prerequisites = [{
                    deviceId: this.props.device._id,
                    type: 'Water_WaterTank_Status',
                    condition: 'LOW',
                    value: updatedValue
                }]
                this.setState({
                    prerequisites
                })
            }
        }

        this.props.handler(this.state.prerequisites, this.props.type)
    }


    render() {
        if (this.props.show === true) {


            return (
                <React.Fragment>
                    {/* <div style={{ textAlign: 'right', color: '#20536c' }}><i class="fa fa-plus" onClick={this.handleAddPre}></i></div> */}

                    {/* {
                        (this.props.device._id === 'LTtesting_84f3ebb398d3_Overhead') ?
                            <div> */}
                    {
                        (this.state.prerequisites.length === 0) ?
                            <div>
                                <label className="col p-0 d-flex text-left">
                                    <h6 style={{ fontSize: "13px" }}>UG Greater than equal to</h6>
                                    <input style={{ border: '0px', float: 'right', display: 'block', width: '75px' }}
                                        className="col-3"
                                        type="number"
                                        name="value"
                                        ref="value"
                                        min='0'
                                        max='100'
                                        // placeholder="Value"
                                        value={this.state.value}
                                        onChangeCapture={(e) => this.handleChangeValueNew(e)}
                                    />
                                    %
                                </label>
                            </div>
                            : null
                    }

                    {this.state.prerequisites.map((event, index) => (
                        <label className="col p-0 d-flex text-left">
                            <h6 className="col p-0" style={{ fontSize: '13px' }}>UG Greater than equal to</h6>
                            <input style={{ border: '0px', float: 'right', display: 'block', width: '75px', height: '26px' }}
                                className="col-3 p-0"
                                type="number"
                                name="value"
                                ref="value"
                                min='0'
                                max='100'
                                // placeholder="Value"
                                value={event.value}
                                onChangeCapture={(e) => this.handleChangeValue(e, index)}
                            />
                                %
                        </label>
                    ))}



                    {/* {this.state.prerequisites.map((event, index) => (
                        <Card >
                            <CardBody>
                                <Row>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                        <FormGroup>
                                            <h6 style={{ fontSize: '13px', marginBottom: '0px', textAlign: 'left' }}>Tank</h6>

                                            <select
                                                style={{ padding: '5px', fontSize: '12px' }}
                                                className="mt-1 w-100"
                                                type="select"
                                                name="deviceId"
                                                placeholder="Tank"
                                                // value={this.state.deviceId}
                                                // onChange={this.valueHandler.bind(this)}
                                                value={event.deviceId.split('_')[event.deviceId.split('_').length - 1]}
                                                onChange={(e) => this.handleChangeDevice(e, index)}
                                            >

                                                <option value={"Overhead"}>Overhead</option>
                                                <option value={"Underground"}>Underground</option>
                                            </select>                                  
                                        </FormGroup>
                                    </Col>

                                    <Col xs="12" sm="12" md="6" lg="6">
                                        <FormGroup>
                                            <h6 style={{ fontSize: '13px', marginBottom: '0px', textAlign: 'left' }}>Condition</h6>
                                            <select style={{ padding: '5px', fontSize: '12px' }}
                                                className="mt-1 w-100"
                                                type="select"
                                                name="condition"
                                                ref="condition"
                                                placeholder="Condition"
                                                value={event.condition}
                                                onChange={(e) => this.handleChangeCondition(e, index)}
                                            >
                                                <option value="LOW">Less than equal to</option>
                                                <option value="EQUAL">Equal to</option>
                                                <option value="HIGH">Greater than equal to</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6">
                                        <FormGroup className="row m-0">
                                            <h6 className="col-12 p-0" style={{ fontSize: '13px', marginBottom: '0px', textAlign: 'left' }}>Value</h6>
                                            <Input style={{fontSize: '12px' }}
                                                className="col-10 mt-1 w-100"
                                                type="number"
                                                name="value"
                                                ref="value"
                                                placeholder="Value"
                                                value={event.value}
                                                onChange={(e) => this.handleChangeValue(e, index)}
                                                
                                            />
                                             <div className="col-2 d-flex align-self-center">%</div>
                                        </FormGroup>
                                       
                                    </Col>
                                    <div style={{ display: 'inline', float: 'right', color: 'red' }}><i class="fa fa-times" onClick={(e) => this.handleRemovePre(index)}></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                    // ))} */}
                    <button style={{ width: "100%", backgroundColor: "#19a848", color: "white", borderColor: "#19a848", fontSize: '12px', margin: '10px 0px' }} onClick={this._handleSavePre}>Save</button>
                    {/* </div>
                            : null

                    } */}
                </React.Fragment>
            );
        }
        // return (
        //     <React.Fragment>
        //         {/* <div>Add</div> */}
        //         {/* <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}></div> */}
        //         <div style={{ textAlign: 'right', color: '#20536c' }}><i class="fa fa-plus" onClick={this.handleAdd}></i></div>
        //         {this.state.events.map((event, index) => (
        //             <div className="row" style={{ marginTop: '5px' }}>
        //                 <div className="col-12" style={{ textAlign: 'left' }}>
        //                     <h6 style={{ fontSize: '13px', marginBottom: '0px' }}>Tank</h6>
        //                     <select value={event.deviceId.split('_')[event.deviceId.split('_').length - 1]} onChange={(e) => this.handleDropdown(e, index)} style={{ width: '200px', fontSize: '13px' }}>
        //                         <option value={"Overhead"}>Overhead</option>
        //                         <option value={"Underground"}>Underground</option>
        //                     </select>
        //                     <div style={{ display: 'inline', float: 'right', color: 'red' }}><i class="fa fa-times" onClick={(e) => this.handleRemove(index)}></i></div>
        //                 </div>
        //             </div>
        //         ))}
        //         <button style={{ width: "100%", backgroundColor: "#19a848", color: "white", borderColor: "#19a848", fontSize: '12px', margin: '10px 0px' }} onClick={this._handleSave}>Save</button>
        //     </React.Fragment>
        // );

    }
}

export default alertEvents;