import React, { Component } from 'react';

import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import LiquidFillGauge from 'react-liquid-gauge';
import { Redirect, withRouter } from 'react-router-dom'

// const settings = {
//     radius: 200,
//     interpolate: interpolateRgb('#6495ed', '#dc143c'),
//     fillColor: settings.interpolate(this.state.data.value / 100),
//     gradientStops: [
//         {
//             key: '0%',
//             stopColor: color(settings.fillColor).darker(0.5).toString(),
//             stopOpacity: 1,
//             offset: '0%'
//         },
//         {
//             key: '50%',
//             stopColor: settings.fillColor,
//             stopOpacity: 0.75,
//             offset: '50%'
//         },
//         {
//             key: '100%',
//             stopColor: color(settings.fillColor).brighter(0.5).toString(),
//             stopOpacity: 0.5,
//             offset: '100%'
//         }
//     ]
// }

class GaugeGraph extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                value: this.props.value,
                valueDate: this.props.valueDate
            },
            connection: 0
        }
    }
    // shouldComponentUpdate(nextProps) {      //for fixing gauge live value
    //     if (this.props.value != nextProps.value) {
    //         this.setState({
    //             data: {
    //                 value: nextProps.value,
    //                 valueDate: nextProps.valueDate
    //             }
    //         })
    //     }
    // }
    componentDidMount() {
        this.setState({ connection: this.props.connection })
    }

    _handleSchedule = () => {
        console.log("object", this.props.history)
        this.props.history.push(`/levels/${this.props.match.params.levelId}/water/schedule`);
    }

    render() {
        let radius = 0;
        // if (window.screen.width < 992) {
        //     radius = 80;
        // }
        // else {
        radius = 100;
        // }
        const interpolate = interpolateRgb('#1FC8DB', '#1FC8DB');
        const fillColor = interpolate(this.state.data.value / 100);
        const gradientStops = [
            {
                key: '0%',
                stopColor: color(fillColor).darker(0.5).toString(),
                stopOpacity: 1,
                offset: '0%'
            },
            {
                key: '50%',
                stopColor: fillColor,
                stopOpacity: 0.75,
                offset: '50%'
            },
            {
                key: '100%',
                stopColor: color(fillColor).brighter(0.5).toString(),
                stopOpacity: 0.5,
                offset: '100%'
            }
        ];

        return (
            <div className="col-12 animated fadeIn" style={{ marginTop: '20px' }} >
                <div style={{ width: '100%' }} className="component-container-box">
                    <div className="row">
                        <div className='col-10' style={{ padding: '5px 20px' }}>
                            <h6 style={{ textAlign: 'left', marginBottom: '0px', fontSize: '14px' }}>Current Status</h6>
                            <p style={{ textAlign: 'left', fontSize: '12px' }}>Since: {this.props.valueDate}</p>
                        </div>
                        <div className='col-2' style={{marginTop:"5px"}}>
                            {/* {(this.state.connection) ?
                                <span className="mdi mdi-wifi mdi-10px mr-1"  ></span>
                                :
                                <span className="mdi mdi-wifi-off mdi-10px mr-1"></span>
                            } */}

                            {(this.props.motorConfigure) ?

                                <span className="mdi mdi-settings-outline mdi-10px" onClick={this._handleSchedule}></span>
                                : null
                            }
                        </div>
                    </div>
                    <div style={{ padding: '1%' }}>
                        <LiquidFillGauge
                            style={{ margin: '0 auto' }}
                            width={radius * 2}
                            height={radius * 2}
                            value={this.state.data.value}
                            percent="%"
                            textSize={1}
                            textOffsetX={10}
                            textOffsetY={15}
                            // textRenderer={() => {
                            //     const radius = Math.min(this.props.height / 2, this.props.width / 2);
                            //     const textPixels = (this.props.textSize * radius / 2);
                            //     const valueStyle = {
                            //         fontSize: textPixels
                            //     };
                            //     const percentStyle = {
                            //         fontSize: textPixels * 0.6
                            //     };

                            //     return (
                            //         <tspan>
                            //             <tspan className="value" style={valueStyle}>{this.state.data.value}</tspan>
                            //             <tspan style={percentStyle}>{props.percent}</tspan>
                            //         </tspan>
                            //     );
                            // }}
                            riseAnimation
                            waveAnimation
                            waveFrequency={2}
                            waveAmplitude={1}
                            gradient
                            gradientStops={gradientStops}
                            circleStyle={{
                                fill: fillColor
                            }}
                            waveStyle={{
                                fill: fillColor
                            }}
                            textStyle={{
                                fill: color('black').toString(),
                                fontFamily: 'Arial'
                            }}
                            waveTextStyle={{
                                fill: color('black').toString(),
                                fontFamily: 'Arial'
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(GaugeGraph);