import { getEventsByLevel } from './../../shared/services/events'

import { write_data, remove_single, remove_all, remove_db } from '../../functions/idb_functions';

import { read, readSingle } from '../../database';

export let getCurrentLevelEvents = (level) => {

    return async (dispatch) => {

        if (navigator.onLine) {

            dispatch(gettingEvents());

            let levelEvents = await getEventsByLevel(level);



            if (levelEvents.error === true) {   //if error occur during login

                return dispatch(gettingEventsFail(levelEvents))
            }
            else {

                return dispatch(gettingEventsSuccess(levelEvents))
            }

        }

        else{
            let results = '';

            if (!window.indexedDB) {
                console.log('IndexedDb is not supported by browser');
            } else {
                results = await readSingle('events', 'events', 'key');
            }

            console.log(results)

            return dispatch(gettingEventsSuccess(results))
        }

    }
}


let gettingEventsFail = (response) => {
    return {
        type: "CURRENT_EVENTS_ERROR",
        payload: response
    }
}


let gettingEventsSuccess = (response) => {

    write_data('events', 'events', 'key', response);

    return {
        type: "CURRENT_EVENTS_GET",
        payload: response
    }
}



let gettingEvents = () => {
    return {
        type: "GETTING_CURRENT_EVENTS",

    }
}

export function clearEvents() {
    return {
        type: "CLEAR_EVENTS"
    }
}