import { signIn, checkSignIn, signOut, getSignedInUser } from '../../shared/services/users';

import { write_data, remove_single, remove_all, remove_db } from '../../functions/idb_functions';

export function register() {

    return {
        type: "REGISTER"
    }
}
export function registerOnDatabase(user) {

    return {
        type: "REGISTER_SUCCESS",
        payload: user
    }
}
export function registerFail(error) {
    return {
        type: "REGISTER_FAIL",
        payload: error.message
    }
}





export let loginProcess = () => {
    return {
        type: "LOGIN"
    }
}

export let loginWithDatabase = (user) => {

    return async (dispatch) => {

        let response = await signIn(user);
        //login user

        if (response) {
            if (response.error === true) {   //if error occur during login

                return dispatch(loginFail(response))
            }
            else {

                return dispatch(loginSuccess(user, response))
            }

        }



    }
}

export let loginFail = (response) => {
    return {
        type: "LOGIN_FAIL",
        payload: response
    }
}
export let loginSuccess = (user, response) => {
    write_data('signin', 'signin', 'key001', user)

    return {
        type: "LOGIN_SUCCESS",
        payload: {
            userId: user._id,
            response: response
        }
    }
}
export let login = () => {
    return {
        type: "LOGIN"
    }
}




export function clearUser() {
    console.log('aaaaaaaaaaaa')
    return {
        type: "CLEAR_USER"
    }
}