import axios from '../utilities/axios';
import cookies from '../utilities/cookies';

export let postCommands = async (data) => {
    try {
        let token = cookies.getCookie('token');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.post('/events/failedcmds', data);
        return response.data;
    } catch (err) {
        return err;
    }
}