import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import Content from '../Content/Content';
import store from '../../store';
import roomImg from '../../assets/icons/room-50x50.png'
import './componentUI.css'
import HomeDevices from '../DevicesCategory/homeDevices';
import {getCurrentLevelEvents} from '../../redux/actions/eventsAction'
import Skeleton from '../../components/skeleton/skeleton'
import waterSchedule from '../Schedules/waterSchedule';




class DeviceUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetch:true,
            events:null,
        }
    }

    
    storeListener=()=>{
        if(this.state.fetch){
            if(store.getState().EventsReducer.gettingCurrentEvents===false){
                if(store.getState().EventsReducer.gettingCurrentEventsError===false){
                    this.setState({
                        fetch:false,
                        events : store.getState().EventsReducer.currentEvent
                    })
                }
            }
        }
    }
   

    componentDidMount() {
        this.unsubscribe = store.subscribe(this.storeListener);
        store.dispatch(getCurrentLevelEvents(this.props.levelId))
    }

  
    componentWillUnmount(){
        this.unsubscribe();
    }
    render() {
        if(this.state.fetch){
            return(
                <div className='row animated fadeIn' id="swipeZone" style={{margin:0}}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                </div>
            )
        }
        else{
            return (
            
                <div className='row' style={{margin:0}}>
                {this.props.devices.map((value,index) => <HomeDevices currentOrg={this.props.currentOrg} events={this.state.events} item={value} key={index}/>)}
                {/* motorConfigure={this.props.WaterReducer.water_device.metadata.motor} */}
                </div>
            
     );
        }
       
    }
}


export default (DeviceUI);