import { write, remove, removeSingle, removeDb } from '../database';

export async function write_data(dbName, storeName, key, document) {
    if (!window.indexedDB) {
        console.log('IndexedDb is not supported by browser');
    } else {
        await write(dbName, storeName, key, document);
    }
}

export async function remove_all(dbName, storeName) {
    if (!window.indexedDB) {
        console.log('IndexedDb is not supported by browser');
    } else {
        await remove(dbName, storeName);
    }
}

export async function remove_single(dbName, storeName, key) {
    if (!window.indexedDB) {
        console.log('IndexedDb is not supported by browser');
    } else {
        await removeSingle(dbName, storeName, key);
    }
}

export async function remove_db(dbName) {
    if (!window.indexedDB) {
        console.log('IndexedDb is not supported by browser');
    } else {
        await removeDb(dbName);
    }
}