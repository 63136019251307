import React from 'react';
import './Header.css';

const goBack = (props) => {
    if (props.history.location.pathname !== '/levels') {
        props.history.goBack()
    }
}
const Header = (props) => {
    return (
        <div className="header" style={{ backgroundImage: 'linear-gradient(#1286A8, #2eafd3)' }}>

            <div className="header-option">
                {(props.hideHomeButton == true) ?
                    null
                    :
                    <span style={{ color: 'white' }} className="mdi mdi-chevron-left mdi-24px" onClick={() => goBack(props)} ></span>
                }
            </div>



            <div className="header-heading">
                <h3 id="levelName" style={{ fontSize: '18px', paddingTop: '5px', color: 'white' }}>{props.name}</h3>
            </div>

            <div className="header-option">
                <span style={{ color: 'white' }} className="mdi mdi-menu mdi-24px" onClick={props.toggle}></span>
            </div>
        </div>
    );
}

export default Header;