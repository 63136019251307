import React, { Component } from 'react';
import { connect } from 'react-redux';
// import * as config from '../../../config';
// import * as publish from '../../../getAlerts';
// import * as configration from '../../../config/config';
import * as functions from '../../functions/functions';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Bar, Line } from 'react-chartjs-2';
import $ from 'jquery';
import LoaderGif from '../../assets/img/loader/loader.gif';
import { getSelectedEvents } from '../../shared/services/events';

import { publishDeviceData } from './../../mqtt/mqtt'

import store from '../../store';
import CookieHandler from './../../shared/utilities/cookies';

import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var yLabels = {
    0: 'Off',
    1: 'On'
}

class MotorGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            limit: 10,
            rand: 0.0,
            radioSelected: 1,
            renderVar: 20,
            overflow: 'scroll',
            graphDate: null,
            isLoader: false,
            volume: 0.0,
            consumption: 0.0,
            shouldredraw: false,
            sinceDate: '',
            mainChartOpts:
            {
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "bottom"
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: 10,
                                maxTicksLimit: 5,
                            },
                            gridLines: {
                                display: false,
                            },
                            /*  type: 'time',
                             distribution: 'linear', */
                            time: {
                                /*   displayFormats: {
                                    minute: 'h:mm:ss a[\n]ll',
                                    millisecond: 'h:mm:ss a[\n]ll',
                                    second: 'h:mm:ss a[\n]ll',
                                    hour: 'h:mm:ss a[\n]ll',
                                    day: 'h:mm:ss a ll',
                                    week: 'h:mm:ss a ll',
                                    month: 'h:mm:ss a ll',
                                    quater: 'h:mm:ss a ll',
                                    year: 'h:mm:ss a [\n] ll'
                                   
                                } */
                            }
                        }],
                    yAxes: [
                        {
                            ticks: {
                                callback: function (value) {
                                    // for a value (tick) equals to 8
                                    return yLabels[value];
                                    // 'junior-dev' will be returned instead and displayed on your chart
                                }
                            },
                            gridLines: {
                                display: false,
                            },
                        }],
                },
                elements: {
                    point: {
                        radius: 3,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            },
            mainChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Motor Status',
                        fill: false,
                        borderColor: '#5BBC93',
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: [],
                        steppedLine: true
                    }
                ],
            },
        }

        // this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
        // this.todayDate = this.todayDate.bind(this);
        this.getWaterMotorGraph = this.getWaterMotorGraph.bind(this);
    }

    async componentDidMount() {
        this.getWaterMotorGraph();
    }

    componentDidUpdate() {
        if (this.state.shouldredraw === true) {
            this.setState({
                shouldredraw: false
            })
        }
    }

    componentWillReceiveProps(props) {
        if (props.mqttReducer.event.deviceID === this.props.device._id) {
            let typeSplit = props.mqttReducer.event.type.toLowerCase().split('_');

            let eventType = '';
            let deviceType = '';

            if (typeSplit.length < 3) {
                eventType = typeSplit[1];
                deviceType = typeSplit[0];
            } else {
                eventType = typeSplit[2];
                deviceType = typeSplit[1];
            }


            if (eventType === 'motor') {
                this.setState({
                    radioSelected: props.mqttReducer.event.value,
                    shouldredraw: true
                })
                this.getWaterMotorGraph();

            }

            // if (eventType === 'connection') {

            //     this.setState({
            //         connection: props.mqttReducer.event.value,
            //         connectionDate: functions.getTime(props.mqttReducer.event.created) + ' ' + functions.getDate(props.mqttReducer.event.created)
            //     })

            // }
            // else if (deviceType === this.props.item.type.toLowerCase() || deviceType === 'water') {
            //     if (deviceType === 'ac') {
            //         if (eventType === 'status') {
            //             this.setState({
            //                 value: props.mqttReducer.event.value,
            //                 valueDate: functions.getTime(props.mqttReducer.event.created) + ' ' + functions.getDate(props.mqttReducer.event.created)
            //             })
            //         }
            //         else if (eventType === 'temperature') {
            //             this.setState({
            //                 tempValue: props.mqttReducer.event.value,

            //             })
            //         }
            //         else if (eventType === 'swing') {
            //             this.setState({
            //                 swingValue: (props.mqttReducer.event.value === 0) ? false : true,

            //             })
            //         }
            //     }
            //     else {
            //         this.setState({
            //             value: props.mqttReducer.event.value,
            //             valueDate: functions.getTime(props.mqttReducer.event.created) + ' ' + functions.getDate(props.mqttReducer.event.created)
            //         })
            //     }


            // }
        }
    }

    getHubType = (hubId, org) => {
        let currentHub = { type: '' };
        store.getState().OrganizationReducer.organizationsDetails.forEach(e => {
            if (org === e._id) {
                e.hubs.forEach(i => {
                    if (i._id === hubId) {
                        currentHub = i
                    }
                })
            }
        })

        return currentHub
    }

    generateType = (hubId, device, org, status) => {
        let currentHub = {};
        store.getState().OrganizationReducer.organizationsDetails.forEach(e => {
            if (org === e._id) {
                e.hubs.forEach(i => {
                    if (i._id === hubId) {
                        currentHub = i
                    }
                })
            }
        })

        return '' + currentHub.type + '_' + status
    }

    publishData = (type, value, device) => {
        
        if(this.props.device.motorHubId){
            var data = {
                deviceId: this.props.device._id,
                hubId: this.props.device.motorHubId,
                postHubId:this.props.device.hubId,
                value: value,
                type: type,
                createdBy: CookieHandler.getCookie('user')
            }
            publishDeviceData(this.getHubType(this.props.device.hubId, this.props.org).type, this.props.device.motorHubId, "AppEvents", "json", data);
        }
        else{
            var data = {
                deviceId: this.props.device._id,
                hubId: this.props.device.hubId,
                value: value,
                type: type,
                createdBy: CookieHandler.getCookie('user')
            }
            publishDeviceData(this.getHubType(this.props.device.hubId, this.props.org).type, this.props.device.hubId, "AppEvents", "json", data);
        }
    }

    onRadioBtnClick(value) {
        if (value === 2) {
            this.publishData(this.generateType(this.props.device.hubId, this.props.device, this.props.org, 'Motor'), 0)
        }
        else if (value === 1) {
            this.publishData(this.generateType(this.props.device.hubId, this.props.device, this.props.org, 'Motor'), value)
        }
    }

    async getWaterMotorGraph() {

        this.setState({
            isLoader: true
        })

        let data = await getSelectedEvents(this.props.device._id, '10', 'Water_Motor');
        /*   let data=await getConsumptionByDevice(deviceId); */


        if (!data.error) {
            this.setState({
                isLoader: false
            })

            var len = data.length;
            var i;
            var newStatus = [];
            var newLabel = [];
            for (i = 0; i < len; i++) {
                this.setState({ sinceDate: functions.convertToDate(data[0].created) })
                // var arr = functions.convertToDate(data[i].created,'object')
                // newLabel.push(new Date(arr[0],arr[1]-1,arr[2],arr[3],arr[4],arr[5]));
                /*   newLabel.push(functions.convertToDate(data[i].created,"live")); */
                var arr = [];
                arr.push(functions.convertToDate(data[i].created, "live"));
                arr.push(functions.convertToDate(data[i].created, 'date'));
                newLabel.push(arr);
                newStatus.push(data[i].value);

            }
            newStatus.reverse();
            newLabel.reverse();
            var prevData = this.state.mainChart;
            prevData.datasets[0].data = newStatus;

            prevData.labels = newLabel;

            this.setState({
                mainChart: prevData
            });
            this.setState({
                rand: Math.random()
            })

            if (data.length !== 0) {
                if (data[0].value === 0) {
                    this.setState({
                        radioSelected: 2
                    })
                }
                else if (data[0].value === 1) {
                    this.setState({
                        radioSelected: 1
                    })
                }
            }

        }
        else {

            this.setState({
                isLoader: false

            })
        }
    }


    render() {
        return (
            <div className="col-12 animated fadeIn" style={{ marginTop: '20px' }} >
                <div style={{ width: '100%' }} className="component-container-box">
                    <div style={{ padding: '5px' }}>
                        <h6 style={{ textAlign: 'left', marginBottom: '0px', fontSize: '14px' }}>Motor History</h6>
                        {/* <p style={{ textAlign: 'left', fontSize: '12px' }}>Since: {this.props.valueDate}</p> */}
                        <p style={{ textAlign: 'left', fontSize: '12px' }}>Since: {this.state.sinceDate}</p>
                    </div>
                    <div style={{ padding: '1%' }}>
                        {/* {(this.state.radioSelected === 1) ? */}
                        <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block" }} className="margin-top-1">
                            <ButtonGroup className="float-right mr-3" aria-label="First group" size="sm">
                                <Button onClick={() => this.onRadioBtnClick(1)} style={(this.state.radioSelected === 1) ? { backgroundColor: 'gray', color: 'white' } : { backgroundColor: 'white', color: 'black' }}>On</Button>
                                <Button onClick={() => this.onRadioBtnClick(2)} style={(this.state.radioSelected === 2) ? { backgroundColor: 'gray', color: 'white' } : { backgroundColor: 'white', color: 'black' }}>Off</Button>
                            </ButtonGroup>
                        </ButtonToolbar>

                        {/* {(this.state.radioSelected === 2) ?
                            <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block" }} className="margin-top-1">
                                <ButtonGroup className="float-right mr-3" aria-label="First group" size="sm">
                                    <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>On</Button>
                                    <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Off</Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                            :
                            null
                        } */}
                        <div id={"graph-" + this.props.device._id} style={{
                            height: 250 + 'px',
                            marginTop: 40 + 'px'
                        }}>
                            <Line redraw={this.state.shouldredraw} height={250} data={this.state.mainChart} options={this.state.mainChartOpts} />

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mqttReducer: state.MqttReducer
    }
}

export default connect(mapStateToProps, null)(MotorGraph);