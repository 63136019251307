import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import { withRouter } from 'react-router-dom';

import * as functions from '../../shared/services/repository';

class HomeSchedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidenav: false,
            alert_high: '',
            alert_high_id: '',
            alert_low: '',
            alert_low_id: '',
            schedule_start: '',
            schedule_start_id: '',
            schedule_end: '',
            schedule_end_id: ''
        }

        this._handlealert_high = this._handlealert_high.bind(this)
        this._handlealert_low = this._handlealert_low.bind(this)
        this._handleSave = this._handleSave.bind(this)
        // this.getAlertsFunction = this.getAlertsFunction.bind(this)
        this.getSchedulesFunction = this.getSchedulesFunction.bind(this)
        this._handleschedule_start = this._handleschedule_start.bind(this)
        this._handleschedule_end = this._handleschedule_end.bind(this)
    }

    sidenavToggleHandler = () => {
        if (this.state.sidenav) {
            this.setState({
                ...this.state,
                sidenav: false
            });
        } else {
            this.setState({
                ...this.state,
                sidenav: true
            });
        }
    }

    async getSchedulesFunction() {
        if (this.props.ScheduleReducer.schedule_device) {
            let levID = this.props.ScheduleReducer.schedule_device.levelId.split('_')[0]
            let res = await functions.getSchedulesByLevel(levID)
            if (res != []) {
                res.map((key) => {
                    if (key.events[0].deviceId === this.props.ScheduleReducer.schedule_device._id && key.events[0].value === 1) {
                        this.setState({ schedule_start: key.startTime, schedule_end: key.endTime, schedule_start_id: key._id })
                    }
                    else if (key.events[0].deviceId === this.props.ScheduleReducer.schedule_device._id && key.events[0].value === 0) {
                        this.setState({ schedule_end_id: key._id })
                    }
                })
            }
            console.log(res)
        }
    }

    componentDidMount() {
        console.log(this.props.ScheduleReducer.schedule_device)
        this.getSchedulesFunction();
    }

    _handleschedule_start(event) {
        this.setState({ schedule_start: event.target.value })
        console.log(event.target.value)
    }

    _handleschedule_end(event) {
        this.setState({ schedule_end: event.target.value })
        console.log(event.target.value)
    }

    _handlealert_high(event) {
        this.setState({ alert_high: event.target.value })
        console.log(event.target.value)
    }

    _handlealert_low(event) {
        this.setState({ alert_low: event.target.value })
        console.log(event.target.value)
    }

    async _handleSave() {
        // console.log(this.state.alert_high);
        // console.log(this.state.alert_low)
        // if (this.state.alert_high_id != '' && this.state.alert_low_id != '') {
        //     if (this.state.alert_high === '' && this.state.alert_low === '') {
        //         let res = await functions.deleteAlert(this.state.alert_high_id)
        //         let res1 = await functions.deleteAlert(this.state.alert_low_id)
        //         this.setState({ alert_high_id: '', alert_low_id: '' })
        //     }
        //     else {
        //         let res = await functions.putAlert(this.state.alert_high_id, this.state.alert_high)
        //         let res1 = await functions.putAlert(this.state.alert_low_id, this.state.alert_low)
        //     }
        // }
        // else {
        //     if (this.state.alert_high != '' && this.state.alert_low != '') {
        //         let res = await functions.postAlert(this.props.WaterReducer.water_device._id, this.state.alert_high, this.state.alert_low)
        //         this.getAlertsFunction()
        //     }
        // }

        if (this.state.schedule_start_id != '' && this.state.schedule_end_id != '') {
            if (this.state.schedule_start === '' && this.state.schedule_end === '') {
                let res = await functions.deleteSchedule(this.state.schedule_start_id)
                let res1 = await functions.deleteSchedule(this.state.schedule_end_id)
                this.setState({ schedule_start_id: '', schedule_end_id: '' })
            }
            else {
                let res = await functions.putSchedule(this.state.schedule_start_id, this.state.schedule_start, this.state.schedule_end)
                let res1 = await functions.putSchedule(this.state.schedule_end_id, this.state.schedule_end, this.state.schedule_start)
            }
        }
        else {
            if (this.state.schedule_start != '' && this.state.schedule_end != '') {
                let res = await functions.postSchedule(this.props.ScheduleReducer.schedule_device._id, this.state.schedule_start, this.state.schedule_end, "Hub_" + this.props.ScheduleReducer.schedule_device.type + "_Status")
                this.getSchedulesFunction()
            }
        }
    }

    render() {

        if (this.props.ScheduleReducer.schedule_device === undefined) {
            this.props.history.push(`/levels/${this.props.match.params.levelId}`);
        }

        return (
            <div>
                <Header history={this.props.history} toggle={() => this.sidenavToggleHandler()} name={"Schedules"}  ></Header>
                <Sidenav width={this.state.sidenav ? '75%' : '0px'} history={this.props.history}></Sidenav>

                {(this.props.ScheduleReducer.schedule_device) ?
                    <div className="col-12 animated fadeIn" style={{ marginTop: '20px' }} >
                        <div style={{ marginTop: '70px', width: '100%', padding: '10px' }} className="component-container-box">
                            <div style={{ marginBottom: '30px', position: 'absolute', width: '85%' }}>
                                <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>{this.props.ScheduleReducer.schedule_device.type} On Time</h6>
                                <input style={{ border: '0px', float: 'right', display: 'block' }} type="time" name="usr_time" onChange={this._handleschedule_start} value={this.state.schedule_start} />
                            </div>
                            <div style={{ marginBottom: '30px', marginTop: '50px', position: 'absolute', width: '85%' }}>
                                <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>{this.props.ScheduleReducer.schedule_device.type} Off Time</h6>
                                <input style={{ border: '0px', float: 'right', display: 'block' }} type="time" name="usr_time" onChange={this._handleschedule_end} value={this.state.schedule_end} />
                            </div>
                            {/* <div style={{ marginBottom: '30px', marginTop: '100px', position: 'absolute', width: '85%' }}>
                            <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>Motor On Water Level</h6>
                            <span style={{ float: 'right' }}>%</span>
                            <input style={{ border: '0px', float: 'right', display: 'block', width: '75px' }} type="number" name="quantity" min="0" max="100" onChange={this._handlealert_low} value={this.state.alert_low} />
                        </div>
                        <div style={{ marginBottom: '30px', marginTop: '150px', position: 'absolute', width: '85%' }}>
                            <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>Motor Off Water Level</h6>
                            <span style={{ float: 'right' }}>%</span>
                            <input style={{ border: '0px', float: 'right', display: 'block', width: '75px' }} type="number" name="quantity" min="0" max="100" onChange={this._handlealert_high} value={this.state.alert_high} />
                        </div> */}
                            <button style={{ marginTop: '100px', width: "100%", backgroundColor: "#1286A8", color: "white", borderColor: "#1286A8" }} onClick={this._handleSave}>Save</button>
                        </div>
                    </div>
                    :
                    null}

                <Footer></Footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ScheduleReducer: state.ScheduleReducer
    }
}

export default connect(mapStateToProps, null)(withRouter(HomeSchedule));