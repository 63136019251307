const INITIAL_STATE = {
    authUser: {},
    isAuthenticated: false,
    isLoginProcessing: false,
    isSignupProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {}
}
const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "REGISTER":
            return { ...state, isSignupProcessing: true, isRegistered: false, isError: false };

        case "REGISTER_SUCCESS":
            return { ...state, isRegistered: true, isSignupProcessing: false, isAuthenticated: true, authUser: action.payload,errorMessage: {}  };

        case "REGISTER_FAIL":
            return { ...state, errorMessage: action.payload, isSignupProcessing: false, isError: true };

        case "LOGIN":
            return { ...state, isLoginProcessing: true, isAuthenticated: false, isError: false };

        case "LOGIN_SUCCESS":
            return { ...state, isAuthenticated: true, authUser: action.payload, isLoginProcessing: false, isRegistered: false, errorMessage: {} };

        case "LOGIN_FAIL":
            return { ...state, errorMessage: action.payload, isLoginProcessing: false, isError: true, isAuthenticated: false,authUser: {}};

            case "CLEAR_USER":
           
            return { ...state,  authUser: {},
            isAuthenticated: false,
            isLoginProcessing: false,
            isSignupProcessing: false,
            isRegistered: false,
            isError: false,
            errorMessage: {}};
      
      
            
            default:
            return state
    }
}
export default UserReducer