const INITIAL_STATE = {
    event:{
        hubId: "",
        eventId: "",
        deviceID: "",
        levelId: "",
        value: "",
        type: "",
        created: "",
    }
   
   
}
const MqttReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
     


        case "ADD_MQTT_EVENT":
            return { ...state, event:action.payload};
        
        
          
      
           
            default:
            return state
    }
}
export default MqttReducer