import axios from '../utilities/axios';
import cookies from '../utilities/cookies';

export let signIn = async (credentials, user) => {
    console.log(credentials)
    try {
        let response = await axios.post('/users/signin', credentials);
        console.log(response);
        cookies.setCookie('jwt', response.data.token, response.data.expiresIn);
        cookies.setCookie('user', credentials._id, response.data.expiresIn);
        return response.data;
    } catch (err) {
        if (err.response) {
            err.response.data.error = true;
            return err.response.data;
        }
        else {
            let error = {
                response: {
                    data: {
                        error: true,
                        statusCode: 503,
                        message: "Network Error"
                    }
                }
            };
            return error.response.data

        }

    }
}

export let checkSignIn = () => {
    return cookies.getCookie('jwt') ? true : false;
}

export let signOut = () => {
    cookies.deleteCookie('jwt');
    cookies.deleteCookie('user');
}

export let getSignedInUser = async () => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/users/me');
        console.log(response)
        return response.data;
    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}