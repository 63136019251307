import React, { Component } from 'react';
import { connect } from 'react-redux';
// import * as config from '../../../config';
// import * as publish from '../../../getAlerts';
// import * as configration from '../../../config/config';
import * as functions from '../../functions/functions';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Bar, Line } from 'react-chartjs-2';
import $ from 'jquery';
import LoaderGif from '../../assets/img/loader/loader.gif';
import { getFlowByDevice } from '../../shared/services/events'


import {
    Badge,
    Button,
    ButtonDropdown,
    ButtonGroup,
    ButtonToolbar,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardTitle,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Progress,
    Row,
    Table,
} from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';

class FlowGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unmount: false,
            width: 0,
            limit: 10,
            rand: 0.0,
            radioSelected: 2,
            renderVar: 20,
            overflow: 'scroll',
            graphDate: null,
            isLoader: false,
            volume: 0.0,
            consumption: 0.0,
            mainChartOpts:
            {

                tooltips: {
                    enabled: false,
                    custom: CustomTooltips,
                    intersect: true,
                    mode: 'index',
                    position: 'nearest',
                    callbacks: {
                        labelColor: function (tooltipItem, chart) {
                            return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                        }
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: "bottom"
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                fontSize: 10,
                                maxTicksLimit: 5,
                            },
                            gridLines: {
                                display: false,
                            },
                            /*  type: 'time',
                             distribution: 'linear', */
                            time: {
                                /*   displayFormats: {
                                    minute: 'h:mm:ss a[\n]ll',
                                    millisecond: 'h:mm:ss a[\n]ll',
                                    second: 'h:mm:ss a[\n]ll',
                                    hour: 'h:mm:ss a[\n]ll',
                                    day: 'h:mm:ss a ll',
                                    week: 'h:mm:ss a ll',
                                    month: 'h:mm:ss a ll',
                                    quater: 'h:mm:ss a ll',
                                    year: 'h:mm:ss a [\n] ll'
                                   
                                } */
                            }
                        }],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                /* stepSize: Math.ceil(250 / 5),
                                max: 250, */
                            },
                            gridLines: {
                                display: false,
                            }
                        }],
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            },
            mainChart: {
                labels: [],
                datasets: [
                    {
                        label: 'Intake',
                        fill: false,
                        borderColor: '#20C8DB',
                        pointHoverBackgroundColor: '#fff',
                        borderWidth: 2,
                        data: [],
                    }
                ],
            },
        }

        this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
        this.todayDate = this.todayDate.bind(this);
        this.getWaterConsumptionGraph = this.getWaterConsumptionGraph.bind(this);
    }

    async componentDidMount() {
        console.log(this.props.device)
        $(window).resize(() => {
            if (this.state.radioSelected != 1) {
                this.state.unmount = true;
                var len = this.state.mainChart.datasets[0].data.length
                if (len == 0) {
                    this.state.limit = 100;
                    this.state.renderVar = 20;
                }
                else {
                    var graphWidth = $("#graph-" + this.props.device._id).width();

                    var approxWidth = 20 * len
                    if (approxWidth <= graphWidth) {
                        var renderVar = graphWidth / len;
                        this.state.renderVar = renderVar;
                    }
                    else {
                        this.state.renderVar = 20;
                    }
                    this.state.limit = len;
                }
                this.forceUpdate();

                this.state.unmount = false;
                this.forceUpdate();

            }
        });
        setTimeout(() => {
            //   this.getWaterConsumptionGraph("recent");
            this.onRadioBtnClick(2)
        }, 30);


        this.state.startDate = this.todayDate('start');
        this.state.endDate = this.todayDate('end')




    }
    todayDate(type) {

        if (type === "start") {
            var currentdate = new Date();
            currentdate.setDate(currentdate.getDate() - 1);
            var currentYear = currentdate.getFullYear();
            var currentMonth = (currentdate.getMonth() + 1);
            if (currentMonth <= 9) {
                currentMonth = "0" + currentMonth;
            }
            var currentDate = currentdate.getDate();
            if (currentDate <= 9) {
                currentDate = "0" + currentDate;
            }
            var datetime = "" + currentYear + "-"
                + currentMonth + "-"
                + currentDate;



            return datetime

        }
        else {
            var currentdate = new Date();
            var currentYear = currentdate.getFullYear();
            var currentMonth = (currentdate.getMonth() + 1);
            if (currentMonth <= 9) {
                currentMonth = "0" + currentMonth;
            }
            var currentDate = currentdate.getDate();
            if (currentDate <= 9) {
                currentDate = "0" + currentDate;
            }
            var datetime = "" + currentYear + "-"
                + currentMonth + "-"
                + currentDate;


            return datetime

        }

    }
    onRadioBtnClick(value) {
        console.log(value)
        this.setState({
            graphDate: null
        })
        this.setState({
            radioSelected: value
        })
        if (value == 1) {
            this.state.limit = 10;
            this.state.renderVar = 20;
            this.getWaterConsumptionGraph('recent');
            this.state.mainChartOpts.responsive = true;

            this.state.mainChartOpts.tooltips = {
                enabled: false,
                custom: CustomTooltips,
                intersect: true,
                mode: 'index',
                position: 'nearest',
                callbacks: {
                    labelColor: function (tooltipItem, chart) {
                        return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                    }
                }
            };
            this.state.shouldRedraw = true;
            this.forceUpdate();
            setTimeout(() => {
                this.state.shouldRedraw = false;
            }, 1000);

        }
        else {
            this.state.limit = 10;
            this.state.renderVar = 20;
            this.state.unmount = true;
            this.forceUpdate();
            this.state.unmount = false;
            // defaults.global.responsive = false;
            // this.state.shouldRedraw = true;
            this.state.mainChartOpts.responsive = false;

            this.state.mainChartOpts.tooltips = {};
            this.forceUpdate();

            /*    setTimeout(() => {
                 this.state.shouldRedraw = false;
               }, 1000); */

            if (value == 2) {
                this.state.graphDate = this.todayDate('end')
                this.getWaterConsumptionGraph('hour')

            }
            else if (value == 3) {
                this.state.graphDate = this.todayDate('end')
                this.getWaterConsumptionGraph('day')

            }
            else if (value == 4) {
                this.state.graphDate = this.todayDate('end')
                this.getWaterConsumptionGraph('month')

            }
        }
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter') {

            if (this.state.radioSelected === 2) {
                this.getWaterConsumptionGraph('hour')

            }
            else if (this.state.radioSelected === 3) {
                this.getWaterConsumptionGraph('day')

            }
            else if (this.state.radioSelected === 4) {
                this.getWaterConsumptionGraph('month')

            }
        }
    }

    graphDateHandler(e) {
        this.setState({
            graphDate: e.target.value
        })
        setTimeout(() => {
            if (this.state.radioSelected === 2) {
                this.getWaterConsumptionGraph('hour')

            }
            else if (this.state.radioSelected === 3) {
                this.getWaterConsumptionGraph('day')

            }
            else if (this.state.radioSelected === 4) {
                this.getWaterConsumptionGraph('month')

            }

        }, 200);
    }

    async getWaterConsumptionGraph(type) {
        console.log('get consumption')

        if (type === 'hour') {


            if (this.state.graphDate !== null) {
              var newdt = this.state.graphDate;
              /*   var dateSplit = this.state.graphDate.split("-");
               
                var newdt = new Date (dateSplit[0],dateSplit[1],dateSplit[2]);
                var enddt = new Date(dateSplit[0],dateSplit[1],dateSplit[2]);
                enddt.setDate(enddt.getDate()+1);
                var startDateInt = functions.convertToInt(newdt);
                var endDateInt = functions.convertToInt(enddt); */
              var startDateInt = functions.convertToInt(newdt);
      
              this.setState({
                isLoader: true
              })
      
              let data = await getFlowByDevice(this.props.device._id, 'hourly');
              /*   let data=await getConsumptionByDevice(deviceId); */
      
              if (!data.error) {
                this.setState({
                  isLoader: false
                })
      
                var len = data.length;
                var i;
                var newVolume = [];
                var newLabel = [];
                for (i = 0; i < len; i++) {
                  newVolume.push(data[i].intake);
                  // var arr = functions.convertToDate(data[i].created,'object')
                  // newLabel.push(new Date(arr[0],arr[1]-1,arr[2],arr[3],arr[4],arr[5]));
                  /*   newLabel.push(functions.convertToDate(data[i].created,"live")); */
                  var arr = [];
                  arr.push(functions.convertToDate(data[i].created, "live"));
                  arr.push(functions.convertToDate(data[i].created, 'date'));
                  newLabel.push(arr);
      
                }
                newVolume.reverse();
                newLabel.reverse();
                var prevData = this.state.mainChart;
                prevData.datasets[0].data = newVolume;
      
                prevData.labels = newLabel;
      
                this.setState({
                  mainChart: prevData
                });
                this.setState({
                  rand: Math.random()
                })
      
      
                this.state.unmount = true;
                if (len == 0) {
                  this.state.limit = 100;
                  this.state.renderVar = 20;
                  this.state.overflow = 'hidden';
      
                }
                else {
                  var graphWidth = $("#graph-" + this.props.device._id).width();
      
                  var approxWidth = 20 * len
                  if (approxWidth <= graphWidth) {
                    var renderVar = graphWidth / len;
                    this.state.renderVar = renderVar;
                    this.state.overflow = 'hidden'
                  }
                  else {
                    this.state.overflow = 'scroll'
                    this.state.renderVar = 20;
                  }
                  this.state.limit = len;
                }
      
                this.forceUpdate();
      
                this.state.unmount = false;
                this.forceUpdate();
      
              }
              else {
      
                this.setState({
                  isLoader: false
      
                })
              }
            }
          }

        if (type === 'day') {


            if (this.state.graphDate !== null) {
              var newdt = this.state.graphDate;
              /*   var dateSplit = this.state.graphDate.split("-");
               
                var newdt = new Date (dateSplit[0],dateSplit[1],dateSplit[2]);
                var enddt = new Date(dateSplit[0],dateSplit[1],dateSplit[2]);
                enddt.setDate(enddt.getDate()+1);
                var startDateInt = functions.convertToInt(newdt);
                var endDateInt = functions.convertToInt(enddt); */
              var startDateInt = functions.convertToInt(newdt);
      
              this.setState({
                isLoader: true
              })
      
              let data = await getFlowByDevice(this.props.device._id, 'daily');
              /*   let data=await getConsumptionByDevice(deviceId); */
      
              if (!data.error) {
                this.setState({
                  isLoader: false
                })
      
                var len = data.length;
                var i;
                var newVolume = [];
                var newLabel = [];
                for (i = 0; i < len; i++) {
                  newVolume.push(data[i].intake);
                  // var arr = functions.convertToDate(data[i].created,'object')
                  // newLabel.push(new Date(arr[0],arr[1]-1,arr[2],arr[3],arr[4],arr[5]));
                  /*   newLabel.push(functions.convertToDate(data[i].created,"live")); */
                  var arr = [];
                  // arr.push(functions.convertToDate(data[i].created, "live"));
                  arr.push(functions.convertToDate(data[i].created, 'date'));
                  newLabel.push(arr);
      
                }
                newVolume.reverse();
                newLabel.reverse();
                var prevData = this.state.mainChart;
                prevData.datasets[0].data = newVolume;
      
                prevData.labels = newLabel;
      
                this.setState({
                  mainChart: prevData
                });
                this.setState({
                  rand: Math.random()
                })
      
      
                this.state.unmount = true;
                if (len == 0) {
                  this.state.limit = 100;
                  this.state.renderVar = 20;
                  this.state.overflow = 'hidden';
      
                }
                else {
                  var graphWidth = $("#graph-" + this.props.device._id).width();
      
                  var approxWidth = 20 * len
                  if (approxWidth <= graphWidth) {
                    var renderVar = graphWidth / len;
                    this.state.renderVar = renderVar;
                    this.state.overflow = 'hidden'
                  }
                  else {
                    this.state.overflow = 'scroll'
                    this.state.renderVar = 20;
                  }
                  this.state.limit = len;
                }
      
                this.forceUpdate();
      
                this.state.unmount = false;
                this.forceUpdate();
      
              }
              else {
      
                this.setState({
                  isLoader: false
      
                })
              }
            }
          }
      
          if (type === 'month') {
      
      
            if (this.state.graphDate !== null) {
              var newdt = this.state.graphDate;
              /*   var dateSplit = this.state.graphDate.split("-");
               
                var newdt = new Date (dateSplit[0],dateSplit[1],dateSplit[2]);
                var enddt = new Date(dateSplit[0],dateSplit[1],dateSplit[2]);
                enddt.setDate(enddt.getDate()+1);
                var startDateInt = functions.convertToInt(newdt);
                var endDateInt = functions.convertToInt(enddt); */
              var startDateInt = functions.convertToInt(newdt);
      
      
              this.setState({
                isLoader: true
              })
      
              let data = await getFlowByDevice(this.props.device._id, 'monthly');
              if (!data.error) {
      
                this.setState({
                  isLoader: false
                })
      
                var len = data.length;
                var i;
                var newVolume = [];
                var newLabel = [];
                for (i = 0; i < len; i++) {
                  newVolume.push(data[i].intake);
                  //   var arr = functions.convertToDate(data[i].created,'object')
                  //   newLabel.push(new Date(arr[0],arr[1]-1,arr[2],arr[3],arr[4],arr[5]));
                  //   newLabel.push(functions.convertToDate(data[i].created,type));
                  var arr = [];
      
                  arr.push(functions.convertToDate(data[i].created, 'date'));
                  newLabel.push(arr);
      
                }
                newVolume.reverse();
                newLabel.reverse();
                var prevData = this.state.mainChart;
                prevData.datasets[0].data = newVolume;
      
                prevData.labels = newLabel;
      
                this.setState({
                  mainChart: prevData
                })
      
      
      
                this.state.unmount = true;
                if (len == 0) {
                  this.state.limit = 100;
                  this.state.renderVar = 20;
                  this.state.overflow = 'hidden';
      
                }
                else {
                  var graphWidth = $("#graph-" + this.props.device._id).width();
      
                  var approxWidth = 20 * len
                  if (approxWidth <= graphWidth) {
                    var renderVar = graphWidth / len;
                    this.state.renderVar = renderVar;
                    this.state.overflow = 'hidden'
                  }
                  else {
                    this.state.overflow = 'scroll'
                    this.state.renderVar = 20;
                  }
                  this.state.limit = len;
                }
      
                this.forceUpdate();
      
                this.state.unmount = false;
                this.forceUpdate();
      
              }
              else {
      
                this.setState({
                  isLoader: false
                })
              }
      
            }
          }
      
      
        //   if (type === 'year') {
      
      
        //     if (this.state.graphDate !== null) {
        //       var newdt = this.state.graphDate;
        //       /*   var dateSplit = this.state.graphDate.split("-");
               
        //         var newdt = new Date (dateSplit[0],dateSplit[1],dateSplit[2]);
        //         var enddt = new Date(dateSplit[0],dateSplit[1],dateSplit[2]);
        //         enddt.setDate(enddt.getDate()+1);
        //         var startDateInt = functions.convertToInt(newdt);
        //         var endDateInt = functions.convertToInt(enddt); */
        //       var startDateInt = functions.convertToInt(newdt);
      
      
        //       this.setState({
        //         isLoader: true
        //       })
        //       let data = await getConsumptionByDeviceAndDate(this.props.device._id, 'monthly', startDateInt);
        //       if (!data.error) {
        //         this.setState({
        //           isLoader: false
        //         })
      
        //         var len = data.length;
        //         var i;
        //         var newVolume = [];
        //         var newConsumption = [];
        //         var newLabel = [];
        //         for (i = 0; i < len; i++) {
        //           newVolume.push(data[i].volume);
        //           /*  var arr = functions.convertToDate(data[i].created,'object')
        //            newLabel.push(new Date(arr[0],arr[1]-1,arr[2],arr[3],arr[4],arr[5])); */
        //           //   newLabel.push(functions.convertToDate(data[i].created,type));
        //           var arr = [];
      
        //           arr.push(functions.convertToDate(data[i].created, 'date'));
        //           newLabel.push(arr);
        //           newConsumption.push(data[i].consumption);
      
        //         }
      
        //         newVolume.reverse();
        //         newConsumption.reverse();
        //         newLabel.reverse();
        //         var prevData = this.state.mainChart;
        //         prevData.datasets[0].data = newVolume;
        //         prevData.datasets[1].data = newConsumption;
      
        //         prevData.labels = newLabel;
      
        //         this.setState({
        //           mainChart: prevData
        //         })
      
      
      
        //         this.state.unmount = true;
        //         if (len == 0) {
        //           this.state.limit = 100;
        //           this.state.renderVar = 20;
        //           this.state.overflow = 'hidden';
      
        //         }
        //         else {
        //           var graphWidth = $("#graph-" + this.props.device._id).width();
      
        //           var approxWidth = 20 * len
        //           if (approxWidth <= graphWidth) {
        //             var renderVar = graphWidth / len;
        //             this.state.renderVar = renderVar;
        //             this.state.overflow = 'hidden'
        //           }
        //           else {
        //             this.state.overflow = 'scroll'
        //             this.state.renderVar = 20;
        //           }
        //           this.state.limit = len;
        //         }
      
        //         this.forceUpdate();
      
        //         this.state.unmount = false;
        //         this.forceUpdate();
      
        //       }
        //       else {
      
      
      
        //         this.setState({
        //           isLoader: false
        //         })
      
        //       }
        //     }
        //   }
          else if (type == 'recent') {
      
            this.setState({
              radioSelected: 1
            })
            let data = await getFlowByDevice(this.props.device._id,'hourly');
      
            if (!data.error) {
      
      
              var len = data.length;
              var i;
              var newVolume = [];
              var newLabel = [];
              console.log(data)
              for (i = 0; i < len; i++) {
                newVolume.push(data[i].intake);
      
                var arr = [];
                arr.push(functions.convertToDate(data[i].created, "live"));
                arr.push(functions.convertToDate(data[i].created, 'date'));
                newLabel.push(arr);
                /*    var arr = functions.convertToDate(data[i].created,'object')
                   newLabel.push(new Date(arr[0],arr[1]-1,arr[2],arr[3],arr[4],arr[5])); */
      
              }
      
              newVolume.reverse();
              newLabel.reverse();
      
              var prevData = this.state.mainChart;
              prevData.datasets[0].data = newVolume;
      
              prevData.labels = newLabel;
      
              this.setState({
                mainChart: prevData,
                volume: newVolume[newVolume.length - 1]
              })
      
            }
            else {
              this.setState({
                isLoader: false
              })
            }
      
      
        }
    }

    render() {
        return (
            <div className="col-12 animated fadeIn" style={{ marginTop: '20px' }} >
                <div style={{ width: '100%' }} className="component-container-box">
                    <div style={{ padding: '5px' }}>
                        <h6 style={{ textAlign: 'left', marginBottom: '0px', fontSize: '14px' }}>Water Volume (litres)</h6>
                    </div>
                    <div style={{ padding: '1%' }}>
                    <Row>
                        <Col sm="12" md="12" lg="12" xs="12" xl="12" >
                            <Row>

                                {
                                    (this.state.radioSelected === 1) ?

                                        <Col sm="12" md="12" lg="12" xs="12" xl="12" style={{ marginBottom: 10 }}>
                                            <Row>
                                                <Col sm="12" md="5" lg="5" xs="12" style={{ marginBottom: 10 }}>

                                                    {/* <Input type="date" name="date" disabled id="exampleDate" placeholder="date placeholder" className="mr-2 margin-top-1" onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} /> */}
                                                </Col>
                                                {/* < div className=" col-sm-12 col-sx-12 col-md-2 col-lg-2  margin-top-2" >
              <Button color="primary" disabled onClick={()=>this.getWaterConsumptionGraph('day')}>Search </Button>
              </div> */}
                                                <Col sm="12" md="7" lg="7" xs="12">
                                                    <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block" }} className="margin-top-1">
                                                        <ButtonGroup className="float-right mr-3" aria-label="First group" size="sm">
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Hour</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(3)} active={this.state.radioSelected === 3}>Day</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(4)} active={this.state.radioSelected === 4}>Month</Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </Col>
                                            </Row>



                                        </Col >



                                        :
                                        null
                                }

                                {
                                    (this.state.radioSelected === 2) ?
                                        <Col sm="12" md="12" lg="12" xs="12" xl="12" style={{ marginBottom: 10 }}>
                                            <Row>
                                                <Col sm="12" md="5" lg="5" xs="12" style={{ marginBottom: 10 }}>
                                                    {/* <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" className="mr-2 margin-top-1" onKeyPress={this.onKeyPress} defaultValue={this.todayDate('end')} onChange={this.graphDateHandler.bind(this)} /> */}
                                                </Col>
                                                {/*  < div className=" col-sm-12 col-sx-12 col-md-2 col-lg-2  margin-top-2" >
              <Button color="primary" onClick={()=>this.getWaterConsumptionGraph('day')}>Search </Button>
              </div> */}
                                                <Col sm="12" md="7" lg="7" xs="12">
                                                    <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block" }} className="margin-top-1">
                                                        <ButtonGroup className="float-right mr-3" aria-label="First group" size="sm">
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Hour</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(3)} active={this.state.radioSelected === 3}>Day</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(4)} active={this.state.radioSelected === 4}>Month</Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </Col>
                                            </Row>



                                        </Col >


                                        :
                                        null
                                }

                                {
                                    (this.state.radioSelected === 3) ?
                                        <Col sm="12" md="12" lg="12" xs="12" xl="12" style={{ marginBottom: 10 }}>
                                            <Row>
                                                <Col sm="12" md="5" lg="5" xs="12" style={{ marginBottom: 10 }}>
                                                    {/* <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" className="mr-2 margin-top-1" defaultValue={this.todayDate('end')} onKeyPress={this.onKeyPress} onChange={this.graphDateHandler.bind(this)} /> */}
                                                </Col>
                                                {/*  < div className=" col-sm-12 col-sx-12 col-md-2 col-lg-2  margin-top-2" >
              <Button color="primary" onClick={()=>this.getWaterConsumptionGraph('month')}>Search </Button>
              </div> */}
                                                <Col sm="12" md="7" lg="7" xs="12">
                                                    <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block" }} className="margin-top-1">
                                                        <ButtonGroup className="float-right mr-3" aria-label="First group" size="sm">
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Hour</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(3)} active={this.state.radioSelected === 3}>Day</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(4)} active={this.state.radioSelected === 4}>Month</Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </Col>
                                            </Row>



                                        </Col >
                                        :
                                        null
                                }


                                {
                                    (this.state.radioSelected === 4) ?

                                        <Col sm="12" md="12" lg="12" xs="12" xl="12" style={{ marginBottom: 10 }}>
                                            <Row>
                                                <Col sm="12" md="5" lg="5" xs="12" style={{ marginBottom: 10 }}>
                                                    {/* <Input type="date" name="date" id="exampleDate" placeholder="date placeholder" className="mr-2 margin-top-1" onKeyPress={this.onKeyPress} defaultValue={this.todayDate('end')} onChange={this.graphDateHandler.bind(this)} /> */}
                                                </Col>
                                                {/*   < div className=" col-sm-12 col-sx-12 col-md-2 col-lg-2  margin-top-2" >
              <Button color="primary" onClick={()=>this.getWaterConsumptionGraph('year')}>Search </Button>
              </div> */}
                                                <Col sm="12" md="7" lg="7" xs="12">
                                                    <ButtonToolbar aria-label="Toolbar with button groups" style={{ display: "block" }} className="margin-top-1">
                                                        <ButtonGroup className="float-right mr-3" aria-label="First group" size="sm">
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Hour</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(3)} active={this.state.radioSelected === 3}>Day</Button>
                                                            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(4)} active={this.state.radioSelected === 4}>Month</Button>
                                                        </ButtonGroup>
                                                    </ButtonToolbar>
                                                </Col>
                                            </Row>
                                        </Col>
                                        :
                                        null
                                }

                                {/*  <Col sm="12" md="12" lg="12" xs="12">
        <ButtonToolbar  aria-label="Toolbar with button groups" style={{display:"block"}}>
            <ButtonGroup className="float-right mr-3" aria-label="First group">
            <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(1)} active={this.state.radioSelected === 1}>Recent</Button>
              <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(2)} active={this.state.radioSelected === 2}>Day</Button>
              <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(3)} active={this.state.radioSelected === 3}>Month</Button>
              <Button color="outline-secondary" onClick={() => this.onRadioBtnClick(4)} active={this.state.radioSelected === 4}>Year</Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col> */}
                            </Row>

                        </Col>
                    </Row>
                    <div id={"graph-" + this.props.device._id} className="chart-wrapper" style={(this.state.radioSelected == 1) ? {
                        height: 300 + 'px',
                        marginTop: 40 + 'px'
                    } : { overflowX: this.state.overflow, marginTop: 40 + 'px' }} rand={this.state.rand}>
                        {(this.state.unmount) ?
                            null :
                              <Bar width={this.state.renderVar * this.state.limit} redraw={this.state.shouldRedraw}
                              height={250}
                              options={{
                                  maintainAspectRatio: true
                              }} data={this.state.mainChart} options={this.state.mainChartOpts} rand={this.state.rand} />
                        }

                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FlowGraph;