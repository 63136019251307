import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './Layout.css';
import { checkSignIn } from '../../shared/services/users';
import { getdata, disconnectMqtt } from '../../mqtt/mqtt';

import { read_single, read_all } from '../../redux/actions/idbActions';
import { write_data, remove_single, remove_all, remove_db } from '../../functions/idb_functions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Import Routes
import INDEX from './indexre'
import Signin from '../Signin/Signin';
import AddDevice from '../AddDevice/add-device';
import Home from '../Home/Home';
import Levels from '../Levels/levels';
import WaterGraphs from '../Graphs/waterGraphs';
import WaterSchedule from '../Schedules/waterSchedule';
import HomeSchedule from '../Schedules/homeSchedule';

class Layout extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        if (checkSignIn()) {
            getdata();
        }

        // write_data('database', 'signin', 'key001', {});
        // write_data('database', 'organizations', 'levels', {});
        // remove_single('database', 'store', 'key002');
        // this.props.read_all('database', 'store');
        // remove('database', 'store');
        // remove_db('database');

    }
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path="/index.html" exact component={INDEX} />
                    <Route path="/home.html" exact component={INDEX} />
                    <Route path="/" exact component={Signin} />
                    <Route path="/add" exact component={AddDevice} />
                    <Route path="/levels" exact component={Home} />
                    <Route path="/levels/:levelId" exact component={Levels} />
                    <Route path="/levels/:levelId/home_schedule" exact component={HomeSchedule} />
                    <Route path="/levels/:levelId/water" exact component={WaterGraphs} />
                    <Route path="/levels/:levelId/water/schedule" exact component={WaterSchedule} />
                </Switch>
            </BrowserRouter>
        )
    }
}

function mapStateToProps(state) {
    return {
        idb_state: state.idb_state,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ read_single, read_all }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);