import moment from 'moment';

export function getUnit(deviceId) {
    if (deviceId === 'CO' || deviceId === 'TVOC' || deviceId === 'LPG' || deviceId === 'CH4' || deviceId === 'Humidity') {
        return '%'
    }
    else if (deviceId === 'Temperature') {
        return '°C'
    }
    else if (deviceId === 'Pressure') {
        return 'hPa'
    }
    else if (deviceId === 'Dust') {
        return 'µg/m3'
    }
    else if (deviceId === 'Altitude') {
        return 'Meters'
    }
    else if (deviceId === 'AQI') {
        return 'INDEX'
    }

}

export function getDate(str) {
    let date = str.split('T')[0];
    let splitDate = date.split('-');
    return splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0]
}
export function getTime(str) {
    let time = str.split('T')[1];
    let timeFiltered = time.split('.')[0]
    let splitTime = timeFiltered.split(':');
    return formatAMPM(splitTime[0], splitTime[1], splitTime[2])
}
function formatAMPM(hours, minutes, secs) {
    var hours = hours
    var minutes = minutes
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    if (minutes == 0) {
        minutes = "00"
    }
    else {
        minutes = minutes
    }
    //  minutes = minutes < 10 ? ''+minutes : minutes;
    var strTime = hours + ':' + minutes + ':' + secs + " " + ampm;
    return strTime;
}

export function convertToInt(date, start = null, end = null) {
    let dt = date

    dt = dt.replace('T', ' ');
    if (dt.length === 16) {
        dt += ':00.000';
    }
    else if (dt.length === 19) {
        dt += '.000';
    }
    else if (dt.length === 10) {
        dt += ' 00:00:00.000';
    }

    return dt
}


export function convertToDate(str, type) {
    let date = str.split('T')[0];
    let splitDate = date.split('-');
    let time = str.split('T')[1];
    let timeFiltered = time.split('.')[0]
    let splitTime = timeFiltered.split(':');

    let day = splitDate[2]
    let month = splitDate[1]
    let year = splitDate[0]
    let hours = splitTime[0]
    let mins = splitTime[1]
    let secs = splitTime[2]
    if (type == 'day') {
        return hours;
    }
    else if (type == 'month') {
        return day;
    }

    else if (type == 'year') {
        return month;
    }

    else if (type == 'live') {
        return formatAMPM(hours, mins, secs);
    }
    else if (type == 'recent') {
        return hours;
    }

    else if (type == 'date') {
        return day + '-' + month + '-' + year;
    }

    else if (type == 'object') {
        return [year, month, day, hours, mins, secs];
    }

    return formatAMPM(hours, mins, secs) + ' ' + day + '-' + month + '-' + year;
    //return new Date(year, month, day, hours, mins, secs);
}

export function createDate() {
    let temp = moment().utc().add(5, 'hours')
    return temp
}