import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import { withRouter } from 'react-router-dom';
import AlertEvents from './alertEvents';

import { Card, Button, CardTitle, CardText, CardHeader, CardBody, Table, Row, Col, Collapse } from 'reactstrap';
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as functions from '../../shared/services/repository';
import dev from '../../shared/config/dev';


import store from '../../store'
import $ from 'jquery';

let updatedValue = null;
var preReqVar = null;
class WaterSchedule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            collapse: false,
            collapse1: false,
            collapsePre: false,
            collapsePre1: false,
            device: '',
            sidenav: false,
            alert_high: '',
            alert_high_events: [],
            alert_high_id: '',
            alert_high_Pre: '',
            alert_high_Prerequisites: [],
            alert_high_Pre_id: '',
            alert_low: '',
            alert_low_events: [],
            alert_low_id: '',
            alert_low_Pre: '',
            alert_low_Prerequisites: [],
            alert_low_Pre_id: '',
            schedule_start: '',
            schedule_start_id: '',
            schedule_end: '',
            schedule_end_id: '',

            prerequisites: [],
            value: 5,  // for give default value to pre requisites

            deviceName: '', // for showing device name on header 

            repeatOn: null,
            repeatOff: null,
            monOn: false,
            tueOn: false,
            wedOn: false,
            thuOn: false,
            friOn: false,
            satOn: false,
            sunOn: false,
            allOn: false,

            monOff: false,
            tueOff: false,
            wedOff: false,
            thuOff: false,
            friOff: false,
            satOff: false,
            sunOff: false,
            allOff: false,
            devices: [],
            WaterReducer_LevelId: []
        }

        this._handlealert_high = this._handlealert_high.bind(this)
        this._handlealert_low = this._handlealert_low.bind(this)

        this._handleSave = this._handleSave.bind(this)

        this.getAlertsFunction = this.getAlertsFunction.bind(this)
        this.getSchedulesFunction = this.getSchedulesFunction.bind(this)

        this.postAlertsFunction = this.postAlertsFunction.bind(this)
        this.postSchedulesFunction = this.postSchedulesFunction.bind(this)

        this._handleschedule_start = this._handleschedule_start.bind(this)
        this._handleschedule_end = this._handleschedule_end.bind(this)
    }
    updateNotify_Conditions = () => {
        if (this.state.schedule_start != '' && this.setRepeatOn().length === 0 && this.state.schedule_end === '') {
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Please confirm days</span>)
        }
        else if (this.state.schedule_start != '' && this.setRepeatOn().length > 0 && this.state.schedule_end === '') {
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Motor schedule updated! </span>)
        }
        else if (this.state.schedule_start === '' && this.state.schedule_end != '' && this.setRepeatOff().length === 0) {
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Please confirm days</span>)
            console.log('reapeat1', this.setRepeatOff().length)
        }
        else if (this.state.schedule_start != '' && this.state.schedule_end != '' && this.setRepeatOff().length === 0) {
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Please confirm days</span>)
            console.log('reapeat1', this.setRepeatOff().length)
        }
        else if (this.state.schedule_start != '' && this.state.schedule_end != '' && this.setRepeatOn().length === 0) {
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Please confirm days</span>)
            console.log('reapeat1', this.setRepeatOff().length)
        }
        else if (this.state.schedule_start === '' && this.state.schedule_end != '' && this.setRepeatOff().length > 0) {
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Motor schedule updated!</span>)
            console.log('reapeat2', this.setRepeatOff().length)
        }
        else if (this.state.schedule_start != '' && this.setRepeatOn().length === 0 && this.state.schedule_end != '' && this.setRepeatOff().length === 0) {
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Please confirm days</span>)
        }
        else if (parseInt(this.state.alert_low) > parseInt(this.state.alert_high) && this.state.alert_high.length != 0) {
            console.log("Low and high :",this.state.alert_low + this.state.alert_high)
            toast.warn(<span> <i className="fa fa-exclamation-circle"></i> Motor OFF Tank Level Should be Greater Than Motor ON Tank Level</span>)
        }
        else {
            this.updateNotify();
        }
    }

    updateNotify = () => {
        toast.success(<span><i className="fa fa-check-circle"></i> Settings updated!
        </span>, {
            position: "top-right",
            autoClose: 3000
        });
    }

    toggle = async () => {
        this.setState({ collapse: !this.state.collapse });
    }

    toggle1 = async () => {
        this.setState({ collapse1: !this.state.collapse1 });
    }
    togglePre = async () => {
        this.setState({ collapsePre: !this.state.collapsePre });
    }

    togglePre1 = async () => {
        this.setState({ collapsePre1: !this.state.collapsePre1 });
    }

    sidenavToggleHandler = () => {
        if (this.state.sidenav) {
            this.setState({
                ...this.state,
                sidenav: false
            });
        } else {
            this.setState({
                ...this.state,
                sidenav: true
            });
        }
    }

    async getAlertsFunction() {
        if (this.props.WaterReducer.water_device) {
            let res = await functions.getAlertsByDeviceId(this.props.WaterReducer.water_device._id)
            if (res != []) {
                res.map((key) => {
                    if (key.condition === 'HIGH' && key.type === 'Water_WaterTank_Status') {
                        this.setState({ alert_high: key.value, alert_high_events: key.events, alert_high_id: key._id })
                        this.setState({ alert_high_Pre: key.value, alert_high_Prerequisites: key.prerequisites, alert_high_Pre_id: key._id })
                    } else if (key.condition === 'LOW' && key.type === 'Water_WaterTank_Status') {
                        this.setState({ alert_low: key.value, alert_low_events: key.events, alert_low_id: key._id })
                        this.setState({ alert_low_Pre: key.value, alert_low_Prerequisites: key.prerequisites, alert_low_Pre_id: key._id })
                    }
                })
            }
            console.log("res", res)
        }
    }

    async getSchedulesFunction() {
        console.log(this.props.WaterReducer.water_device)
        if (this.props.WaterReducer.water_device) {
            let levID = this.props.WaterReducer.water_device.levelId.split('_')[0]
            let res = await functions.getSchedulesByLevel(levID)
            res = res.filter(e => e.events[0].deviceId === this.props.WaterReducer.water_device._id)
            console.log("getSchedulesByLevel", res)
            if (res.length == 1) {
                res.map((key) => {
                    if (key.events[0].deviceId === this.props.WaterReducer.water_device._id && key.events[0].value === 1) {
                        this.setState({ schedule_start: key.startTime, schedule_start_id: key._id, repeatOn: key.repeat })
                        if (this.state.repeatOn !== null) { // for Showing Repeated Days Motor On Schedule
                            if (this.state.repeatOn.length === 7) {
                                this.state.allOn = true;
                                this.state.monOn = true;
                                this.state.tueOn = true;
                                this.state.wedOn = true;
                                this.state.thuOn = true;
                                this.state.friOn = true;
                                this.state.satOn = true;
                                this.state.sunOn = true;

                            } else {
                                if (this.state.repeatOn.indexOf("Mon") != -1) {
                                    this.state.monOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Tue") != -1) {
                                    this.state.tueOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Wed") != -1) {
                                    this.state.wedOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Thu") != -1) {
                                    this.state.thuOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Fri") != -1) {
                                    this.state.friOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Sat") != -1) {
                                    this.state.satOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Sun") != -1) {
                                    this.state.sunOn = true;
                                }
                            }
                        }
                    }
                    else if (key.events[0].deviceId === this.props.WaterReducer.water_device._id && key.events[0].value === 0) {
                        this.setState({ schedule_end: key.startTime, schedule_end_id: key._id, repeatOff: key.repeat })
                        if (this.state.repeatOff !== null) { // for Showing Repeated Days  Motor Off Schedule
                            if (this.state.repeatOff.length === 7) {
                                this.state.allOff = true;
                                this.state.monOff = true;
                                this.state.tueOff = true;
                                this.state.wedOff = true;
                                this.state.thuOff = true;
                                this.state.friOff = true;
                                this.state.satOff = true;
                                this.state.sunOff = true;
                            } else {
                                if (this.state.repeatOff.indexOf("Mon") != -1) {
                                    this.state.monOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Tue") != -1) {
                                    this.state.tueOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Wed") != -1) {
                                    this.state.wedOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Thu") != -1) {
                                    this.state.thuOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Fri") != -1) {
                                    this.state.friOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Sat") != -1) {
                                    this.state.satOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Sun") != -1) {
                                    this.state.sunOff = true;
                                }
                            }
                        }
                    }
                })
            } else if (res != []) {
                res.map((key) => {
                    if (key.events[0].deviceId === this.props.WaterReducer.water_device._id && key.events[0].value === 1) {
                        this.setState({ schedule_start: key.startTime, schedule_end: key.endTime, schedule_start_id: key._id, repeatOn: key.repeat })
                        if (this.state.repeatOn !== null) { // for Showing Repeated Days Motor On Schedule
                            if (this.state.repeatOn.length === 7) {
                                this.state.allOn = true;
                                this.state.monOn = true;
                                this.state.tueOn = true;
                                this.state.wedOn = true;
                                this.state.thuOn = true;
                                this.state.friOn = true;
                                this.state.satOn = true;
                                this.state.sunOn = true;

                            } else {
                                if (this.state.repeatOn.indexOf("Mon") != -1) {
                                    this.state.monOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Tue") != -1) {
                                    this.state.tueOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Wed") != -1) {
                                    this.state.wedOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Thu") != -1) {
                                    this.state.thuOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Fri") != -1) {
                                    this.state.friOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Sat") != -1) {
                                    this.state.satOn = true;
                                }
                                if (this.state.repeatOn.indexOf("Sun") != -1) {
                                    this.state.sunOn = true;
                                }
                            }

                        }
                    }
                    else if (key.events[0].deviceId === this.props.WaterReducer.water_device._id && key.events[0].value === 0) {
                        this.setState({ schedule_end_id: key._id, repeatOff: key.repeat })
                        if (this.state.repeatOff !== null) { // for Showing Repeated Days  Motor Off Schedule
                            if (this.state.repeatOff.length === 7) {
                                this.state.allOff = true;
                                this.state.monOff = true;
                                this.state.tueOff = true;
                                this.state.wedOff = true;
                                this.state.thuOff = true;
                                this.state.friOff = true;
                                this.state.satOff = true;
                                this.state.sunOff = true;
                            } else {
                                if (this.state.repeatOff.indexOf("Mon") != -1) {
                                    this.state.monOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Tue") != -1) {
                                    this.state.tueOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Wed") != -1) {
                                    this.state.wedOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Thu") != -1) {
                                    this.state.thuOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Fri") != -1) {
                                    this.state.friOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Sat") != -1) {
                                    this.state.satOff = true;
                                }
                                if (this.state.repeatOff.indexOf("Sun") != -1) {
                                    this.state.sunOff = true;
                                }
                            }
                        }
                    }
                })
            }
            console.log(res)
        }
    }


    async componentDidMount() {
        this.setState({ loading: true })
        let temp = await this.getAlertsFunction();
        let temp1 = await this.getSchedulesFunction();

        if (this.props.WaterReducer.water_device) {
            if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                this.setState({ device: 'Motor' })
            } else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                this.setState({ device: 'Valve' })
            }
        }
        this.setState({ loading: false })

        //for getting device name
        if (this.props.WaterReducer.water_device) {
            let device = this.props.WaterReducer.water_device;
            let deviceName = device.name;
            this.setState({ deviceName: deviceName });
        }
        this.setState({ prerequisites: this.state.alert_low_Prerequisites })
        preReqVar = $('#preReqValue').val();
        if (preReqVar !== undefined) {
            console.log('preReqVarCheckIf', preReqVar)
            this.setState({ value: preReqVar })
        }else{
            console.log('preReqVarCheckElse', preReqVar)
        }
    }

    componentWillMount() {
        // for getting water devices   
        if (this.props.WaterReducer.water_device) {
            let deviceee = this.props.WaterReducer.water_device.levelId;
            store.getState().OrganizationReducer.organizationsDetails.forEach(e => {
                e.devices.forEach(i => {
                    if (i.levelId === deviceee) {
                        this.state.devices.push(i);
                    }
                })
            })
        }

    }

    _handleschedule_start(event) {
        this.setState({ schedule_start: event.target.value })
        console.log(event.target.value)
    }

    _handleschedule_end(event) {
        this.setState({ schedule_end: event.target.value })
        console.log(event.target.value)
    }

    _handlealert_high(event) {
        this.setState({ alert_high: event.target.value })
        this.setState({ alert_high_Pre: event.target.value })
    }

    _handlealert_low(event) {
        this.setState({ alert_low: event.target.value })
        this.setState({ alert_low_Pre: event.target.value })
    }

    async postAlertsFunction() {
        if (this.state.alert_high_id !== '' && this.state.alert_low_id !== '') {
            if (this.state.alert_high === '' && this.state.alert_low === '') {
                console.log("delete 1")
                let res = await functions.deleteAlert(this.state.alert_high_id)
                let res1 = await functions.deleteAlert(this.state.alert_low_id)
                this.setState({ alert_high_id: '', alert_low_id: '' })
                // For Pre
                let res_ = await functions.deleteAlert(this.state.alert_high_Pre_id)
                let res_1 = await functions.deleteAlert(this.state.alert_low_Pre_id)
                this.setState({ alert_high_Pre_id: '', alert_low_Pre_id: '' })
            }
            else if (this.state.alert_high === '') {
                console.log("delete 2")
                let res = await functions.deleteAlert(this.state.alert_high_id)
                let res1 = await functions.putAlert(this.state.alert_low_id, this.state.alert_low, this.state.alert_low_events)
                this.setState({ alert_high_id: '' })

                //// for Prerequisites             
                let res_ = await functions.deleteAlert(this.state.alert_high_Pre_id)
                let res_1 = await functions.putAlertPre(this.state.alert_low_Pre_id, this.state.alert_low_Pre, this.state.alert_low_Prerequisites)
                this.setState({ alert_high_Pre_id: '' })
            }
            else if (this.state.alert_low === '') {
                console.log("delete 3")
                let res1 = await functions.deleteAlert(this.state.alert_low_id)
                let res = await functions.putAlert(this.state.alert_high_id, this.state.alert_high, this.state.alert_high_events)
                this.setState({ alert_low_id: '' })

                //// for Prerequisites
                let res_1 = await functions.deleteAlert(this.state.alert_low_Pre_id)
                let res_ = await functions.putAlertPre(this.state.alert_high_Pre_id, this.state.alert_high_Pre, this.state.alert_high_Prerequisites)
                this.setState({ alert_low_Pre_id: '' })
            }
            else {
                if (this.state.alert_low < this.state.alert_high) {
                    let res = await functions.putAlert(this.state.alert_high_id, this.state.alert_high, this.state.alert_high_events)
                    let res1 = await functions.putAlert(this.state.alert_low_id, this.state.alert_low, this.state.alert_low_events)

                    //// for Prerequisites  
                    let res_ = await functions.putAlertPre(this.state.alert_high_Pre_id, this.state.alert_high_Pre, this.state.alert_high_Prerequisites)
                    let res_1 = await functions.putAlertPre(this.state.alert_low_Pre_id, this.state.alert_low_Pre, this.state.alert_low_Prerequisites)
                }
            }
        }

        else if (this.state.alert_high_id !== '') {
            if (this.state.alert_low !== '') {
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    console.log("Post 1")
                    let res = await functions.postAlert(this.props.WaterReducer.water_device._id, '', '', this.state.alert_low, this.state.alert_low_events, 'WaterTank', 'Motor', this.state.alert_low_Prerequisites)

                } else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postAlert(this.props.WaterReducer.water_device._id, '', '', this.state.alert_low, this.state.alert_low_events, 'FlowMeter', 'Valve', this.state.alert_low_Prerequisites)
                }
                this.getAlertsFunction()
            }
            else if (this.state.alert_high === '') {
                console.log("delete 4")

                let res = await functions.deleteAlert(this.state.alert_high_id)
                this.setState({ alert_high_id: '' })

                // For Prerequisites
                let res_ = await functions.deleteAlert(this.state.alert_high_Pre_id)
                this.setState({ alert_high_Pre_id: '' })
            }
            else if (this.state.alert_high !== '' && this.state.alert_low < this.state.alert_high) { // for testing Low < High Value
                let res = await functions.putAlert(this.state.alert_high_id, this.state.alert_high, this.state.alert_high_events)
                // this.updateNotify('Alert');

                // for Prerequisites              
                let res_ = await functions.putAlertPre(this.state.alert_high_Pre_id, this.state.alert_high_Pre, this.state.alert_high_Prerequisites)
            }
        }

        else if (this.state.alert_low_id !== '') {
            if (this.state.alert_high !== '') {
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    console.log("Post 2")
                    let res = await functions.postAlert(this.props.WaterReducer.water_device._id, this.state.alert_high, this.state.alert_high_events, '', '', 'WaterTank', 'Motor', this.state.alert_low_Prerequisites)

                } else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postAlert(this.props.WaterReducer.water_device._id, this.state.alert_high, this.state.alert_high_events, '', '', 'FlowMeter', 'Valve', this.state.alert_low_Prerequisites)
                }
                this.getAlertsFunction()
            }
            if (this.state.alert_low === '') {
                console.log("delete 5")

                let res1 = await functions.deleteAlert(this.state.alert_low_id)
                this.setState({ alert_low_id: '' })
                //For Prerequisites
                let res_1 = await functions.deleteAlert(this.state.alert_low_Pre_id)
                this.setState({ alert_low_Pre_id: '' })
            }
            else if (this.state.alert_low !== '' && this.state.alert_low < this.state.alert_high) {   // for testing Low < High Value
                let res1 = await functions.putAlert(this.state.alert_low_id, this.state.alert_low, this.state.alert_low_events)
                // for Prerequisites
                let res_1 = await functions.putAlertPre(this.state.alert_low_Pre_id, this.state.alert_low_Pre, this.state.alert_low_Prerequisites)
            }
        }

        else {
            if (this.state.alert_high !== '' || this.state.alert_low !== '') {
                console.log("Post 3", this.state.alert_low, this.state.alert_high,)
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    console.log("Post 3", this.state.alert_low, this.state.alert_high,)
                    let res = await functions.postAlert(this.props.WaterReducer.water_device._id, this.state.alert_high, this.state.alert_high_events, this.state.alert_low, this.state.alert_low_events, 'WaterTank', 'Motor', this.state.alert_low_Prerequisites)
                } else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postAlert(this.props.WaterReducer.water_device._id, this.state.alert_high, this.state.alert_high_events, this.state.alert_low, this.state.alert_low_events, 'FlowMeter', 'Valve', this.state.alert_low_Prerequisites)
                }
                this.getAlertsFunction()
            }
        }
    }

    async postSchedulesFunction() {
        if (this.state.schedule_start_id != '' && this.state.schedule_end_id != '') {
            //for delete on and off schedule
            console.log("first")

            if (this.state.schedule_start === '' && this.state.schedule_end === '') {
                console.log("for delete on and off schedule")
                let res = await functions.deleteSchedule(this.state.schedule_start_id)
                let res1 = await functions.deleteSchedule(this.state.schedule_end_id)
                this.setState({ schedule_start_id: '', schedule_end_id: '' })
            }

            else if (this.state.schedule_start === '' && this.state.schedule_end != '') {
                //delete start
                console.log("delete start")
                let res = await functions.deleteSchedule(this.state.schedule_start_id)
                this.setState({ schedule_start_id: '' })

                //update end   
                console.log("update end ")
                console.log("this.state.schedule_end", this.state.schedule_end)
                let res1 = await functions.putScheduleEnd(this.state.schedule_end_id, this.state.schedule_end, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff())
            }

            else if (this.state.schedule_start != '' && this.state.schedule_end === '') {
                //delete end
                console.log("delete end ")

                let res1 = await functions.deleteSchedule(this.state.schedule_end_id)
                this.setState({ schedule_end_id: '' })

                //update start
                console.log("update start")

                let res = await functions.putScheduleStart(this.state.schedule_start_id, this.state.schedule_start, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff());
            }

            else if (this.state.schedule_start != '' && this.state.schedule_end != '') {
                //update both
                console.log("update both")

                let res = await functions.putSchedule(this.state.schedule_start_id, this.state.schedule_start, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff());
                let res1 = await functions.putSchedule(this.state.schedule_end_id, this.state.schedule_end, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff())
            }
            //
            this.getSchedulesFunction()

        } else if (this.state.schedule_start_id == '' && this.state.schedule_end_id != '') {
            console.log("second")
            if (this.state.schedule_start === '' && this.state.schedule_end === '') {
                let res1 = await functions.deleteSchedule(this.state.schedule_end_id)
                this.setState({ schedule_start_id: '', schedule_end_id: '' })
            }
            else if (this.state.schedule_start === '' && this.state.schedule_end != '') {
                //update end
                console.log("update end")

                let res1 = await functions.putScheduleEnd(this.state.schedule_end_id, this.state.schedule_end, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff())

            }
            else if (this.state.schedule_start != '' && this.state.schedule_end === '') {
                //delete end
                console.log("delete end")
                let res1 = await functions.deleteSchedule(this.state.schedule_end_id)
                this.setState({ schedule_end_id: '' })

                //create start
                console.log("create start")
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    let res = await functions.postSchedule(this.props.WaterReducer.water_device._id, this.state.
                        schedule_start, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff(), 'Water_Motor')
                }
                else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postScheduleStart(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff(), 'Water_Valve')
                }


            } else if (this.state.schedule_start != '' && this.state.schedule_end != '') {
                // delete end
                console.log("delete end")
                let res1 = await functions.deleteSchedule(this.state.schedule_end_id)
                this.setState({ schedule_end_id: '' })
                //create start
                console.log("create start")
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    let res = await functions.postSchedule(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Motor')
                }
                else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postSchedule(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Valve')
                }

                //update end
                // console.log("update end")
                // let res1 = await functions.putScheduleEnd(this.state.schedule_end_id, this.state.schedule_end, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff())

            }
            //
            this.getSchedulesFunction()

        }
        else if (this.state.schedule_start_id != '' && this.state.schedule_end_id == '') {
            console.log("third")
            if (this.state.schedule_start === '' && this.state.schedule_end === '') {
                let res = await functions.deleteSchedule(this.state.schedule_start_id)
                this.setState({ schedule_start_id: '', schedule_end_id: '' })
            }
            else if (this.state.schedule_start === '' && this.state.schedule_end != '') {
                //start delete
                console.log("start delete")

                let res = await functions.deleteSchedule(this.state.schedule_start_id)
                this.setState({ schedule_start_id: '' })

                //create end
                console.log("create end1")
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    let res = await functions.postScheduleEnd(this.props.WaterReducer.water_device._id, this.state.schedule_end, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Motor')
                }
                else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postScheduleEnd(this.props.WaterReducer.water_device._id, this.state.schedule_end, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Valve')
                }

            }
            else if (this.state.schedule_start != '' && this.state.schedule_end === '') {
                //update start
                console.log("update start")

                let res = await functions.putScheduleStart(this.state.schedule_start_id, this.state.schedule_start, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff());

            }
            else if (this.state.schedule_start != '' && this.state.schedule_end != '') {
                // start delete
                console.log("start delete")

                let res = await functions.deleteSchedule(this.state.schedule_start_id)
                this.setState({ schedule_start_id: '' })

                //create end
                console.log("create end2")

                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    let res = await functions.postSchedule(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Motor')
                }
                else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postSchedule(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Valve')
                }

                //update start
                // console.log("update start")

                // let res_ = await functions.putScheduleStart(this.state.schedule_start_id, this.state.schedule_end, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff());
            }
            //
            this.getSchedulesFunction()

        }
        else {
            console.log("fourth")

            if (this.state.schedule_start === '' && this.state.schedule_end === '') {
                this.setState({ schedule_start_id: '', schedule_end_id: '' })
            }
            else if (this.state.schedule_start === '' && this.state.schedule_end != '') {
                //create end
                console.log("create end")
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    let res = await functions.postScheduleEnd(this.props.WaterReducer.water_device._id, this.state.schedule_end, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Motor')
                }
                else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postScheduleEnd(this.props.WaterReducer.water_device._id, this.state.schedule_end, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Valve')
                }

            }
            else if (this.state.schedule_start != '' && this.state.schedule_end === '') {
                //create start
                console.log("create start")
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    let res = await functions.postScheduleStart(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff(), 'Water_Motor')
                } else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postScheduleStart(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_start, this.setRepeatOn(), this.setRepeatOff(), 'Water_Valve')
                }

            }
            else if (this.state.schedule_start != '' && this.state.schedule_end != '') {
                //create both
                console.log("create both")
                if (this.props.WaterReducer.water_device.type === 'WaterTank') {
                    let res = await functions.postSchedule(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Motor')
                } else if (this.props.WaterReducer.water_device.type === 'FlowMeter') {
                    let res = await functions.postSchedule(this.props.WaterReducer.water_device._id, this.state.schedule_start, this.state.schedule_end, this.setRepeatOn(), this.setRepeatOff(), 'Water_Valve')
                }
            }
            //
            this.getSchedulesFunction()
        }

    }

    addEvents = () => {
        if (this.state.alert_low !== '') {        //for add Event directly if user enter motor on value   
            let device = this.props.WaterReducer.water_device;
            let eventType = 'Low';

            if (this.state.alert_low_events.length === 0) {
                let alert_low_events = this.state.alert_low_events;
                alert_low_events.push({
                    deviceId: device._id,
                    hubId: device.hubId,
                    type: 'Water_Motor',
                    value: (eventType === 'Low') ? 1 : 0
                })
                this.setState({
                    alert_low_events
                })
            }
            if (this.state.alert_low_events.value > 0) {
                let alert_low_events = this.state.alert_low_events;
                alert_low_events = [{
                    deviceId: device._id,
                    hubId: device.hubId,
                    type: 'Water_Motor',
                    value: (eventType === 'Low') ? 1 : 0
                }]
                this.setState({
                    alert_low_events
                })
            }
        }

        if (this.state.alert_high !== '') {        //for add Event directly if user enter motorOff value   
            let alert_high_events = this.state.alert_high_events;
            let device = this.props.WaterReducer.water_device;
            let eventType = 'High';

            if (this.state.alert_high_events.length === 0) {
                alert_high_events.push({
                    deviceId: device._id,
                    hubId: device.hubId,
                    type: 'Water_Motor',
                    value: (eventType === 'High') ? 0 : 0
                })
                this.setState({
                    alert_high_events
                })
            }
            if (this.state.alert_high_events.value > 0) {
                alert_high_events = [{
                    deviceId: device._id,
                    hubId: device.hubId,
                    type: 'Water_Motor',
                    value: (eventType === 'High') ? 0 : 0
                }]
                this.setState({
                    alert_high_events
                })
            }
        }
    }

    addPrerequisites = () => {
        if (updatedValue !== '' || this.state.value !== '') {
            if (this.state.prerequisites.length === 0) {
                console.log("prerequisites ADD ")
                let prerequisites = this.state.prerequisites;
                prerequisites.push({
                    deviceId: this.state.devices[1]._id,
                    type: 'Water_WaterTank_Status',
                    condition: 'HIGH',
                    value: this.state.value
                    // value: preReqVar
                })
                this.setState({
                    prerequisites
                })
            }
            else if (this.state.prerequisites.length > 0) {
                console.log("prerequisites UPDATE ")
                console.log("updatedValue ", this.state.value)

                let prerequisites = this.state.prerequisites;
                let oldValue = prerequisites.value;
                prerequisites = [{
                    deviceId: this.state.devices[1]._id,
                    type: 'Water_WaterTank_Status',
                    condition: 'HIGH',
                    value: this.state.value
                    // value: preReqVar
                }]
                this.setState({
                    prerequisites
                })
            }
        }
        this.updateEvents2(this.state.prerequisites, 'Low');
    }


    unselect_RepeatedDays = () => {
        if (this.state.schedule_start === '') {
            this.setState({
                monOn: false,
                tueOn: false,
                wedOn: false,
                thuOn: false,
                friOn: false,
                satOn: false,
                sunOn: false,
                allOn: false,
            })
        }

        if (this.state.schedule_end === '') {
            this.setState({
                monOff: false,
                tueOff: false,
                wedOff: false,
                thuOff: false,
                friOff: false,
                satOff: false,
                sunOff: false,
                allOff: false
            })
        }
    }

    handleChangeValueNew = (e) => {
        console.log("first Pre:", e.target.value);
        this.setState({ value: e.target.value });
    }

    handleChangeValue = (e, index) => {
        console.log("second Pre:", e.target.value);

        let prerequisites = this.state.prerequisites;
        prerequisites[index].value = e.target.value;
        updatedValue = e.target.value;
        // console.log("updatedValue", updatedValue)
        this.setState({
            prerequisites
        })
        console.log("second Pre updatedValue:", updatedValue);

        if (updatedValue == '') {
            let prerequisites = this.state.prerequisites;
            prerequisites.splice(index, 1);
            this.setState({
                prerequisites
            })
        }
        console.log(" second pre changed :", updatedValue)
        this.setState({ value: updatedValue })

    }

    _handleSave() {
        // preReqVar = $('#preReqValue').val();
        this.addEvents(); // for adding events on insert Motor ON and Motor OFF 
        this.addPrerequisites();

        this.postAlertsFunction();
        this.postSchedulesFunction();

        this.unselect_RepeatedDays(); // for unselect days if user remove schedule time ON or OFF

        this.updateNotify_Conditions();
    }


    updateEvents = (events, type) => {
        if (type === 'Low') {
            this.setState({
                alert_low_events: events,
                collapse: !this.state.collapse
            })
        } else if (type === 'High') {
            this.setState({
                alert_high_events: events,
                collapse1: !this.state.collapse1
            })
        }
    }

    updateEvents2 = (events, type) => { // for prerequisites
        console.log("this.state.prerequisites2", events);
        if (type === 'Low') {
            this.setState({
                alert_low_Prerequisites: events,
                collapsePre: !this.state.collapsePre
            })
        } else if (type === 'High') {
            this.setState({
                alert_high_Prerequisites: events,
                collapsePre1: !this.state.collapsePre1
            })
        }
    }


    scheduleHandlerOn(e) {

        var val = !this.state[e.target.name];
        this.state[e.target.name] = val;
        this.forceUpdate();
        // this.toggleChange(true);
        if (e.target.name !== "allOn" && e.target.checked === false) {
            this.state.allOn = false;
            this.forceUpdate();
            // this.toggleChange(true);
        }

        if (e.target.name !== "allOn" && e.target.checked === true) {
            if (
                this.state.monOn &&
                this.state.tueOn &&
                this.state.wedOn &&
                this.state.thuOn &&
                this.state.friOn &&
                this.state.satOn &&
                this.state.sunOn
            ) {
                this.state.allOn = true;
                this.forceUpdate();
                // this.toggleChange(true);
            }
        }

        if (e.target.name === "allOn" && e.target.checked === true) {
            this.state.monOn = true;
            this.state.tueOn = true;
            this.state.wedOn = true;
            this.state.thuOn = true;
            this.state.friOn = true;
            this.state.satOn = true;
            this.state.sunOn = true;

            this.forceUpdate();
            // this.toggleChange(true);
        }
        if (e.target.name === "allOn" && e.target.checked === false) {
            this.state.monOn = false;
            this.state.tueOn = false;
            this.state.wedOn = false;
            this.state.thuOn = false;
            this.state.friOn = false;
            this.state.satOn = false;
            this.state.sunOn = false;

            this.forceUpdate();
            // this.toggleChange(true);
        }
        this.setRepeatOn();
    }

    scheduleHandlerOff(e) {
        var val = !this.state[e.target.name];
        console.log("val", val);
        this.state[e.target.name] = val;
        this.forceUpdate();
        // this.toggleChange(true);
        if (e.target.name !== "allOff" && e.target.checked === false) {
            this.state.allOff = false;
            this.forceUpdate();
            //   this.toggleChange(true);
        }

        if (e.target.name !== "allOff" && e.target.checked === true) {
            if (
                this.state.monOff &&
                this.state.tueOff &&
                this.state.wedOff &&
                this.state.thuOff &&
                this.state.friOff &&
                this.state.satOff &&
                this.state.sunOff
            ) {
                this.state.allOff = true;
                this.forceUpdate();
                // this.toggleChange(true);
            }
        }

        if (e.target.name === "allOff" && e.target.checked === true) {
            this.state.monOff = true;
            this.state.tueOff = true;
            this.state.wedOff = true;
            this.state.thuOff = true;
            this.state.friOff = true;
            this.state.satOff = true;
            this.state.sunOff = true;

            this.forceUpdate();
            //   this.toggleChange(true);
        }
        if (e.target.name === "allOff" && e.target.checked === false) {
            this.state.monOff = false;
            this.state.tueOff = false;
            this.state.wedOff = false;
            this.state.thuOff = false;
            this.state.friOff = false;
            this.state.satOff = false;
            this.state.sunOff = false;

            this.forceUpdate();
            //   this.toggleChange(true);
        }
        this.setRepeatOff();
    }


    setRepeatOn = () => {
        let repeatArray = [];
        if (this.state.monOn) {
            repeatArray.push("Mon");
        }
        if (this.state.tueOn) {
            repeatArray.push("Tue");
        }
        if (this.state.wedOn) {
            repeatArray.push("Wed");
        }
        if (this.state.thuOn) {
            repeatArray.push("Thu");
        }
        if (this.state.friOn) {
            repeatArray.push("Fri");
        }
        if (this.state.satOn) {
            repeatArray.push("Sat");
        }
        if (this.state.sunOn) {
            repeatArray.push("Sun");
        }
        return repeatArray;
    };

    setRepeatOff = () => {
        let repeatArray = [];
        if (this.state.monOff) {
            repeatArray.push("Mon");
        }
        if (this.state.tueOff) {
            repeatArray.push("Tue");
        }
        if (this.state.wedOff) {
            repeatArray.push("Wed");
        }
        if (this.state.thuOff) {
            repeatArray.push("Thu");
        }
        if (this.state.friOff) {
            repeatArray.push("Fri");
        }
        if (this.state.satOff) {
            repeatArray.push("Sat");
        }
        if (this.state.sunOff) {
            repeatArray.push("Sun");
        }
        return repeatArray;
    };

    render() {
        if (this.props.WaterReducer.water_device === undefined) {
            this.props.history.push(`/levels/${this.props.match.params.levelId}`);
        }
        return (
            <div>
                <Header history={this.props.history} toggle={() => this.sidenavToggleHandler()} name={"Settings"}  ></Header>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    style={{ width: '80%', height: '30px', margin: '65px 0 0 20%', right: '0px !important' }}
                />
                <Sidenav width={this.state.sidenav ? '75%' : '0px'} history={this.props.history}></Sidenav>

                {(this.state.loading) ?
                    null
                    :
                    <div className="col-12 animated fadeIn p-0">

                        <div style={{ marginTop: '60px', marginBottom: '40px', width: '100%', padding: '10px' }} className="component-container-box">
                            <div className="row" style={{ margin: '0px' }}>

                                {(this.state.device) === 'Motor' ?
                                    <React.Fragment>
                                        <h5 style={{ textAlign: "center", width: "100%" }}>{this.state.deviceName}</h5>
                                        <div className="col-12 rounded" style={{ marginTop: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.2)' }}>
                                            <div className="row py-3">
                                                <div className="col-12">
                                                    <p className="text-left" style={{ float: 'left', display: 'block' }}>{this.state.device}  Switch<br /> On Time
                                                    </p>
                                                    <input style={{ border: '0px', float: 'right', display: 'block' }} type="time" name="usr_time" onChange={this._handleschedule_start} value={this.state.schedule_start} />
                                                </div>
                                                <div className="col-12 d-flex">
                                                    <FormGroup className="text-left">
                                                        <p>Days </p>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={this.state.monOn}
                                                                    name="monOn"
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                    Mon
                                                </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="tueOn"
                                                                    checked={this.state.tueOn}
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                     Tue
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="wedOn"
                                                                    checked={this.state.wedOn}
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                    Wed
                                                </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="thuOn"
                                                                    checked={this.state.thuOn}
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                    Thu
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="friOn"
                                                                    checked={this.state.friOn}
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                     Fri
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="satOn"
                                                                    checked={this.state.satOn}
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                        Sat
                                                    </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="sunOn"
                                                                    checked={this.state.sunOn}
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                    Sun
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="allOn"
                                                                    checked={this.state.allOn}
                                                                    onChange={this.scheduleHandlerOn.bind(this)}
                                                                />{" "}
                                                    All
                                                </Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </div>

                                                {/* <div style={{ display: 'inline', float: 'right', color: 'red' }}><i class="fa fa-times"
                                            onClick={this.deleteStartSechedule.bind(this)} ></i></div> */}
                                                {/* {(this.state.alert_low !== '') ? */}
                                                <div className="col-12">
                                                    <p className="text-left" style={{ float: 'left', display: 'block' }}>{this.state.device} Switch On at<br />Tank Level
                                                    </p>
                                                    <span style={{ float: 'right' }}>%</span>
                                                    <input style={{ border: '1px solid grey', marginRight: '4px', float: 'right', display: 'block', width: '75px' }} type="number" name="quantity" min="0" max="100" onChange={this._handlealert_low} value={this.state.alert_low} />
                                                </div>
                                                {/* <div className="col-12" style={{ padding: '0px 10px 5px' }}>
                                                        <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>Tank Selection</h6>
                                                        <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                                                            {(!this.state.collapse) ?
                                                                <i class="fa fa-chevron-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle()}></i> :
                                                                <i class="fa fa-chevron-up float-right" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle()}></i>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-12" style={{ padding: '0px 10px 10px' }}>
                                                        <Collapse className="rounded" isOpen={this.state.collapse} id="collapseExample" style={{ padding: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                                                            <CardBody style={{ padding: '0px' }}>
                                                                <AlertEvents events={this.state.alert_low_events} show={false} type="Low" device={this.props.WaterReducer.water_device} handler={this.updateEvents} />
                                                            </CardBody>
                                                        </Collapse>
                                                    </div> */}
                                                <hr style={{ border: '1px solid rgb(240,240,240)', width: '100%', margin: '6px 10px' }} />
                                                {
                                                    //for showing  Prerequisites only in overhead

                                                    ((this.state.devices.length > 1) ?
                                                        (this.state.deviceName === 'Overhead') ?


                                                            <div className="col-12">

                                                                {/* <div className="col-12" style={{ marginTop: 20, padding: '0px 10px 5px' }}> */}
                                                                {/* <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>Prerequisites</h6> */}
                                                                {/* <div className=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}> */}
                                                                {/* {(!this.state.collapsePre) ?
                                                                <i class="fa fa-chevron-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.togglePre()}></i> :
                                                                <i class="fa fa-chevron-up float-right" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.togglePre()}></i>
                                                            } */}
                                                                {/* </div>
                                                                </div> */}



                                                                {/* <Collapse isOpen={this.state.collapsePre} id="collapseExample" style={{ padding: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                                                            <CardBody style={{ padding: '0px' }}> */}
                                                                {/* <AlertEvents events={this.state.alert_low_Prerequisites} show={true} type="Low" device={this.props.WaterReducer.water_device} handler={this.updateEvents2} /> */}
                                                                {
                                                                    (this.state.prerequisites.length === 0) ?
                                                                        <React.Fragment>
                                                                            <p className="text-left m-0" style={{ float: 'left', display: 'block' }}>Underground Tank<br />Level Greater Than  </p>
                                                                            <span style={{ float: 'right' }}>%</span>
                                                                            <input style={{ border: '1px solid grey', marginRight: '4px', float: 'right', display: 'block', width: '75px' }}
                                                                                type="number"
                                                                                name="value"
                                                                                ref="value"
                                                                                min='0'
                                                                                max='100'
                                                                                // placeholder="Value"
                                                                                value={this.state.value}
                                                                                onChange={(e) => this.handleChangeValueNew(e)}
                                                                            />
                                                                        </React.Fragment>
                                                                        : null

                                                                }

                                                                {this.state.prerequisites.map((event, index) => (
                                                                    <React.Fragment>
                                                                        <p className="text-left m-0" style={{ float: 'left', display: 'block' }}>Underground Tank<br />Level Greater Than  </p>
                                                                        <span style={{ float: 'right' }}>%</span>
                                                                        <input style={{ border: '1px solid grey', marginRight: '4px', float: 'right', display: 'block', width: '75px' }}
                                                                            type="number"
                                                                            name="value"
                                                                            ref="value"
                                                                            id="preReqValue"
                                                                            min='0'
                                                                            max='100'
                                                                            // placeholder="Value"
                                                                            value={event.value}
                                                                            onChange={(e) => this.handleChangeValue(e, index)}
                                                                        /></React.Fragment>
                                                                ))}

                                                                {/* </CardBody>
                                                        </Collapse> */}
                                                            </div>
                                                            : null

                                                        :
                                                        null
                                                    )
                                                }
                                            </div>
                                            {/* //     :
                                            //     <div className="row m-0 rounded" style={{ margin: '10px 0px 0px 0px', padding: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.2)' }}>
                                            //         <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>{this.state.device} On Water Level</h6>
                                            //         <input style={{ border: '0px', display: 'block', width: '75px' }} type="number" name="quantity" min="0" max="100" onChange={this._handlealert_low} value={this.state.alert_low} />
                                            //         <span>%</span>
                                            //     </div>
                                            // } */}

                                        </div>
                                        <div className="col-12 rounded"
                                            style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0,0,0,0.2)' }}>
                                            <div className="row py-3">
                                                <div className="col-12">
                                                    <p className="text-left" style={{ float: 'left', display: 'block' }}>{this.state.device} Switch <br /> Off Time</p>
                                                    <input style={{ border: '0px', float: 'right', display: 'block' }} type="time" name="usr_time" onChange={this._handleschedule_end} value={this.state.schedule_end} />
                                                </div>
                                                <div className="col-12 d-flex">
                                                    <FormGroup className="text-left">
                                                        <p>Days </p>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={this.state.monOff}
                                                                    name="monOff"
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                    Mon
                                                </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="tueOff"
                                                                    checked={this.state.tueOff}
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                     Tue
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="wedOff"
                                                                    checked={this.state.wedOff}
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                    Wed
                                                </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="thuOff"
                                                                    checked={this.state.thuOff}
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                    Thu
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="friOff"
                                                                    checked={this.state.friOff}
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                     Fri
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="satOff"
                                                                    checked={this.state.satOff}
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                        Sat
                                                    </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="sunOff"
                                                                    checked={this.state.sunOff}
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                    Sun
                                                 </Label>
                                                        </FormGroup>

                                                        <FormGroup check inline>
                                                            <Label check>
                                                                <Input
                                                                    type="checkbox"
                                                                    name="allOff"
                                                                    checked={this.state.allOff}
                                                                    onChange={this.scheduleHandlerOff.bind(this)}
                                                                />{" "}
                                                    All
                                                </Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </div>

                                                {/* {(this.state.alert_high !== '') ? */}

                                                <div className="col-12">
                                                    <p className="text-left m-0" style={{ float: 'left', display: 'block' }}>{this.state.device} Switch Off at <br />Tank Level</p>
                                                    <span style={{ float: 'right' }}>%</span>
                                                    <input style={{ border: '1px solid grey', marginRight: '4px', float: 'right', display: 'block', width: '75px' }} type="number" name="quantity" min="0" max="100" onChange={this._handlealert_high} value={this.state.alert_high} />
                                                </div>
                                                {/* <div className="col-12" style={{ padding: '0px 10px 5px' }}>
                                                        <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>Tank Selection</h6>
                                                        <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                                                            {(!this.state.collapse1) ?
                                                                <i class="fa fa-chevron-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.toggle1()}></i> :
                                                                <i class="fa fa-chevron-up float-right" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.toggle1()}></i>
                                                            }
                                                        </div>
                                                    </div> */}
                                                {/* <div className="col-12" style={{ padding: '0px 15px 10px' }}> */}
                                                {/* <Collapse isOpen={this.state.collapse1} id="collapseExample" style={{ padding: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                                                            <CardBody style={{ padding: '0px' }}>
                                                                <AlertEvents events={this.state.alert_high_events} show={false} type="High" device={this.props.WaterReducer.water_device} handler={this.updateEvents} />
                                                            </CardBody>
                                                        </Collapse> */}
                                                {/* </div> */}
                                                {/* <hr style={{ border: '1px solid rgb(240,240,240)', width: '100%', margin: '0 10px' }} /> */}
                                                {/* <div className="col-12" style={{ marginTop: 20, padding: '0px 10px 5px' }}>
                                                        <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>Prerequisites</h6>
                                                        <div class=" small general-value-div" style={{ color: '#20536c', fontWeight: 'bold', margin: 'auto 10px 8px auto', textAlign: 'center' }}>
                                                            {(!this.state.collapsePre1) ?
                                                                <i class="fa fa-chevron-down float-right" style={{ marginTop: '5px', transition: '0.5s transform', cursor: 'pointer' }} onClick={() => this.togglePre1()}></i> :
                                                                <i class="fa fa-chevron-up float-right" style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => this.togglePre1()}></i>
                                                            }
                                                        </div>
                                                    </div> */}
                                                {/* <div className="col-12" style={{ padding: '0px 15px 10px' }}>
                                                        <Collapse isOpen={this.state.collapsePre1} id="collapseExample" style={{ padding: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                                                            <CardBody style={{ padding: '0px' }}>
                                                        <AlertEvents events={this.state.alert_high_Prerequisites} show={true} type="High" device={this.props.WaterReducer.water_device} handler={this.updateEvents2} />
                                                        </CardBody>
                                                        </Collapse>
                                                    </div> */}
                                            </div>

                                            {/* :
                                                <div className="col-12 rounded" style={{ margin: '10px 0px 0px 0px', padding: '10px', boxShadow: '0px 0px 10px rgba(0,0,0,0.1)' }}>
                                                    <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>{this.state.device} Off Water Level</h6>
                                                    <span style={{ float: 'right' }}>%</span>
                                                    <input style={{ border: '0px', float: 'right', display: 'block', width: '75px' }} type="number" name="quantity" min="0" max="100" onChange={this._handlealert_high} value={this.state.alert_high} />
                                                </div>
                                            } */}
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="col-12" style={{ marginTop: '20px', border: '1px solid black', padding: '10px' }}>
                                        <h6 style={{ fontSize: '13px', float: 'left', display: 'block' }}>{this.state.device} Off Flow Volume</h6>
                                        <span style={{ float: 'right' }}>Ltr</span>
                                        <input style={{ border: '0px', float: 'right', display: 'block', width: '75px' }} type="number" name="quantity" min="0" max="100" onChange={this._handlealert_high} value={this.state.alert_high} />
                                    </div>
                                }
                                <div className="col-12 " style={{ margin: '20px 0px', padding: '0px' }}>
                                    <button style={{ width: "100%", backgroundColor: "#1286A8", color: "white", borderColor: "#1286A8", marginBottom: "" }} onClick={this._handleSave}>Save</button>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                <Footer></Footer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        WaterReducer: state.WaterReducer
    }
}

export default connect(mapStateToProps, null)(withRouter(WaterSchedule));