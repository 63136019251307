let flatenHierarchy = function(org) {
    let levels = [];
    traverseHierarchy(org, levels);
    return levels;
}

let traverseHierarchy = function(level, levels) {
    if(level.levels) {
        for(let i = 0; i < level.levels.length; i++) {
            if(level.levels[i] && level.levels[i].levels) {
                traverseHierarchy(level.levels[i], levels);
                levels.push(level.levels[i]);
            } else {
                levels.push(level.levels[i]);
            }
        }
    }
}

export default {
    flatenHierarchy
}