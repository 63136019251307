'use strict';

import * as idb  from './idb'

/*
* Writes data to a particular store within a database.
* Document is overidden if id matches.
*/
export async function write(dbName, storeName, id, document) {
    let database = await idb.openDb(dbName, 1, (db) => {
        if (!db.objectStoreNames.contains(storeName)) {
            db.createObjectStore(storeName);
        }
    });

    let transaction = database.transaction(storeName, 'readwrite');
    let store = transaction.objectStore(storeName);
    await store.put(document, id);
    await transaction.complete;
}

/*
* Reads all data from a store.
* Returns empty array if store doesn't exists.
*/
export async function read(dbName, storeName) {
    let database = await idb.openDb(dbName, 1);
    let transaction = database.transaction(storeName, 'readonly');
    let store = transaction.objectStore(storeName);
    let results = await store.getAll();
    await transaction.complete;
    return results;
}

/*
* Reads a single document from a store.
* Returns null if not found.
*/
export async function readSingle(dbName, storeName, id) {
    let database = await idb.openDb(dbName, 1);
    let transaction = database.transaction(storeName, 'readonly');
    let store = transaction.objectStore(storeName);
    let results = await store.get(id);
    await transaction.complete;
    return results;
}

/* 
* Removes all documents from a store.
*/
export async function remove(dbName, storeName) {
    let database = await idb.openDb(dbName, 1);
    let transaction = database.transaction(storeName, 'readwrite');
    let store = transaction.objectStore(storeName);
    await store.clear();
    await transaction.complete;
}

/*
* Removes a single document from store.
* Returns null if not found.
*/
export async function removeSingle(dbName, storeName, id) {
    let database = await idb.openDb(dbName, 1);
    let transaction = database.transaction(storeName, 'readwrite');
    let store = transaction.objectStore(storeName);
    await store.delete(id);
    await transaction.complete;
}

// Remove database

export async function removeDb(dbName) {
    let database = await idb.deleteDb(dbName);
}