import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Sidenav from '../../components/Sidenav/Sidenav';
import Content from '../Content/Content';
import store from '../../store';

//     Images
import Room from '../../assets/icons/room-50x50.png';

import Agri from '../../assets/level/ic_agri.png';
import Airquality from '../../assets/level/ic_airquality.png';
import Barrage from '../../assets/level/ic_barrage.png';
import Controlroom from '../../assets/level/ic_controlroom.png';
import Datacenter from '../../assets/level/ic_datacenter.png';
import Hall from '../../assets/level/ic_hall.png';
import Home from '../../assets/level/ic_home.png';
import Kitchen from '../../assets/level/ic_kitchen.png';
import Office from '../../assets/level/ic_office.png';
import Outdoor from '../../assets/level/ic_outdoor.png';
import Power from '../../assets/level/ic_power.png';
import Reception from '../../assets/level/ic_reception.png';
import Study from '../../assets/level/ic_study.png';
import Utilities from '../../assets/level/ic_utilities.png';
import Washroom from '../../assets/level/ic_washroom.png';

import './componentUI.css'

class LevelUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            levelCount: 0,
            deviceCount: 0
        }
    }





    componentDidMount() {
        console.log(this.props.item)
        const path = this.props.history.location.pathname;
        const levelId = path.split('/')[path.split('/').length - 1];
        const org = levelId.split('_')[0];

        let storeData = store.getState().OrganizationReducer.organizationsDetails;
        let levelCount = 0;
        let deviceCount = 0;
        storeData.forEach((i) => {
            if (i._id === org) {

                i.levels.forEach((e) => {
                    if (e.levelId === this.props.item._id) {
                        levelCount++;
                    }
                })
                i.devices.forEach((e) => {
                    if (e.levelId === this.props.item._id) {
                        deviceCount++;
                    }
                })

                this.setState({
                    levelCount,
                    deviceCount
                })
            }
        })


    }
    render() {
        return (


            // <div className="col-12 animated fadeIn" style={{ marginTop: '5px', padding: '5px' }} onClick={() => this.props.history.push('/levels/' + this.props.item._id)}>

            //     <div style={{ width: '100%', borderRadius: '10px' }} className="component-container-box">
            //         <div className="row" style={{ margin: 0 }}>
            //             <div className='col-3' style={{ width: '100%', overflow: 'hidden', paddingTop: 10, paddingBottom: 10, paddingLeft: 15, backgroundColor: '#1286A8', borderRadius: '10px 0px 0px 10px' }}>
            //                 {(this.props.item.name === 'Utilities') ?
            //                     <img width={50} height={50} src={Utilities} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //                     :
            //                     ((this.props.item.name === 'Outdoor') ?
            //                         <img width={50} height={50} src={Outdoor} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //                         :
            //                         ((this.props.item.name === 'Home') ?
            //                             <img width={50} height={50} src={Home} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //                             :
            //                             ((this.props.item.name === 'Office') ?
            //                                 <img width={50} height={50} src={Office} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //                                 :
            //                                 ((this.props.item.name === 'Hall') ?
            //                                     <img width={50} height={50} src={Hall} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //                                     :
            //                                     <img width={50} height={50} src={roomImg} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />)
            //                             )
            //                         )
            //                     )
            //                 }
            //                 {/* {(this.props.item.name === 'Outdoor') ?
            //                     <img width={50} height={50} src={Outdoor} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //                     :
            //                     <img width={50} height={50} src={roomImg} style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)', filter: 'brightness(100)' }} />
            //                 } */}
            //             </div>
            //             <div className='col-9' style={{ textAlign: 'left', paddingTop: 10, paddingBottom: 10, paddingRight: 25 }}>
            //                 <h6 style={{ fontWeight: 'bold' }}>{this.props.item.name}</h6>

            //                 <div className="">
            //                     <div style={{ fontSize: 12 }}><span style={{ fontWeight: 'bold' }}>Nested Levels: </span><span>{this.state.levelCount} levels</span></div>
            //                     <div style={{ fontSize: 12 }}><span style={{ fontWeight: 'bold' }}>Nested Devices: </span><span>{this.state.deviceCount} Devices</span></div>

            //                 </div>
            //             </div>
            //         </div>

            <div className="content-container" onClick={() => this.props.history.push('/levels/' + this.props.item._id)}>
                <div style={{ padding: '10px 3px', backgroundImage: 'linear-gradient(#1286A8, #2eafd3)', borderRadius: '5px' }}>
                    {/* <div style={{ float: 'right', marginRight: 10 }}><span style={{ color: 'rgb(119, 119, 119)' }}></span></div> */}
                    <div style={{ width: '100%', overflow: 'hidden' }}>
                        {(this.props.item.type === 'Utilities') ?
                            <img width={50} height={50} src={Utilities} style={{ filter: 'brightness(10)' }} />
                            :
                            ((this.props.item.type === 'Outdoor') ?
                                <img width={50} height={50} src={Outdoor} style={{ filter: 'brightness(10)' }} />
                                :
                                ((this.props.item.type === 'Home') ?
                                    <img width={50} height={50} src={Home} style={{ filter: 'brightness(10)' }} />
                                    :
                                    ((this.props.item.type === 'Office' || this.props.item.type === 'Branch') ?
                                        <img width={50} height={50} src={Office} style={{ filter: 'brightness(10)' }} />
                                        :
                                        ((this.props.item.type === 'Hall') ?
                                            <img width={50} height={50} src={Hall} style={{ filter: 'brightness(10)' }} />
                                            :
                                            ((this.props.item.type === 'Agri') ?
                                                <img width={50} height={50} src={Agri} style={{ filter: 'brightness(10)' }} />
                                                :
                                                ((this.props.item.type === 'Airquality') ?
                                                    <img width={50} height={50} src={Airquality} style={{ filter: 'brightness(10)' }} />
                                                    :
                                                    ((this.props.item.type === 'Barrage') ?
                                                        <img width={60} height={60} src={Barrage} style={{ filter: 'brightness(10)' }} />
                                                        :
                                                        ((this.props.item.type === 'Controlroom') ?
                                                            <img width={50} height={50} src={Controlroom} style={{ filter: 'brightness(10)' }} />
                                                            :
                                                            ((this.props.item.type === 'Datacenter') ?
                                                                <img width={50} height={50} src={Datacenter} style={{ filter: 'brightness(10)' }} />
                                                                :
                                                                ((this.props.item.type === 'Kitchen') ?
                                                                    <img width={50} height={50} src={Kitchen} style={{ filter: 'brightness(10)' }} />
                                                                    :
                                                                    ((this.props.item.type === 'Power') ?
                                                                        <img width={50} height={50} src={Power} style={{ filter: 'brightness(10)' }} />
                                                                        :
                                                                        ((this.props.item.type === 'Reception') ?
                                                                            <img width={50} height={50} src={Reception} style={{ filter: 'brightness(10)' }} />
                                                                            :
                                                                            ((this.props.item.type === 'Study') ?
                                                                                <img width={50} height={50} src={Study} style={{ filter: 'brightness(10)' }} />
                                                                                :
                                                                                ((this.props.item.type === 'Washroom') ?
                                                                                    <img width={50} height={50} src={Washroom} style={{ filter: 'brightness(10)' }} />
                                                                                    :
                                                                                    <img width={50} height={50} src={Room} style={{ filter: 'brightness(10)' }} />
                                                                                )
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        }
                        {/* <img width={50} height={50} src={this.props.item.type} style={{ filter: 'brightness(10)' }} /> */}
                    </div>
                    <p style={{ color: 'white' }}>{this.props.item.name}</p>
                </div>
            </div>
            //     </div>
            // </div>





        );
    }
}

export default LevelUI;