import axios from '../utilities/axios';
import cookies from '../utilities/cookies';

export let getEventsByLevel = async (levelId) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/levels/' + levelId + '/events');
        return response.data;
    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}

export let getStatusByLevel = async (levelId) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/levels/' + levelId + '/status');
        return response.data;
    } catch (err) {
        err.response.data.error = true;
        return err.response.data;
    }
}

export let getConsumptionByDevice = async (deviceId) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/devices/' + deviceId + '/water/consumption');
        return response.data;
    } catch (err) {
        if (err.response) {
            err.response.data.error = true;
            return err.response.data;
        }
        else {
            let error = {
                response: {
                    data: {
                        error: true,
                        statusCode: 503,
                        message: "Network Error"
                    }
                }
            };
            return error.response.data

        }

    }
}


export let getConsumptionByDeviceAndDate = async (deviceId, filter, date) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/devices/' + deviceId + '/water/consumption?filter=' + filter);
        return response.data;
    } catch (err) {
        if (err.response) {
            err.response.data.error = true;
            return err.response.data;
        }
        else {
            let error = {
                response: {
                    data: {
                        error: true,
                        statusCode: 503,
                        message: "Network Error"
                    }
                }
            };
            return error.response.data

        }

    }
}

export let getIntakeByDevice = async (deviceId, filter) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        // let response = await axios.get('/devices/'+deviceId+'/water/consumption?filter='+filter+'&endDate='+date);
        let response = await axios.get('/devices/' + deviceId + '/water/intake?unit=' + filter);
        return response.data;
    } catch (err) {
        if (err.response) {
            err.response.data.error = true;
            return err.response.data;
        }
        else {
            let error = {
                response: {
                    data: {
                        error: true,
                        statusCode: 503,
                        message: "Network Error"
                    }
                }
            };
            return error.response.data

        }

    }
}

export let getFlowByDevice = async (deviceId, filter) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        // let response = await axios.get('/devices/'+deviceId+'/water/consumption?filter='+filter+'&endDate='+date);
        let response = await axios.get('/devices/' + deviceId + '/water/flow?filter=' + filter);
        return response.data;
    } catch (err) {
        if (err.response) {
            err.response.data.error = true;
            return err.response.data;
        }
        else {
            let error = {
                response: {
                    data: {
                        error: true,
                        statusCode: 503,
                        message: "Network Error"
                    }
                }
            };
            return error.response.data

        }

    }
}

export let getSelectedEvents = async (deviceId, limit, type) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        // let response = await axios.get('/devices/'+deviceId+'/water/consumption?filter='+filter+'&endDate='+date);
        let response = await axios.get('/devices/' + deviceId + '/events?limit=' + limit + '&type=' + type);
        return response.data;
    } catch (err) {
        if (err.response) {
            err.response.data.error = true;
            return err.response.data;
        }
        else {
            let error = {
                response: {
                    data: {
                        error: true,
                        statusCode: 503,
                        message: "Network Error"
                    }
                }
            };
            return error.response.data

        }

    }
}