import React from 'react';
import ReactDOM from 'react-dom';
import Layout from './containers/Layout/Layout'
import store from './store';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css'
import * as serviceWorker from './serviceWorker';





ReactDOM.render(
    <Provider store={store}>
    <Layout />
    </Provider>


, document.getElementById('root'));

serviceWorker.register();