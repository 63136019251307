

export default function (state = {}, action) {
    switch (action.type) {
        case 'Schedule_Store':
            console.log(action.payload)
            return { ...state, schedule_device: action.payload.device }
        default:
            return state;
    }
}