

export let storeWater = (org, device, value, valueDate) => {
    return {
        type: "Water_Store",
        payload: {
            org,
            device,
            value,
            valueDate
        }
    }
}