import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise';


import UserReducer from './redux/reducers/usersReducer';
import OrganizationReducer from './redux/reducers/organizationReducer';
import EventsReducer from './redux/reducers/eventsReducer';
import MqttReducer from './redux/reducers/mqttReducer';
import WaterReducer from './redux/reducers/waterReducer';
import ScheduleReducer from './redux/reducers/schedulesReducer'
import idb_state from './redux/reducers/idb_reducer';

export default createStore(
    combineReducers({
        UserReducer,
        OrganizationReducer,
        EventsReducer,
        MqttReducer,
        WaterReducer,
        ScheduleReducer,
        idb_state
    }),
    applyMiddleware(thunk, promiseMiddleware)
)