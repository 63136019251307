export function mqttConfig(){
    return({
        "org" : "m4qjey",
        "id" : guid(),
        "auth-key" : "a-m4qjey-hjm41sfhtn",
        "auth-token" : "IKeffU&1KEEi2098tq"
        
    });
}
function guid(){
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4();
}