import React from 'react';
import OTTO from '../../assets/icons/otto.png'
import './Footer.css';

const footer = () => {
    return (
        <div className="footer" style={{ height: '50px', textAlign: 'center' }}>
            {/* <div className="footer-option">
                <span className="mdi mdi-home-outline mdi-18px"></span>
            </div>
            <div className="footer-option">
                <span className="mdi mdi-alarm mdi-18px"></span>
            </div>
            <div className="footer-option">
                <span className="mdi mdi-bell-outline mdi-18px"></span>
            </div>
            <div className="footer-option">
                <span className="mdi mdi-settings-outline mdi-18px"></span>
            </div> */}
            <a className="navbar-brand" href="#">
                <h6 className="navbar-brand-full" style={{ color: 'black' }}>
                    {/* <img src={OTTO} width="30" height="30" alt="Linked-things Logo" style={{ transform: 'rotate(168deg)', filter: 'grayscale(1) brightness(5)' }} /> */}
                    <img src={OTTO} width="30" height="30" alt="Linked-things Logo" style={{ transform: 'rotate(168deg)' }} />
                    <span style={{ marginTop: '12px', display: 'inline-block' }}><span style={{ fontSize: 14 }}></span>Ottomatically</span>
                </h6>
            </a>
        </div>
    );
}

export default footer;