
import * as ibmiot from 'ibmiotf';
import store from '../store'
import * as config from '../config/config';
import * as functions from '../functions/functions'
import { postCommands } from '../shared/services/production'

let ibmClient = null;


export let disconnectMqtt = () => {
    if (ibmClient !== null) {
        ibmClient.disconnect();
        ibmClient = null
    }
    else {
        console.log('no connection found')
    }
}

export async function publishDeviceData(type, hubId, topic, format, data) {

    var appClientConfig = config.mqttConfig();

    var appClient = new ibmiot.IotfApplication(appClientConfig);

    let date = functions.createDate();
    data.created = date.format();

    appClient.connect();
    appClient.on("connect", () => {

        appClient.publishDeviceCommand(type, hubId, topic, format, data);

    });

    let res = await postCommands(data);


}
export function getdata() {

    var appClientConfig = config.mqttConfig();

    var appClient = new ibmiot.IotfApplication(appClientConfig);

    appClient.connect();
    ibmClient = appClient
    appClient.on("connect", () => {
        /*  appClient.subscribeToDeviceEvents('Water');
         appClient.subscribeToDeviceEvents('Hub'); */
        /*  appClient.subscribeToDeviceEvents('Power'); */

        appClient.subscribeToDeviceEvents('Server');

    });

    /*   setInterval(()=>{ 
          var data = {
              
              deviceID : "deviceId",
              deviceType : "deviceType" ,
              eventType:"eventType",
              payload : "payload"
          }
         this.props.storeEvent(data);
  
       }, 3000); */
    appClient.on("deviceEvent", (deviceType, deviceId, eventType, format, payload) => {




        var eventData = JSON.parse(payload);

        var deviceID = eventData.deviceId;
        var type = eventData.type;
        var levelId = eventData.levelId


        var data = {
            hubId: eventData.hubId,
            eventId: eventData._id,
            deviceID: deviceID,
            levelId: levelId,
            value: eventData.value,
            type: type,
            created: eventData.created
        }


        store.dispatch({
            type: "ADD_MQTT_EVENT",
            payload: data,


        })






        // console.log(payload);
    });
}






