import { read, readSingle } from '../../database';

export async function read_single(dbName, storeName, key) {
    let results = '';

    if (!window.indexedDB) {
        console.log('IndexedDb is not supported by browser');
    } else {
        results = await readSingle(dbName, storeName, key);
    }

    return {
        type: 'READ_WRITE',
        payload: results
    }
}

export async function read_all(dbName, storeName) {
    let results = '';

    if (!window.indexedDB) {
        console.log('IndexedDb is not supported by browser');
    } else {
        results = await read(dbName, storeName);
    }

    return {
        type: 'READ_WRITE',
        payload: results
    }
}