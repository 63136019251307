import axios from '../utilities/axios';
import cookies from '../utilities/cookies';
import trees from '../utilities/trees';

export let getHierarchy = async (levelId) => {
    try {
        let token = cookies.getCookie('jwt');
        axios.defaults.headers['Authorization'] = 'Bearer ' + token;
        let response = await axios.get('/levels/' + levelId + '/hierarchy');
        let hierarchy = trees.flatenHierarchy(response.data);
        return [ response.data, ...hierarchy ];
    }catch(err) {
        if(err.response){
            err.response.data.error = true;
            return err.response.data;
        }
        else{
            let error = {
                response:{
                    data:{
                        error : true,
                        statusCode : 503,
                        message : "Network Error"
                    }
                }
            };
            return error.response.data
           
        }
      
    }
}

