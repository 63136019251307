import React, { Component } from 'react';

import { getIntakeByDevice } from '../../shared/services/events'
import { convertToDate } from '../../functions/functions'

class IntakeStats extends Component {
    constructor(props) {
        super(props);

        this.state = {
            intakestats: ''
        }

    }

    async componentDidMount() {
        let data = await getIntakeByDevice(this.props.device._id, 'gallons')
        console.log(data)

        this.setState({ intakestats: data })

    }

    render() {

        return (
            <div className="col-12 animated fadeIn" style={{ marginTop: '20px' }} >
                <div style={{ width: '100%' }} className="component-container-box">
                    <div style={{ padding: '5px' }}>
                        <h6 style={{ textAlign: 'left', marginBottom: '0px', fontSize: '14px' }}>Intake Stats</h6>
                    </div>
                    <div style={{ padding: '1%' }}>
                        <table style={{ fontSize: '12px', width: '100%' }}>
                            <tr style={{ borderBottom: '1px solid black' }}>
                                <th>DateTime</th>
                                <th>Level (Before)</th>
                                <th>Level (After)</th>
                                <th>Intake (Litres)</th>
                                <th>Intake (Gallons)</th>
                            </tr>
                            {(this.state.intakestats !== '') ?
                                this.state.intakestats.map((key, index) => {
                                    return (
                                        <tr style={(this.state.intakestats.length - 1 !== index) ? { borderBottom: '1px solid gray' } : null}>
                                            <td>{convertToDate(key.startTime)}</td>
                                            <td>{key.startValue} %</td>
                                            <td>{key.endValue} %</td>
                                            <td>{key.intakeLitres}</td>
                                            <td>{key.intakeGallons}</td>
                                        </tr>
                                    )
                                })
                                :
                                null
                            }
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

export default IntakeStats;